import {FileDoneOutlined, LoadingOutlined} from '@ant-design/icons';
import {Button, Form, Input} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React, {ReactElement, useEffect, useState} from 'react';

import {FormMode} from 'components/form';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {AIS_REGISTER_ROLE_SECTION} from 'pages/ais/documents-register-page/ais-documents-register-constants';
import {SPVDOCOptionsLabelFormatter} from 'pages/ais/documents-register-page/ais-documents-register-utils';
import {DATE, DATE_DMY} from 'shared/constants/date-format';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors} from 'store/slices/ais-slice/ais-slice';
import {removeSinglePUDDocument} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisRegisterDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

export interface MainInfoProps {
    form: FormInstance;
    registerDocument?: AisRegisterDocumentDto;
    formMode: FormMode;
    pudUploadJSX: ReactElement;
    isDisabled?: boolean;
}

export const MainInfo: React.FC<MainInfoProps> = React.forwardRef<FormInstance, MainInfoProps>(({
    form,
    registerDocument,
    formMode,
    pudUploadJSX,
    isDisabled,
}: MainInfoProps, ref) => {
    const dispatch = useAppDispatch();

    const [initialFormValues, setInitialFormValues] = useState(registerDocument);

    const registerDocumentInStore = useAppSelector(s => {
        if (!registerDocument?.id) return undefined;
        const inDocumentsRegister = aisSelectors.documentsSelectors.selectById(s, registerDocument.id);
        return inDocumentsRegister;
    });
    const {useIdAutogeneration} = useAppSelector(aisSelectors.selectAisRegisterDocumentsSettings) ?? {};

    const isRemovingPud = useAppSelector(s => selectIsThunkPending(s, removeSinglePUDDocument.typePrefix));

    useEffect(() => {
        setInitialFormValues(registerDocument);
    }, [registerDocument]);

    useEffect(() => {
        form.resetFields();
    }, [initialFormValues]);

    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: [AIS_REGISTER_ROLE_SECTION],
    });

    return (
        <Form<AisRegisterDocumentDto>
            form={form}
            ref={ref}
            initialValues={initialFormValues}
            layout="vertical"
        >
            <div
                className="form-flex scroll"
                style={{
                    maxHeight: `calc(min(${
                        formMode === 'create' ? '600' : '599'
                    }px, 64vh))`,
                    overflow: 'auto',
                    padding: '8px 12px 8px 0',
                }}
            >
                {/* todo: inline-стили переписать на классы */}
                <h4>Основная информация по документу</h4>
                <Form.Item
                    name="id"
                    hidden
                />
                <div className="form-flex-row">
                    {formMode === FormMode.EDIT || !useIdAutogeneration ? (
                        <Form.Item
                            label="Идентификатор документа"
                            name="aisDocId"
                            required
                            rules={[{required: true}]}
                        >
                            <Input
                                placeholder="Идентификатор документа"
                                disabled={formMode === FormMode.EDIT}
                            />
                        </Form.Item>
                    ) : undefined}
                    <Form.Item
                        label="Номер документа"
                        name="docNumber"
                    >
                        <Input
                            placeholder="Номер документа"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Наименование документа"
                        name="docName"
                        required
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Наименование документа"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        name="docDate"
                        label="Дата документа"
                        style={{maxWidth: 250}}
                        required
                        rules={[{required: true}]}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Дата начала периода, к которому относится документ"
                        name="dateFrom"
                        required
                        rules={[{required: true}]}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Дата окончания периода, к которому относится документ"
                        name="dateTo"
                        required
                        rules={[{required: true}]}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Сумма по документу всего, руб"
                        name="docSumGross"
                        rules={[{
                            pattern: /^(\d+|\d+,\d+)$/,
                            message: 'Данное поле должно содержать только цифры и одну запятую',
                        }]}
                    >
                        <Input
                            placeholder="Укажите сумму по документу всего, руб"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Сумма НДС по документу всего, руб"
                        name="docTaxSum"
                        rules={[{
                            pattern: /^(\d+|\d+,\d+)$/,
                            message: 'Данное поле должно содержать только цифры и одну запятую',
                        }]}
                    >
                        <Input
                            placeholder="Укажите сумму НДС по документу всего, руб"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Код СПВДОК"
                        name="docCode"
                        required
                        rules={[{required: true}]}
                    >
                        <CustomSelect
                            optionsLabelFormatter={SPVDOCOptionsLabelFormatter}
                            settings={{
                                url: '/ais/valueLists/SPVDOC_TYPE_CODES',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код СПВДОК',
                                isDisabled,
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Код КДОК"
                        name="docCatCode"
                    >
                        <CustomSelect
                            optionsLabelFormatter={SPVDOCOptionsLabelFormatter}
                            settings={{
                                url: '/ais/valueLists/SPVDOC_TYPE_CAT_CODES',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код КДОК',
                                isDisabled,
                            }}
                        />
                    </Form.Item>
                </div>

                <h4 className="my-1-5">Информация по контрагенту</h4>

                <div className="form-flex-row">
                    <Form.Item
                        label="Наименование контрагента"
                        name="cpartyName"
                    >
                        <Input
                            placeholder="Введите наименование контрагента"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>

                <div className="form-flex-row">
                    <Form.Item
                        label="ИНН организации"
                        name="cpartyInn"
                    >
                        <Input
                            placeholder="Введите ИНН организации"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Наименование КПП организации"
                        name="cpartyKpp"
                    >
                        <Input
                            placeholder="Введите КПП организации"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>

                <div className="form-flex-row align-items-end" >
                    <Form.Item
                        name="cpartyForeignRegNum"
                        label={(
                            <div style={{whiteSpace: 'pre'}}>
                                Регистрационный номер контрагента{'\n'}
                                в стране регистрации (инкорпорации)
                            </div>
                        )}
                    >
                        <Input
                            placeholder="Введите регистрационный номер"
                            disabled={isDisabled}

                        />
                    </Form.Item>
                    <Form.Item
                        name="cpartyCountryCode"
                        label="Код страны регистрации контрагента-нерезидента"
                    >
                        <CustomSelect
                            settings={{
                                url: '/valueLists/COUNTRY',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код страны',
                                isDisabled,
                            }}
                        />
                    </Form.Item>
                </div>

                <h4 className="my-1-5">Информация о документе, на основании которого составлены ПУД</h4>

                <div className="form-flex-row">
                    <Form.Item
                        label="Номер документа"
                        name="baseDocNumber"
                    >
                        <Input
                            placeholder="Номер документа"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Наименование документа"
                        name="baseDocName"
                    >
                        <Input
                            placeholder="Наименование документа"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>

                <div className="form-flex-row">
                    <Form.Item
                        name="baseDocDate"
                        label="Дата документа"
                        style={{maxWidth: 250}}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Код СПВДОК"
                        name="baseDocCode"
                    >
                        <CustomSelect
                            optionsLabelFormatter={SPVDOCOptionsLabelFormatter}
                            settings={{
                                url: '/ais/valueLists/SPVDOC_TYPE_CODES',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код СПВДОК',
                                isDisabled,
                            }}
                        />
                    </Form.Item>
                </div>

                {hasUserRoleFunction(UserRoleFunction.UPLOAD_PUD) && registerDocument && formMode === 'edit'
                    && registerDocumentInStore?.contentAvailability === 0
                    && pudUploadJSX}

                {(registerDocumentInStore?.contentAvailability === 1) && (
                    <div className="d-flex align-items-center gap-2 mt-1">
                        <div className="text-gray-600">
                            {!isRemovingPud ? (
                                <>
                                    <FileDoneOutlined className="mr-1" />
                                    Файл ПУД прикреплён
                                </>
                            ) : (
                                <>
                                    <LoadingOutlined className="mr-1" />
                                    Удаление ПУД...
                                </>
                            )}
                        </div>

                        {hasUserRoleFunction(UserRoleFunction.DELETE_PUD)
                        && (
                            <ModalOpenerComponent
                                component={(
                                    <Button
                                        type="default"
                                    >Удалить ПУД
                                    </Button>
                                )}
                                modalProps={{
                                    centered: true,
                                    width: 500,
                                    title: 'Удаление ПУД',
                                    destroyOnClose: true,
                                    forceRender: false,
                                }}
                                controlLabels={{
                                    save: 'Удалить',
                                }}
                                handleSave={setIsModalOpen => {
                                    if (!registerDocument) return;
                                    setIsModalOpen(false);
                                    dispatch(removeSinglePUDDocument({
                                        aisDocId: registerDocument.aisDocId,
                                        id: registerDocument.id,
                                    })).unwrap().then(() => {
                                        showMessage({message: 'Файл ПУД успешно удалён'});
                                    }, () => {
                                        showMessage({message: 'Во время удаления ПУД произошла ошибка', isError: true});
                                    });
                                }}
                            >
                                <div>Вы действительно хотите удалить ПУД?</div>
                            </ModalOpenerComponent>
                        )}
                    </div>
                )}
            </div>
        </Form>
    );
});
