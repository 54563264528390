import {createAsyncThunk} from '@reduxjs/toolkit';

import {RequestTableResponseWithPagination} from 'shared/types/requests';

import {
    fetchAisCommentsRequestRowsTable,
    FetchAisCommentsRequestRowsTableParams,
    fetchAisCommentsRequestTable,
    FetchAisCommentsRequestTableParams,
    postAisCommentsRequest,
    PostAisCommentsRequestData,
} from './ais-comments-request-api';
import {AIS3_COMMENTS_REQUEST_API_PREFIX} from './ais-comments-request-constants';
import {AisCommentsRequestResultTableDto, AisInspectorCommentDto} from './ais-comments-request-types';

// ==== Thunks to work with Ais Comments Request Result Table ====
export const loadAisCommentsRequestTable = createAsyncThunk<
    RequestTableResponseWithPagination<AisCommentsRequestResultTableDto>,
    FetchAisCommentsRequestTableParams | undefined>(
        `${AIS3_COMMENTS_REQUEST_API_PREFIX}/aisCommentsRequestTable`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await fetchAisCommentsRequestTable(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ==== Thunks to work with Ais Comments Request Comments Table ====
export const loadAisCommentsRequestRowsTable = createAsyncThunk<
    AisInspectorCommentDto,
    FetchAisCommentsRequestRowsTableParams>(
        `${AIS3_COMMENTS_REQUEST_API_PREFIX}/aisCommentsRequestRowsTable`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await fetchAisCommentsRequestRowsTable(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

// ==== Thunks to work with Ais Comments Request Comments Form ====
export const createAisCommentsRequest = createAsyncThunk<
    string,
    PostAisCommentsRequestData>(
        `${AIS3_COMMENTS_REQUEST_API_PREFIX}/createAisCommentsRequest`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await postAisCommentsRequest(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
