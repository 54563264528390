import {uniq} from 'lodash';

import {AisDocInterfaceFieldsRaw, AisDocRegisterFieldsRaw} from 'store/slices/ais-slice/ais-slice-api';

import {
    AisDocRegisterField,
    AisDocRegisterFilter,
    AisDocRegisterFields,
    AisSliceState,
    AisDocInterfaceFields,
    AisDocInterfaceFilter,
    AisDocInterfaceField,
} from './ais-slice-types';

export const getMappingIdsByDocumentId = (state: AisSliceState, id: number) => {
    const documentsByTaxEntities = state.registerDocumentsByTax.ids.map(
        documentId => state.registerDocumentsByTax.entities[documentId],
    );

    const mappingIdsToUpdate = uniq(documentsByTaxEntities
        .filter(entity => entity?.id === id)
        .map(entity => entity?.mappingId)
        .filter(v => !!v)) as number[];

    return mappingIdsToUpdate;
};

export const convertRawAisDocRegistersFields = (rawFields: AisDocRegisterFieldsRaw): AisDocRegisterFields => {
    const filters: AisDocRegisterFilter[] = [];
    const columns: AisDocRegisterField[] = [];

    rawFields
        ?.filter(f => f.isParam || f.display)
        ?.sort((a, b) => a.columnOrdinal - b.columnOrdinal)
        ?.forEach(rf => {
            if (rf.display) {
                columns.push({
                    id: rf.id,
                    columnOrdinal: rf.columnOrdinal,
                    label: rf.userName,
                    type: rf.fieldType,
                    fieldKey: rf.field,
                });
            }

            if (rf.isParam) {
                filters.push({
                    id: rf.id,
                    columnOrdinal: rf.columnOrdinal,
                    label: rf.userName,
                    type: rf.paramType,
                    fieldKey: rf.field,
                    referenceUrl: rf.paramLov,
                    isRequired: rf.paramRequired,
                });
            }
        });

    return {
        columns,
        filters,
    };
};

export const convertRawAisDocInterfaceFields = (rawFields: AisDocInterfaceFieldsRaw): AisDocInterfaceFields => {
    const filters: AisDocInterfaceFilter[] = [];
    const columns: AisDocInterfaceField[] = [];

    rawFields
        ?.filter(f => f.isParam || f.display)
        ?.sort((a, b) => a.columnOrdinal - b.columnOrdinal)
        ?.forEach(rf => {
            if (rf.display) {
                columns.push({
                    id: rf.id,
                    columnOrdinal: rf.columnOrdinal,
                    label: rf.userName,
                    type: rf.fieldType,
                    fieldKey: rf.field,
                });
            }

            if (rf.isParam) {
                filters.push({
                    id: rf.id,
                    columnOrdinal: rf.columnOrdinal,
                    label: rf.userName,
                    type: rf.paramType,
                    fieldKey: rf.field,
                    referenceUrl: rf.paramLov,
                    isRequired: rf.paramRequired,
                });
            }
        });

    return {
        columns,
        filters,
    };
};
