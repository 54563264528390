import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

import {getSubsectionFromLocation} from 'modules/documents';
import {getRegionFromSubsectionCode, selectRegions} from 'modules/regions';
import {getRegionFromRegionCode} from 'modules/regions/regions-utils';
import {useAppSelector} from 'store/config/hooks';

export const useCurrentLocationRegionData = () => {
    const regions = useAppSelector(selectRegions);
    const currentLocation = useLocation<History>();

    const currentLocationRegionData = useMemo(() => {
        if (!regions) {
            return {};
        }

        const subRegionCode = getSubsectionFromLocation(currentLocation);
        const region = getRegionFromSubsectionCode(subRegionCode, regions)
                    || getRegionFromRegionCode(subRegionCode, regions);
        const subRegion = region?.subregionList.find(({code}) => code === subRegionCode);

        return {region, subRegion};
    }, [currentLocation, regions]);

    return {
        currentLocationRegion: currentLocationRegionData?.region,
        currentLocationSubregion: currentLocationRegionData?.subRegion,
    };
};
