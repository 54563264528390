import React from 'react';

import {ReactComponent as MainLogoIcon} from 'shared/assets/main-logo.svg';
import './empty-widgets-greetings.less';
import {DEFAULT_THEME} from 'shared/constants/layout';

export const EmptyWidgetsGreetings = () => (
    <div className="empty-widget-greeting">
        <div className="empty-widget-greeting-logo d-flex align-items-center">
            {process.env.REACT_APP_THEME !== DEFAULT_THEME ? (
                <img
                    src={`${process.env.PUBLIC_URL}/logos/${process.env.REACT_APP_THEME}-dark.svg`}
                    className="empty-widget-greeting__partner-logo"
                    alt="Логотип портала."
                />
            ) : (
                <>
                    <MainLogoIcon className="empty-widget-greeting-logo__icon" />
                    <div className="empty-widget-greeting-logo__name">
                        Витрина<br />
                        налогового<br />
                        мониторинга
                    </div>
                </>
            )}
        </div>
    </div>
);
