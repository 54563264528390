import {FormInstance} from 'antd/es/form';
import {omitBy} from 'lodash';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import isNaN from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import React from 'react';

import {Truthy} from 'shared/types/generics';

import {FieldData} from '../types';

export type {RequestPromise} from './request';
export {performRequest, downloadFile} from './request';
export {convertToFormData} from './convert-to-form-data';
export {showMessageFromResponse} from './show-message-from-response';
export {showMessage} from './notifications';
export {isString, isObject, isNumber};

export const {isArray} = Array;

export const prettifyXml = (sourceXml: string) => {
    const xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    const xsltDoc = new DOMParser().parseFromString([
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">',
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    const xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    const resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};

export const generateRandomString = () => Math.floor(Math.random() * Date.now()).toString(36);

export const setFieldsValueUntouched = (form: FormInstance, values: {[index: string]: any}) => {
    const valuesList: FieldData[] = Object.entries(values).map(([index, value]) => ({
        name: index,
        value,
        touched: false,
    }));
    form.setFields(valuesList);
};

export const makeFormFieldsUntouched = (form: FormInstance) => {
    const currentFields = form.getFieldsValue();
    setFieldsValueUntouched(form, currentFields);
};

export const omitEmptyLines = (obj?: {[key: string]: any}) => {
    if (!obj) return undefined;
    return omitBy(obj, v => {
        if (typeof v === 'string' && v === '') return true;
        return false;
    });
};

export const filterObject = (
    object: Object,
    callback: (key: React.Key, value: any) => boolean,
) => {
    if (!object) return undefined;
    return Object.fromEntries(Object.entries(object).filter(([key, value]) => callback(key, value)));
};

export const isEmptyObject = (v: any) => !Array.isArray(v) && typeof v === 'object' && !Object.keys(v).length;

export const isEmptyArray = (v: any) => Array.isArray(v) && isEmpty(v);

export const isEmptyArrayOrObject = (v: any) => isEmptyObject(v) || isEmptyArray(v);

export const getMapOfValues = <I extends Record<string, any>>(
    items: I[], fieldName: string = 'id',
): Record<string, I> => items
        .reduce((acc: Record<string, I>, currentItem: I) => {
            acc[currentItem[fieldName]] = currentItem;
            return acc;
        }, {});

export const isOverflown = (element?: HTMLElement | null) => {
    if (!element) return false;

    const {
        clientWidth, clientHeight, scrollWidth, scrollHeight,
    } = element;

    return scrollHeight > clientHeight || scrollWidth > clientWidth;
};

const createParam = (key: string, value: string | string[]): string => {
    if (isObject(value)) {
        const newValue: string[] = Object.values(value);
        return newValue.join('=');
    }

    return isArray(value)
        ? value.map((item: string) => `${key}=${item}`).join('&')
        : `${key}=${value}`;
};

export const createQueryParams = (object: Record<string, any>, url?: string) => {
    const keys = Object.keys(object);
    const unnecessaryValues = [null, undefined];
    const alreadyHasQuery = url?.includes('?');

    return keys
        .filter(key => !unnecessaryValues.includes(object[key]))
        .reduce((params: string, currentKey: string, currentIndex: number) => {
            const separator = currentIndex === 0 && !alreadyHasQuery ? '?' : '&';
            const newParam = `${separator}${createParam(currentKey, object[currentKey])}`;

            return `${params}${newParam}`;
        }, '');
};

export const isBlob = (value: any) => value instanceof Blob;

export const createOptionItemTransformer = (
    labelPath: string, valuePath: string, reserveLabelPath?: string,
) => <T extends {}>(record: T) => {
    let label = get(record, labelPath) as string;
    const value = get(record, valuePath) as string;

    if (!label && reserveLabelPath) {
        label = get(record, reserveLabelPath);
    }

    return {
        ...record,
        label,
        value,
    };
};

export const createOptionItemsTransformer = (
    labelPath: string, valuePath: string, reserveLabelPath?: string,
) => <T extends {}>(records: T[]) => {
    const transformOption = createOptionItemTransformer(labelPath, valuePath, reserveLabelPath);

    return records.map(transformOption);
};

export const saveLocalFile = (file: File) => {
    const fileUrl = URL.createObjectURL(file);

    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = file.name;
    link.click();
};

export const isEnumInstance = <T extends object>(value: string | number, type: T): type is T => (
    Object.values(type).includes(value)
);

export {
    convertArrayToTwoLevelObject,
    replaceFirstElementInArray,
    flattenDeepArrayByField,
} from './arrays';

export const isNumeric = (value: any) => (
    !isNaN(parseFloat(value)) && isFinite(parseFloat(value))
);

const moveUnderscoreToEnd = (str:string | undefined) => {
    if (!str) {
        return '';
    }
    const nonUnderscores = str.replace('_', '');
    const hasUnderscores = str.length > nonUnderscores.length;

    return `${nonUnderscores}${hasUnderscores ? '_' : ''}`;
};

export const escapeRegExp = (characters: string | undefined) => (
    moveUnderscoreToEnd(characters).replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Экранируем специальные символы
);

// ts-friendly equivalent of Boolean
export const isTruthy = <T>(value: T): value is Truthy<T> => !!value;

/**
 * Функция для удаления ключей с значениями undefined из объекта.
 * @param obj - Исходный объект.
 * @returns Новый объект без ключей со значениями undefined.
 */
export function removeUndefinedKeys<T extends object>(obj: T): Partial<T> {
    // Создаем новый объект, в который будем добавлять ключи с допустимыми значениями
    const result: Partial<T> = {};

    // Получаем массив пар [ключ, значение] и проходимся по нему
    Object.entries(obj).forEach(([key, value]) => {
        // Если значение не undefined, добавляем его в новый объект
        if (value !== undefined) {
            result[key as keyof T] = value;
        }
    });

    return result;
}
