import {Button, Form} from 'antd';
import React, {useRef} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {OrganizationStructureTabKeys as FormKeys} from 'pages/ais/organization-structure/organization-structure-page.constants';
import {
    AddItemButtonRef,
    OrgStructureTabProps,
} from 'pages/ais/organization-structure/organization-structure-page.types';
import {ForeignParentsList} from 'pages/ais/organization-structure/tabs/foreign-parents-tab/foreign-parents-list/foreign-parents-list';

interface ForeignParentsTabProps extends OrgStructureTabProps, Pick<OrgStructureTabProps, 'isDisabled'> {}

export const ForeignParentsTab = ({buttonsBar, isDisabled}: ForeignParentsTabProps) => {
    const addBtnRef = useRef<AddItemButtonRef>(null);

    return (
        <>
            {buttonsBar?.((
                <Button
                    type="default"
                    onClick={() => addBtnRef.current?.add()}
                >
                    <DynamicIcon type="PlusCircleOutlined" />
                    Добавить
                </Button>
            ))}
            <div
                className="foreign-parents-tab"
            >
                <Form.List
                    name={[FormKeys.companyGroup, FormKeys.foreignParentsInformation]}
                >
                    {(fields, operation, {errors}) => (
                        <ForeignParentsList
                            fields={fields}
                            operations={operation}
                            errors={errors}
                            isDisabled={isDisabled}
                            ref={addBtnRef}
                        />
                    )}
                </Form.List>
            </div>
        </>
    );
};
