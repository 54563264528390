import {
    Button, Form, Input, Spin, Tooltip, Typography,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    FC, useEffect, useState,
} from 'react';
import {useHistory} from 'react-router-dom';

import {ActionBar} from 'components/@common/widgets/action-bar';
import {CustomCard} from 'components/@common/widgets/custom-card';
import {CustomTable, customTableUtils} from 'components/@common/widgets/custom-table';
import {AIS_ROUTES} from 'components/ais/ais.constants';
import {MonitorServicesModal} from 'components/ais/monitor-services-card/monitor-services-modal';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    AisInspectorCommentDto,
    loadAisCommentsRequestRowsTable,
    loadAisCommentsRequestTable,
    selectCommentsRequestRowsTableData,
    selectCommentsRequestTableData,
} from 'store/slices/ais-slice/ais-comments-request';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {
    CommentRequestStatuses,
    COMMENTS_REQUEST_MONITOR_SERVICE_LOOKUP_CODE,
    formRowCommentsTableHeader,
} from '../constants/ais-comments-request-result.constants';
import {AisCommentRequestRowModalOpener} from '../modals';
import s from './ais-answer-formation-page.module.less';

const {Paragraph} = Typography;

interface AisAnswerFormationPageProps {
  requestId: number;
  entityName: string;
}

export const AisAnswerFormationPage: FC<AisAnswerFormationPageProps> = ({
    requestId,
    entityName,
}: AisAnswerFormationPageProps) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMonitorServiceModalOpen, setIsMonitorServiceModalOpen] = useState(false);
    const [currentRequestId, setCurrentRequestId] = useState(requestId);
    const [form] = useForm<Partial<AisInspectorCommentDto>>();

    const fetchTableData = async () => {
        await dispatch(loadAisCommentsRequestRowsTable({
            requestId: currentRequestId,
        }));
    };

    useEffect(() => {
        dispatch(loadAisCommentsRequestTable());
    }, []);

    useEffect(() => {
        fetchTableData();
    }, [currentRequestId]);

    const isLoadingRequestNumber = useAppSelector(
        st => selectIsThunkPending(st, loadAisCommentsRequestTable.typePrefix),
    );
    const isLoadingTable = useAppSelector(
        st => selectIsThunkPending(st, loadAisCommentsRequestRowsTable.typePrefix),
    );

    const commentsRequestForSelect = useAppSelector(selectCommentsRequestTableData) ?? [];
    const commentsRequestInfo = useAppSelector(selectCommentsRequestRowsTableData);

    return (
        <CustomCard
            title="Формирование ответа к результатам ФА"
            accordionTransitionType="1"
            accordionContent={(
                <Form
                    className={cn(s['answer-formation-form'])}
                    layout="vertical"
                    form={form}
                    initialValues={{
                        ...commentsRequestInfo,
                        id: Number(currentRequestId),
                    }}
                >
                    <div
                        className={cn(s['answer-formation-form__row'])}
                    >
                        <Form.Item
                            className={cn(s['answer-formation-form__item'])}
                            name="id"
                            label="Номер запроса"
                        >
                            <CustomSelect
                                entries={commentsRequestForSelect.map(e => ({
                                    label: e.numberRequest,
                                    value: e.id,
                                }))}
                                onChange={v => setCurrentRequestId(v)}
                                settings={{
                                    showSearch: true,
                                    placeholder: 'Выберите номер запроса',
                                    isLoading: isLoadingRequestNumber,
                                }}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Статус"
                        >
                            <Input
                                value={
                                    commentsRequestInfo?.status
                                        ? CommentRequestStatuses.COMPLETED
                                        : CommentRequestStatuses.NEW
                                }
                                disabled
                                placeholder="Введите статус запроса"
                            />
                        </Form.Item>
                    </div>

                    {commentsRequestInfo?.inspectorComment
                    && (
                        <Form.Item
                            label="Комментарий ФНС"
                            style={{width: '100%', marginBottom: 0}}
                        >
                            <div onClick={() => setIsModalOpen(true)}>
                                <Tooltip
                                    title="Просмотреть комментарий целиком"
                                    mouseEnterDelay={0.8}
                                    placement="bottomLeft"
                                >
                                    <Spin
                                        spinning={isLoadingTable}
                                        tip="Загрузка комментария..."
                                    >
                                        <Paragraph
                                            ellipsis={{rows: 3}}
                                            className={cn(s['answer-formation-form__text'])}
                                        >
                                            {commentsRequestInfo?.inspectorComment}
                                        </Paragraph>
                                    </Spin>
                                </Tooltip>
                            </div>
                        </Form.Item>
                    )}
                </Form>
            )}
            isAccordionContentVisibleByDefault
            className={cn(s['answer-formation'])}
            bodyStyle={{paddingTop: 0}}
        >
            <ActionBar
                side="left"
                className={cn(s['answer-formation__buttons-bar'])}
                transparent
            >
                {!commentsRequestInfo?.status && (
                    <Button
                        type="primary"
                        disabled={isLoadingTable || isLoadingRequestNumber}
                        onClick={() => setIsMonitorServiceModalOpen(true)}
                    >
                        Отправить ответ
                    </Button>
                )}

                <MonitorServicesModal
                    entityName={entityName}
                    visible={isMonitorServiceModalOpen}
                    onFormClose={() => setIsMonitorServiceModalOpen(false)}
                    initialValues={{
                        externalServiceLookupCode: COMMENTS_REQUEST_MONITOR_SERVICE_LOOKUP_CODE,
                        formInitialValues: {
                            requestId: commentsRequestForSelect.find(
                                e => e.id === Number(currentRequestId),
                            )?.numberRequest,
                        },
                    }}
                    disabled
                />

                <Button
                    type="default"
                    icon={<ArrowBackOutlined />}
                    onClick={() => history.push(`/${AIS_ROUTES.COMMENTS_REQUEST_RESULT}`)}
                >
                    Назад
                </Button>

            </ActionBar>

            <Spin
                spinning={isLoadingTable}
                tip="Загрузка..."
            >
                <CustomTable
                    rowKey="id"
                    className={cn(s['answer-formation__table'])}
                    columns={[{
                        title: '№ показателя',
                        dataIndex: 'indicatorNumber',
                        key: 'indicatorNumber',
                        align: 'center',
                        render: (_, row) => (
                            <AisCommentRequestRowModalOpener
                                row={row}
                                fetchTableData={fetchTableData}
                                status={
                                    commentsRequestInfo?.status
                                        ? CommentRequestStatuses.COMPLETED
                                        : CommentRequestStatuses.NEW
                                }
                            />
                        ),
                    },
                    ...formRowCommentsTableHeader(
                        commentsRequestInfo?.yearToCompare,
                        commentsRequestInfo?.year,
                    )]}
                    dataSource={commentsRequestInfo?.comments}
                    onColumn={col => ({
                        ...customTableUtils.setHeaderStyles({
                            whiteSpace: 'pre-wrap',
                            textAlign: 'center',
                        }),
                        ...col,
                    })}
                    bordered
                />
            </Spin>

            <ModalOpenerComponent
                modalProps={{
                    title: 'Комментарий ФНС',
                    width: '980px',
                    centered: true,
                }}
                controlLabels={{
                    cancel: 'Закрыть',
                }}
                isOpen={isModalOpen}
                handleCancel={() => setIsModalOpen(false)}
            >
                {commentsRequestInfo?.inspectorComment ?? ''}
            </ModalOpenerComponent>
        </CustomCard>
    );
};
