import {
    Card,
    Form,
    Modal,
} from 'antd';
import {useForm} from 'antd/es/form/Form';
import cn from 'classnames';
import get from 'lodash/get';
import React, {useEffect, useMemo, useState} from 'react';

import {MonitorServiceDynamicFields} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields';
import {SimpleActionButton} from 'components/form/components';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent as TriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {resetLoadedData} from 'modules/data';
import {loadReferenceData} from 'modules/data/data-actions';
import {selectReferenceEntityData} from 'modules/data/data-selectors';
import {EntityType} from 'shared/constants/entities';
import {
    MonitorServicesApi,
    useLazyGetAdditionalFormFieldsByMonitorServiceIdQuery,
    useRunMonitorServiceMutation,
} from 'store/api/monitor-services/monitor-services.api';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';

interface MonitorServicesModalProps {
    entityName: string;
    visible: boolean;
    onFormClose: () => void;
    initialValues?: {
      externalServiceLookupCode: string;
      formInitialValues: Record<string, any>;
    };
    disabled?: boolean;
}

export const MonitorServicesModal = ({
    entityName,
    visible,
    onFormClose,
    initialValues,
    disabled = false,
}: MonitorServicesModalProps) => {
    const dispatch = useAppDispatch();
    const [form] = useForm();
    const handleFormClose = () => {
        dispatch(MonitorServicesApi.util.resetApiState());
        form.resetFields();
        onFormClose();
    };

    const [serviceId, setServiceId] = useState<number | undefined>();
    const [isServicesLoading, setIsServicesLoading] = useState<boolean>(false);

    const servicesEntriesUrl = 'ais/valueLists/AIS_SERVICES';
    const servicesEntriesRaw = useAppSelector(selectReferenceEntityData(servicesEntriesUrl));
    const servicesEntries = useMemo(() => (
        servicesEntriesRaw
            ?.map(dataEntry => ({
                label: get(dataEntry, 'meaning') as string,
                value: get(dataEntry, 'id') as string,
            }))
            ?.sort((a, b) => +a.value - +b.value)
    ), [servicesEntriesRaw]);

    useEffect(() => {
        if (!!servicesEntries?.length && serviceId === undefined) {
            setServiceId(+servicesEntries?.[0].value); // default value
        }
    }, [servicesEntries]);

    useEffect(() => {
        if (!servicesEntriesRaw) {
            setIsServicesLoading(true);
            dispatch(loadReferenceData(servicesEntriesUrl))
                .finally(() => setIsServicesLoading(false));
        }
    }, [servicesEntriesRaw]);

    const [
        getAdditionalFormFieldsByMonitorServiceId,
        {
            currentData: dynamicFields,
            isFetching: isDynamicFieldsFetching,
        },
    ] = useLazyGetAdditionalFormFieldsByMonitorServiceIdQuery();

    const [runMonitorService, {isSuccess: isFormSubmitted}] = useRunMonitorServiceMutation();

    useEffect(() => {
        if (initialValues && visible) {
            const initialExternalServiceId = Number(servicesEntriesRaw?.find(
                e => e.lookupCode === initialValues.externalServiceLookupCode,
            )?.id);

            if (!Number.isNaN(initialExternalServiceId)) {
                getAdditionalFormFieldsByMonitorServiceId({id: initialExternalServiceId});
            }

            form.setFieldsValue({
                ...initialValues.formInitialValues,
                externalServiceId: Number.isNaN(initialExternalServiceId) ? undefined : initialExternalServiceId,
            });
        }
    }, [initialValues]);

    useEffect(() => {
        if (isFormSubmitted) {
            handleFormClose();
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
        }
    }, [isFormSubmitted]);

    return (
        <Modal
            visible={visible}
            centered
            width="980px"
            title="Вызвать сервис"
            onCancel={handleFormClose}
            className={cn('form-modal', 'monitor-services-modal')}
            footer={null}
        >
            {visible && (
                <Card>
                    <Form
                        className={cn('form', 'monitor-services-modal-form')}
                        form={form}
                        layout="vertical"
                        onFinish={() => {
                            const data = form.getFieldsValue();
                            form.validateFields()
                                .then(() => {
                                    runMonitorService(data);
                                });
                        }}
                    >
                        <div className="form-fields">
                            <Form.Item
                                style={{width: '100%'}}
                                name="externalServiceId"
                                label="Наименование сервиса"
                                required
                            >
                                <CustomSelect
                                    onChange={setServiceId}
                                    entries={servicesEntries ?? []}
                                    value={serviceId}
                                    handleTriggerEvent={(value, event) => {
                                        if (event === TriggerEvent.valueWasSet
                                          || event === TriggerEvent.firstOptionWasSet
                                          || event === TriggerEvent.receivedDataFromFetcher
                                          || event === TriggerEvent.valueHasBeenChangedManually) {
                                            getAdditionalFormFieldsByMonitorServiceId({id: value});
                                        }
                                    }}
                                    settings={{
                                        placeholder: 'Выберите сервис',
                                        formFieldKey: 'externalServiceId',
                                        formInstance: form,
                                        showSearch: true,
                                        isDisabled: isDynamicFieldsFetching || disabled || isServicesLoading,
                                    }}
                                />
                            </Form.Item>

                            <MonitorServiceDynamicFields
                                form={form}
                                fields={dynamicFields}
                                entityName={entityName}
                                disabled={disabled}
                            />
                        </div>

                        <SimpleActionButton
                            title="Запуск"
                            type="primary"
                            style={{width: '100%'}}
                            disabled={isDynamicFieldsFetching}
                            onClick={() => form.submit()}
                        />
                    </Form>
                </Card>
            )}
        </Modal>
    );
};
