import {Spin} from 'antd';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {AisMainPageDashboardProps} from 'pages/ais/ais-main-page/ais-main-page-types';
import {ReactComponent as AvailableIcon} from 'shared/assets/ais/available.svg';
import {ReactComponent as UnavailableIcon} from 'shared/assets/ais/unavailable.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AisDashboardCode} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-constants';
import {selectSystemAvailabilityStatusDashboardData} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-selectors';
import {getAisSystemAvailabilityStatusDashboardData} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {AisDashboardCard} from '../@common/ais-dashboard-card';

import './ais-availability-status-dashboard.less';

export const AisAvailabilityStatusDashboard: React.FunctionComponent<AisMainPageDashboardProps> = ({
    context,
    className,
}: AisMainPageDashboardProps) => {
    const dispatch = useAppDispatch();

    const {meaning, isAvailable, statusDate} = useAppSelector(selectSystemAvailabilityStatusDashboardData) ?? {};
    const isLoading = useAppSelector(s => (
        selectIsThunkPending(s, getAisSystemAvailabilityStatusDashboardData.typePrefix)
    ));
    const {taxPeriodId, organizationId} = context || {};

    useEffect(() => {
        if (!taxPeriodId && !organizationId) return;
        dispatch(getAisSystemAvailabilityStatusDashboardData({
            code: AisDashboardCode.SYSTEM_AVAILABILITY_STATUS,
            taxPeriodId,
            organizationId,
        }));
    }, [taxPeriodId, organizationId]);

    return (
        <AisDashboardCard
            title="Статус доступности витрины"
            className={className}
            style={{flexBasis: 0}}
            bodyClassName={cn('ais-availability-status-dashboard')}
        >
            <Spin
                spinning={isLoading}
                tip="Загрузка..."
            >
                <div className={cn('ais-availability-status-dashboard__body')}>
                    <div className={cn('d-flex gap-2 align-items-center')}>
                        <div>{meaning}</div>
                        <div className="ais-availability-status-dashboard__body-icon-wrapper">
                            {
                                isAvailable
                                    ? <AvailableIcon />
                                    : <UnavailableIcon />
                            }
                        </div>
                    </div>
                    <div className={cn('d-flex gap-2 align-items-baseline')}>
                        <div>Дата отправки статуса в НО:</div>
                        <div className={cn('text-gray-600 text-size-14')}>
                            {statusDate}
                        </div>
                    </div>
                </div>
            </Spin>
        </AisDashboardCard>
    );
};
