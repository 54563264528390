import FormItem from 'antd/es/form/FormItem';
import Col from 'antd/es/grid/col';
import Row from 'antd/es/grid/row';
import InputNumber from 'antd/es/input-number';
import React, {useState} from 'react';

import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {CommonInfoFieldNames as FieldNames} from 'pages/ais/organization-structure/organization-structure-page.constants';
import {OrgStructureTabProps} from 'pages/ais/organization-structure/organization-structure-page.types';

interface CommonInfoTabProps extends OrgStructureTabProps {
    isAffiliationSet: boolean;
}

export const CommonInfoTab = ({
    buttonsBar,
    isAffiliationSet,
    setValidationField,
    isDisabled,
}: CommonInfoTabProps) => {
    const [isStatePartSet, setIsStatePartSet] = useState<boolean>(false);

    return (
        <>
            {buttonsBar?.()}
            <div
                className="tab-bordered"
            >
                <Row
                    align="bottom"
                    gutter={16}
                >
                    <Col span={12}>
                        <FormItem
                            name={FieldNames.affiliation}
                            label="Признак принадлежности к группе компаний, в которой существует возможность у
                    кого-либо из участников влиять на решения, принимаемые другими участниками группы"
                            rules={[{required: true}]}
                        >
                            <CustomSelect
                                onChange={value => {
                                    setValidationField?.('isAffiliationSet', value === '1');
                                }}
                                handleTriggerEvent={(value, event) => {
                                    if (event === CustomSelectValueTriggerEvent.receivedDataFromFetcher) {
                                        setValidationField?.('isAffiliationSet', value?.lookupCode === '1');
                                    }
                                }}
                                settings={{
                                    url: 'valueLists/FLEX_VALUE?sublistCode=affiliation',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите значение',
                                    isClearable: true,
                                    showSearch: true,
                                    isDisabled,
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name={FieldNames.statePart}
                            label="Признак государственного участия в организации – участнике налогового мониторинга,
                            представляющей информацию"
                            rules={[{required: true}]}
                        >
                            <CustomSelect
                                onChange={value => {
                                    setIsStatePartSet(value === '1');
                                }}
                                handleTriggerEvent={(value, event) => {
                                    if (event === CustomSelectValueTriggerEvent.receivedDataFromFetcher) {
                                        setIsStatePartSet(value?.lookupCode === '1');
                                    }
                                }}
                                settings={{
                                    url: 'valueLists/FLEX_VALUE?sublistCode=state_part',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите значение',
                                    isClearable: true,
                                    showSearch: true,
                                    isDisabled,
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row
                    align="bottom"
                    gutter={16}
                >
                    <Col span={12}>
                        <FormItem
                            name={FieldNames.position}
                            label="Признак принадлежности организации к головной организации группы компаний"
                            rules={[{required: isAffiliationSet}]}
                        >
                            <CustomSelect
                                settings={{
                                    url: 'valueLists/FLEX_VALUE?sublistCode=position',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите значение',
                                    isClearable: true,
                                    showSearch: true,
                                    isDisabled,
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name={FieldNames.statePartType}
                            label="Способ государственного участия"
                            rules={[{required: isStatePartSet}]}
                        >
                            <CustomSelect
                                settings={{
                                    url: 'valueLists/FLEX_VALUE?sublistCode=state_part_type',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите значение',
                                    isClearable: true,
                                    showSearch: true,
                                    isDisabled,
                                }}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row
                    align="bottom"
                    gutter={16}
                >
                    <Col span={12}>
                        <FormItem
                            name={FieldNames.companyGroupInterdependence}
                            label="Признак взаимозависимости к головной организации группы компаний (холдинга)
                         в соответствии с п.2 ст.105.1 НК РФ"
                            rules={[{required: isAffiliationSet}]}
                        >
                            <CustomSelect
                                settings={{
                                    mode: CustomSelectMode.multiple,
                                    url: 'valueLists/FLEX_VALUE?sublistCode=cg_interdependence',
                                    valuePath: 'lookupCode',
                                    labelPath: 'meaning',
                                    placeholder: 'Выберите значение',
                                    isClearable: true,
                                    showSearch: true,
                                    isDisabled,
                                }}
                            />
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem
                            name={FieldNames.statePartShare}
                            label="Доля государственного участия, в процентах"
                            rules={[{required: isStatePartSet}]}
                        >
                            <InputNumber
                                className="w-100"
                                placeholder="Введите значение"
                                disabled={isDisabled}
                            />
                        </FormItem>
                    </Col>
                </Row>
            </div>
        </>
    );
};
