import {SaveOutlined} from '@ant-design/icons/lib';
import {Button} from 'antd';
import {FormInstance} from 'antd/es/form';
import isNil from 'lodash/isNil';
import React, {ReactNode, useState} from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as EditWithLine} from 'shared/assets/edit-with-line.svg';
import {StateSetter} from 'shared/types/generics';

interface ButtonsBarProps {
    form: FormInstance;
    onReset: () => void;
    isDirty: boolean;
    isEditing: boolean;
    setEditing: StateSetter<boolean>;
    additional?: ReactNode;
}

export const ButtonsBar = ({
    form,
    onReset,
    isDirty,
    isEditing,
    setEditing,
    additional,
}: ButtonsBarProps) => {
    const [isResetModalVisible, setResetModalVisible] = useState(false);

    const handleCancel = () => {
        if (isDirty) {
            setResetModalVisible(true);
        } else {
            onReset();
        }
    };

    const handleReset = () => {
        onReset();
        setResetModalVisible(false);
    };

    return (
        <div className="buttons-bar">
            {isEditing ? (
                <>
                    <Button
                        type="primary"
                        icon={<SaveOutlined />}
                        onClick={() => form.submit()}
                        disabled={!isDirty}
                    >Сохранить
                    </Button>
                    <ModalOpenerComponent
                        component={(
                            <Button
                                className="button-critic"
                                type="default"
                                icon={<CloseXOutlined />}
                                onClick={handleCancel}
                            >Отменить изменения
                            </Button>
                        )}
                        isOpen={isResetModalVisible}
                        handleSave={handleReset}
                        handleCancel={() => setResetModalVisible(false)}
                        modalProps={{
                            title: 'Предупреждение',
                            centered: true,
                            width: '680px',
                        }}
                        controlLabels={{
                            save: 'Да',
                            cancel: 'Нет',
                        }}
                    >
                        <div className="text-center">
                            Вы действительно хотите отменить изменения?
                            <br />Внесенные данные будут потеряны.
                        </div>
                    </ModalOpenerComponent>
                    {additional}
                </>
            ) : !isNil(isEditing) && (
                <Button
                    type="primary"
                    icon={<EditWithLine />}
                    onClick={() => setEditing(true)}
                >Редактировать
                </Button>
            )}
        </div>
    );
};
