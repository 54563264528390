import {Card} from 'antd';
import {CardProps} from 'antd/lib/card';
import cn from 'classnames';
import React, {useState} from 'react';

import {IconsMap} from 'components/dynamic-icon';
import {Spinner} from 'components/spinner';
import {JSXContent} from 'shared/types';

import './custom-card.less';

interface CustomCardProps extends CardProps {
    accordionContent?: JSXContent;
    isAccordionContentVisible?: boolean;
    isAccordionContentVisibleByDefault?: boolean;
    onTitleClick?: () => void;
    titleClassName?: cn.Argument;
    accordionTransitionType?: '1';
    isLoading?: boolean;
}

export const CustomCard: React.FC<CustomCardProps> = (
    props: CustomCardProps,
) => {
    const {
        accordionContent,
        isAccordionContentVisible,
        isAccordionContentVisibleByDefault,
        children,
        title,
        titleClassName,
        onTitleClick,
        accordionTransitionType,
        isLoading,
        ...rest
    } = props;

    const [isContentVisible, setIsContentVisible] = useState(isAccordionContentVisibleByDefault);

    const isExtraVisible = (() => {
        if (isAccordionContentVisible === false) return false;
        return isContentVisible;
    })();

    return (
        <Card
            {...rest}
            className={cn('custom-card', rest.className)}
            title={(
                title && (
                    <div
                        className={cn('custom-card__title', {
                            'custom-card__title_with-extra-content': accordionContent,
                        })}
                        onClick={() => {
                            onTitleClick?.();
                            setIsContentVisible(p => !p);
                        }}
                    >
                        {!isLoading && accordionContent && (
                            <div
                                className={cn(
                                    'custom-card__title__accordion-icon',
                                    {
                                        'custom-card__title__accordion-icon_opened':
                                    isExtraVisible,
                                    },
                                )}
                            >
                                <IconsMap.AccordionIcon />
                            </div>
                        )}
                        <div className={cn(titleClassName)}>{title}</div>
                    </div>
                )
            )}
        >
            {(() => {
                if (isLoading) {
                    return <Spinner tip="Загрузка..." />;
                }

                return (
                    <>
                        {accordionContent && (
                            <div className={cn('custom-card__extra', {
                                'custom-card__extra_hidden': !isExtraVisible,
                                [`custom-card__extra_transition-${accordionTransitionType}`]: !!accordionTransitionType,
                            })}
                            >
                                {accordionContent}
                            </div>
                        )}

                        {children}
                    </>
                );
            })()}
        </Card>
    );
};
