import {Spin} from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {AisMainPageDashboardProps} from 'pages/ais/ais-main-page/ais-main-page-types';
import {DATE_DMY} from 'shared/constants/date-format';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AisDashboardCode} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-constants';
import {selectS4S5StatisticsDashboardDataForChart} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-selectors';
import {getAisS4S5DashboardData} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {generateYearSelectionForDashboard} from '../../ais-main-page.utils';
import {AisDashboardCard} from '../@common/ais-dashboard-card';
import {VerticalBarChart} from '../@common/vertical-bar-chart';

import './ais-request-statistics-dashboard.less';

export const AisRequestStatisticsDashboard: React.FunctionComponent<AisMainPageDashboardProps> = ({
    context,
    className,
}: AisMainPageDashboardProps) => {
    const dispatch = useAppDispatch();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const s4s5DataForChart = useAppSelector(selectS4S5StatisticsDashboardDataForChart);
    const {taxPeriodId, organizationId} = context || {};

    useEffect(() => {
        if (!taxPeriodId && !organizationId) return;
        dispatch(getAisS4S5DashboardData({
            code: AisDashboardCode.REQUEST_STATISTIC_S4_S5,
            year: selectedYear,
            taxPeriodId,
            organizationId,
        }));
    }, [selectedYear, taxPeriodId, organizationId]);

    const isLoading = useAppSelector(s => selectIsThunkPending(s, getAisS4S5DashboardData.typePrefix));

    return (
        <AisDashboardCard
            title="Статистика запросов по С4, С5"
            className={className}
            bodyClassName={cn('ais-request-statistics-dashboard')}
            style={{flexBasis: '40%'}}
            footer={(
                <div className={cn('ais-request-statistics-dashboard__footer')}>
                    По состоянию на: <span className={cn('text-gray-600')}>{moment(new Date()).format(DATE_DMY)}</span>
                </div>
            )}
        >
            <Spin
                spinning={isLoading}
                tip="Загрузка..."
            >
                <div className={cn('ais-request-statistics-dashboard__legend')}>
                    <div className="d-flex gap-1-5 align-items-center">
                        <div className="text-size-14 d-inline">Год</div>
                        <CustomSelect
                            style={{minWidth: 80}}
                            value={selectedYear}
                            onChange={v => {
                                setSelectedYear(v);
                            }}
                            entries={generateYearSelectionForDashboard().map(year => ({
                                value: year,
                                label: `${year}`,
                            }))}
                        />
                    </div>
                    <div className={cn('ais-request-statistics-dashboard__legend__body')}>
                        <div>Сценарий запроса</div>
                        <div className="d-flex align items-center gap-2">
                            <div className="d-flex align-items-center gap-1">
                                <div
                                    style={{background: '#FFE068'}}
                                    className={cn('ais-request-statistics-dashboard__legend__mark')}
                                />
                                <div> 4</div>
                            </div>
                            <div className="d-flex align-items-center gap-1">
                                <div
                                    style={{background: '#F9B042'}}
                                    className={cn('ais-request-statistics-dashboard__legend__mark')}
                                /> 5
                            </div>
                        </div>
                    </div>
                </div>
                <div className={cn('ais-request-statistics-dashboard__chart')}>
                    <div className={cn('ais-request-statistics-dashboard__chart__body')}>
                        {s4s5DataForChart?.map(v => (
                            <VerticalBarChart
                                entries={[{
                                    value: v.scenario4.percent,
                                }, {
                                    value: v.scenario5.percent,
                                    color: '#F9B042',
                                }]}
                                tooltip={(
                                    <div>
                                        <span className={cn('fw-600')}>{v.month}</span>
                                        <div>
                                            Сценарий 4: <b>{v.scenario4.count}</b>
                                        </div>
                                        <div>
                                            Сценарий 5: <b>{v.scenario5.count}</b>
                                        </div>
                                    </div>
                                )}
                                title={v.month.substring(0, 3)}
                            />
                        ))}
                    </div>
                </div>
            </Spin>
        </AisDashboardCard>
    );
};
