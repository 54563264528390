import {Select} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React from 'react';

import {
    Entity, EntityValue,
} from 'modules/data';
import {
    FilterEntityData,
    FormEntityData,
    IReferenceResolverParams,
} from 'modules/data/data-types';
import {FieldMeta, IAdditionalInputOptions, IAdditionalOptions} from 'modules/metadata/metadata-types';
import {getReferenceResolverFunction} from 'modules/metadata/metadata-utils';
import {isObject} from 'shared/utils';

import {FormMode} from '../../form-types';
import {CustomSelectSpinner} from '../custom-select/components/custom-select-spinner';
import {useReferenceSelectAdditionalOptions} from './hooks/reference-select-additional-options-hook';
import {useReferenceSelectConfig} from './hooks/reference-select-config-hook';
import {useReferenceSelectOptions} from './hooks/reference-select-options-hook';
import {SearchableDropdown} from './searchable-dropdown';
import {TagRenderer} from './tag-renderer';
import {getReferenceParamsValues, getReferenceResolver} from './utils/reference-select.utils';

import './reference-select.less';

export const normalizeValueForViewing = (
    currentValue: any,
) => {
    let result: string | string[] = '';
    const referenceResolver = getReferenceResolverFunction('meaning', 'id', 'description', 'lookupCode');

    if (typeof currentValue === 'string') {
        result = currentValue
            .split(/,\s*/)
            .join('; ');
    } else if (Array.isArray(currentValue)) {
        result = currentValue
            .map(record => referenceResolver(record)?.label || record)
            .join('; ');
    } else if (isObject(currentValue)) {
        result = (referenceResolver(currentValue as Record<string, any>)?.label);
    }

    return result;
};

export interface IAdditionalLoadOptions {
    onNotCreateKey?: boolean;
    displayFieldKey?: string;
    dependentInputKey?: string;
    additionalInputOptions?: IAdditionalInputOptions[];
    isSetFilter?: boolean;
    requestFieldKey?: string;
    requestKey?: string;
}

export interface ReferenceSelectProps {
    field: FieldMeta;
    entityName: string;
    referenceUrl: string;
    additionalOptions?: IAdditionalOptions;
    isFilterable?: boolean;
    multipleMode?: boolean;
    path?: IReferenceResolverParams['path'];
    data?: Entity[];
    shouldSimpleDisplayValue?: boolean;
    value?: Entity;
    onChange?: (value: any) => void;
    disabled?: boolean;
    referenceParamsKeys?: Record<string, any>;
    referenceParamsDefault?: Record<string, any>;
    isDependsOnMainFilter?: string;
    filter?: FilterEntityData;
    formData?: FormEntityData;
    stringStructure?: string;
    onNotCreateKey?: boolean;
    displayFieldKey?: string;
    requestFieldKey?: string;
    dependentInputKey?: string;
    additionalInputOptions?: IAdditionalInputOptions[];
    isSetFilter?: boolean;
    fixedDropdown?: boolean;
    useContext?: boolean;
    useFirstOptionAsDefaultValue?: boolean;
    form?: FormInstance;
    defaultValue?: string;
    defaultValueByRule?: string;
    hideSearch?: boolean;
    isClearable?: boolean;
    formMode?: FormMode;
}

export const ReferenceSelect: React.FunctionComponent<ReferenceSelectProps> = ({
    referenceUrl,
    field,
    value,
    isFilterable,
    multipleMode,
    shouldSimpleDisplayValue,
    disabled,
    path,
    stringStructure,
    fixedDropdown = true,
    displayFieldKey,
    requestFieldKey,
    form,
    referenceParamsKeys,
    defaultValue,
    hideSearch,
    defaultValueByRule,
    isClearable,
    formMode,
    formData,
    ...props
}: ReferenceSelectProps) => {
    const referenceParamsValues = getReferenceParamsValues(referenceParamsKeys, form);
    const referenceResolver = getReferenceResolver({referenceUrl, path}, stringStructure);

    const mode = multipleMode ? 'multiple' : undefined;
    const {
        options,
        getSelectedValuesFromOptions,
        valueReferenceSelect,
        normalizeValue,
        handleChange,
        defaultValueOption,
        loading,
        placeholder,
    } = useReferenceSelectOptions({
        ...props,
        referenceResolver,
        referenceUrl,
        value,
        displayFieldKey,
        requestFieldKey,
        referenceParamsValues,
        defaultValue,
        form,
        field,
        defaultValueByRule,
    });

    useReferenceSelectAdditionalOptions({
        ...props,
        key: field.key,
        form,
        formValue: value,
        referenceUrl,
        formMode,
        formData,
    });

    const {
        dropdownOpen,
        setDropdownOpen,
        renderOptions,
        suffixIcon,
        dropdownInputValue,
        setDropdownInputValue,
        setDropdownSelectedIcon,
    } = useReferenceSelectConfig(options, disabled, multipleMode, isFilterable, displayFieldKey, field.key);

    const filterOption = (inputValue: string, option: EntityValue) => {
        const {children} = option as Record<string, any> || {};
        return children?.toLowerCase().includes(inputValue.toLowerCase());
    };

    if (shouldSimpleDisplayValue) {
        return (
            <>
                {normalizeValueForViewing(
                    getSelectedValuesFromOptions(normalizeValue(value) || ''),
                )}
            </>
        );
    }
    return (
        <Select
            {...props}
            showArrow
            allowClear={isClearable}
            showSearch={!hideSearch}
            className="select-field"
            mode={mode}
            filterOption={filterOption}
            onChange={handleChange}
            loading={loading}
            disabled={loading || disabled}
            value={(() => {
                if (loading) return <CustomSelectSpinner /> as unknown as React.ReactText;
                return valueReferenceSelect() || defaultValueOption();
            })()}
            open={dropdownOpen}
            tagRender={TagRenderer}
            onDropdownVisibleChange={v => setDropdownOpen(v)}
            placeholder={placeholder}
            // чтобы dropdown сохранял позицию
            getPopupContainer={
                fixedDropdown
                    ? trigger => trigger.parentNode
                    : () => document.getElementsByClassName('form-with-tabs')[0]
            }
            menuItemSelectedIcon={mode && setDropdownSelectedIcon}
            suffixIcon={suffixIcon}
            dropdownRender={isFilterable && multipleMode ? (menu: React.ReactElement) => (
                <SearchableDropdown
                    menu={menu}
                    inputValue={dropdownInputValue}
                    setInputValue={setDropdownInputValue}
                />
            ) : undefined}
        >
            {renderOptions}
        </Select>
    );
};
