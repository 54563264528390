import isMatch from 'lodash/isMatch';

import {
    OrganizationStructureTabValidationErrors as ValidationErrorMessages,
    CommonInfoFieldNames,
    CompanyGroupFieldNames,
    ForeignParentsFieldNames,
    ParticipantsFieldNames,
} from 'pages/ais/organization-structure/organization-structure-page.constants';
import {DeepPartial} from 'shared/types/generics';
import {areArraysLengthEqual, hasCommonKey} from 'shared/utils/arrays';
import {OrganizationStructureDto} from 'store/slices/ais-slice/organization-structure';

interface GetValidationMessagesArgs {
    invalidFields: string[];
    isAffiliationSet: boolean;
    isForeignParentSet: boolean;
}

export const getValidationMessages = ({
    invalidFields,
    isAffiliationSet,
    isForeignParentSet,
}: GetValidationMessagesArgs): string[] => {
    const messages = [];

    if (hasCommonKey({source: invalidFields, search: Object.keys(CommonInfoFieldNames)})) {
        messages.push(ValidationErrorMessages.commonInfo);
    }
    if (isAffiliationSet) {
        if (hasCommonKey({source: invalidFields, search: Object.keys(CompanyGroupFieldNames)})) {
            messages.push(ValidationErrorMessages.companyGroup);
        }
        if (hasCommonKey({source: invalidFields, search: Object.keys(ParticipantsFieldNames)})) {
            messages.push(ValidationErrorMessages.participants);
        }
    }
    if (isForeignParentSet && hasCommonKey({source: invalidFields, search: Object.keys(ForeignParentsFieldNames)})) {
        messages.push(ValidationErrorMessages.foreignParentsInformation);
    }

    return messages;
};

const extractSubEntitiesFromOrgStructureDto = (orgStructure: DeepPartial<OrganizationStructureDto>) => {
    const {
        companyGroup,
        participants,
        ...restCommonInfo
    } = orgStructure ?? {};
    const {foreignParentsInformation: foreignParents, ...restCompanyGroup} = companyGroup ?? {};

    return ({
        commonInfo: {
            restCommonInfo,
        },
        companyGroup: {
            ...restCompanyGroup,
        },
        foreignParents,
        participants,
    });
};

interface IsFormValuesDirtyArgs {
    initialValues: OrganizationStructureDto;
    currentValues: DeepPartial<OrganizationStructureDto>;
}
export const isFormValuesDirty = ({initialValues, currentValues}: IsFormValuesDirtyArgs): boolean => {
    const {
        commonInfo: initialCommonInfo,
        companyGroup: initialCompanyGroup,
        foreignParents: initialForeignParents,
        participants: initialParticipants,
    } = extractSubEntitiesFromOrgStructureDto(initialValues);
    const {
        commonInfo: currentCommonInfo,
        companyGroup: currentCompanyGroup,
        foreignParents: currentForeignParents,
        participants: currentParticipants,
    } = extractSubEntitiesFromOrgStructureDto(currentValues);

    if (
        !areArraysLengthEqual(initialForeignParents, currentForeignParents)
        || !areArraysLengthEqual(initialParticipants, currentParticipants)
    ) {
        return true;
    }

    return (
        !isMatch(initialCommonInfo, currentCommonInfo)
        || !isMatch(initialCompanyGroup, currentCompanyGroup)
        || !isMatch({a: initialForeignParents}, {a: currentForeignParents})
        || !isMatch({a: initialParticipants}, {a: currentParticipants})
    );
};
