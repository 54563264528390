import {Spin} from 'antd';
import cn from 'classnames';
import React, {FC, useEffect, useState} from 'react';

import {CustomCard} from 'components/@common/widgets/custom-card';
import {CustomTable} from 'components/@common/widgets/custom-table';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {loadAisCommentsRequestTable, selectCommentsRequestTableData} from 'store/slices/ais-slice/ais-comments-request';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import s from './ais-comments-request-result-page.module.less';
import {
    COMMENTS_REQUEST_DEFAULT_PAGE_SIZE,
    TABLE_PAGINATION_CONFIG,
    formCommentsRequestTableHeader,
} from './constants/ais-comments-request-result.constants';

interface AisCommentsRequestResultPageProps {}

export const AisCommentsRequestResultPage: FC<AisCommentsRequestResultPageProps> = () => {
    const dispatch = useAppDispatch();
    const [paginationCurrent, setPaginationCurrent] = useState<number>(1);
    const [paginationPageSize, setPaginationPageSize] = useState<number>(COMMENTS_REQUEST_DEFAULT_PAGE_SIZE);

    const fetchTableData = (
        searchValue?: string | null,
        pagination?: {
            paginationPageSize?: number;
            paginationCurrent?: number;
        },
    ) => {
        dispatch(loadAisCommentsRequestTable({
            paginationPageSize: pagination?.paginationPageSize || COMMENTS_REQUEST_DEFAULT_PAGE_SIZE,
            search: searchValue,
            sorterField: 'receivingDate',
            sorterOrder: 'descend',
            ...pagination,
        }));
    };

    useEffect(() => {
        fetchTableData();
    }, []);

    const isLoading = useAppSelector(
        st => selectIsThunkPending(st, loadAisCommentsRequestTable.typePrefix),
    );

    const commentsRequestTable = useAppSelector(selectCommentsRequestTableData);

    const handlePaginationChange = (page: number, pageSize?: number | undefined) => {
        setPaginationCurrent(page);
        setPaginationPageSize(pageSize || COMMENTS_REQUEST_DEFAULT_PAGE_SIZE);
        fetchTableData(null, {
            paginationCurrent: page,
            paginationPageSize: pageSize,
        });
    };

    return (
        <CustomCard
            title="Запросы на предоставление комментариев к результатам ФА"
            className={cn(s['comments-request'])}
        >
            {/* todo: TAXSERVICE-7211 реализовать поиск по разделу после доработки бэкенда
            <ActionBar
                side="right"
                className={cn(s['comments-request__buttons-bar'])}
            >
                <Search
                    setSearch={setSearch}
                    fetchCallback={fetchTableData}
                />
            </ActionBar> */}

            <Spin
                spinning={isLoading}
                tip="Загрузка..."
            >
                <CustomTable
                    rowKey="id"
                    columns={formCommentsRequestTableHeader(paginationCurrent, paginationPageSize)}
                    pagination={{
                        ...TABLE_PAGINATION_CONFIG,
                        onChange: handlePaginationChange,
                    }}
                    dataSource={commentsRequestTable}
                />
            </Spin>
        </CustomCard>
    );
};
