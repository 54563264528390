// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._166IJL54oSO3WKGQL4F1yK {\n  height: 100%;\n}\n._3Zl-ksXktFnEvAwFsgeqQG {\n  margin-bottom: 24px;\n}\n", "",{"version":3,"sources":["webpack://src/pages/ais/comments-request-result/ais-comments-request-result-page.module.less"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".comments-request {\n  height: 100%;\n\n  &__buttons-bar {\n    margin-bottom: 24px;\n  }\n}\n\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comments-request": "_166IJL54oSO3WKGQL4F1yK",
	"comments-request__buttons-bar": "_3Zl-ksXktFnEvAwFsgeqQG"
};
export default ___CSS_LOADER_EXPORT___;
