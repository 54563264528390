import {Form, Spin} from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {
    Cell, Legend, Pie, PieChart,
} from 'recharts';
import {ContentType} from 'recharts/types/component/DefaultLegendContent';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {AisMainPageDashboardProps} from 'pages/ais/ais-main-page/ais-main-page-types';
import {DATE_DMY, MONTHS} from 'shared/constants/date-format';
import {getDaysInMonth, getHoursString} from 'shared/utils/date-time';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AisDashboardCode} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-constants';
import {selectUnavailabilityDashboardDataForChart} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-selectors';
import {getAisSystemUnavailabilityDashboardData} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {generateYearSelectionForDashboard} from '../../ais-main-page.utils';
import {AisDashboardCard} from '../@common/ais-dashboard-card';
import {PIE_CHART_COLORS} from './ais-unavailability-chart-dashboard.constants';
import s from './ais-unavailability-chart-dashboard.module.less';

export const AisUnavailabilityChartDashboard: React.FunctionComponent<AisMainPageDashboardProps> = ({
    context,
    className,
}: AisMainPageDashboardProps) => {
    const today = new Date();
    const [selectedYear, setSelectedYear] = useState(today.getFullYear());
    const [selectedMonth, setSelectedMonth] = useState(today.getMonth() + 1);
    const [selectedDay, setSelectedDay] = useState(null);
    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(
        st => selectIsThunkPending(st, getAisSystemUnavailabilityDashboardData.typePrefix),
    );

    const unavailabilityDataForChart = useAppSelector(selectUnavailabilityDashboardDataForChart);

    const daysEntriesForSelect = selectedMonth ? Array.from({
        length: getDaysInMonth(
            selectedYear,
            selectedMonth,
        ),
    }, (_, i) => ({
        value: i + 1,
        label: (i + 1).toString(),
    })) : [];

    const getMonthsEntriesForSelect = () => (
        selectedYear === today.getFullYear()
            ? MONTHS.slice(0, today.getMonth() + 1)
            : MONTHS
    );
    const {taxPeriodId, organizationId} = context || {};

    useEffect(() => {
        if (!taxPeriodId && !organizationId) return;
        dispatch(getAisSystemUnavailabilityDashboardData({
            code: AisDashboardCode.SYSTEM_UNAVAILABILITY,
            year: selectedYear,
            month: selectedMonth,
            day: selectedDay,
            taxPeriodId,
            organizationId,
        }));
    }, [selectedYear, selectedMonth, selectedDay, taxPeriodId, organizationId]);

    useEffect(() => {
        if (!daysEntriesForSelect.some(i => i.value === selectedDay)) {
            setSelectedDay(null);
        }
    }, [selectedYear, selectedMonth]);

    const renderCustomLegend: ContentType = props => {
        const {payload} = props;
        return (
            <div className={cn(s['dashboard__custom-legend'])}>
                <ul className={cn(s['dashboard__custom-legend__list'])}>
                    {payload && payload.map((entry, index) => (
                        <li
                            key={`item-${PIE_CHART_COLORS[index]}`}
                        >
                            <div
                                className={cn(s['dashboard__custom-legend__list_color'])}
                                style={{
                                    background: PIE_CHART_COLORS[index],
                                }}
                            />
                            {entry.value}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <AisDashboardCard
            title="Недоступность ИС НП"
            className={className}
            bodyClassName={cn(s.dashboard)}
            footer={(
                <div className={cn(s['dashboard__state-section__text'])}>{'По состоянию на: '}
                    <span className={cn(s['dashboard__state-section__date'])}>
                        {moment().format(DATE_DMY)}
                    </span>
                </div>
            )}
        >
            <Spin
                spinning={isLoading}
                wrapperClassName={cn(s.dashboard__spinner)}
                tip="Загрузка..."
            >
                <div className={cn(s.dashboard__container)}>
                    <section>
                        <Form
                            layout="vertical"
                            className={cn('d-flex gap-1 flex-grow-children-1', s.dashboard__form)}
                        >
                            <Form.Item label="Год">
                                <CustomSelect
                                    settings={{placeholder: 'Год'}}
                                    value={selectedYear}
                                    onChange={v => {
                                        setSelectedYear(v);
                                    }}
                                    entries={generateYearSelectionForDashboard().map(year => ({
                                        value: year,
                                        label: `${year}`,
                                    }))}
                                />
                            </Form.Item>
                            <Form.Item label="Месяц">
                                <CustomSelect
                                    settings={{
                                        placeholder: 'Месяц',
                                        showNullValueAsUndefined: true,
                                        isClearable: true,

                                    }}
                                    value={selectedMonth}
                                    onChange={v => {
                                        setSelectedMonth(v);
                                    }}
                                    entries={getMonthsEntriesForSelect()}
                                />
                            </Form.Item>
                            <Form.Item label="День">
                                <CustomSelect
                                    settings={
                                        {
                                            placeholder: 'День',
                                            isDisabled: !selectedMonth,
                                            showNullValueAsUndefined: true,
                                            isClearable: true,

                                        }
                                    }
                                    value={selectedDay}
                                    onChange={v => {
                                        setSelectedDay(v);
                                    }}
                                    entries={daysEntriesForSelect}
                                />
                            </Form.Item>
                        </Form>
                    </section>

                    <section className={cn('d-flex justify-content-center', s['dashboard__chart-section'])}>
                        <PieChart
                            width={340}
                            height={340}
                        >
                            <Pie
                                isAnimationActive={false}
                                dataKey="value"
                                data={[{name: 'ИС НП недоступна', value: unavailabilityDataForChart?.unavailableHours},
                                    {name: 'ИС НП доступна', value: unavailabilityDataForChart?.availableHours}]}
                                innerRadius={85}
                                cy="50%"
                                outerRadius={140}
                                stroke="none"
                            >
                                <Cell
                                    key="ИС НП недоступна"
                                    fill={PIE_CHART_COLORS[0]}
                                />
                                <Cell
                                    key="ИС НП доступна"
                                    fill={PIE_CHART_COLORS[1]}
                                />
                            </Pie>
                            <Legend
                                content={renderCustomLegend}
                                verticalAlign="bottom"
                                height={36}
                            />
                        </PieChart>
                        <p className={cn(s['dashboard__legend-title'])}>
                            Количество времени
                        </p>
                        <p className={cn(s['dashboard__chart-title'])}>
                            <span className={cn(s['dashboard__chart-title__hours'])}>
                                {unavailabilityDataForChart?.unavailableHours || 0}
                            </span>
                            {` ${getHoursString(unavailabilityDataForChart?.unavailableHours)} недоступности`}
                        </p>
                    </section>
                </div>
            </Spin>
        </AisDashboardCard>
    );
};
