import {createAsyncThunk} from '@reduxjs/toolkit';
import {AxiosResponse} from 'axios';

import {LinkDto} from 'shared/types/links';
import {getCustomLinkConfig} from 'store/slices/custom-link-slice/custom-link-slice-api';
import {customLinkSliceName} from 'store/slices/custom-link-slice/custom-link-slice-constants';

export interface LoadCustomLinkConfigArgs {
    key: string;
    url: string;
}

export const loadCustomLinkConfig = createAsyncThunk<AxiosResponse<LinkDto>, LoadCustomLinkConfigArgs>(
    `${customLinkSliceName}/loadCustomLinkConfig`,
    async (args, {rejectWithValue}) => {
        try {
            const {url} = args;
            const response = await getCustomLinkConfig(url);

            return response;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
