import {PlusCircleOutlined} from '@ant-design/icons';
import {Button, Form} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import React, {useEffect, useState} from 'react';

import {AdministrationMonitorServiceDynamicFields} from 'components/form/fields/administration-monitor-service-dynamic-fields';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent as TriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {ModalOpenerComponent} from 'components/modal-opener-component';
import {resetLoadedData} from 'modules/data';
import {EntityType} from 'shared/constants/entities';
import {useLazyGetAdditionalFormFieldsByMonitorLookupCodeQuery, useRunAdministrationMonitorServiceMutation} from 'store/api/monitor-services/monitor-services.api';
import {useAppDispatch} from 'store/config/hooks';

import {TableActionProps} from '../table-action-types';
import {OPENTEXT_LOOKUP_CODE} from './open-launch-program-modal.constants';
import s from './open-launch-program-modal.module.less';

interface TableLaunchProgramModalActionProps extends Pick<TableActionProps, 'entityName'> {
}

export const TableLaunchProgramModalAction: React.FunctionComponent<TableLaunchProgramModalActionProps> = ({
    entityName,
}: TableLaunchProgramModalActionProps) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
    const [program, setProgram] = useState<string>('');
    const [form] = useForm<Record<string, unknown>>();

    const handleCancel = () => {
        setIsModalOpen(prev => !prev);
        form.resetFields();
    };

    const dispatch = useAppDispatch();

    const [
        getAdditionalFormFieldsByMonitorLookupCode,
        {
            currentData: fetchedData,
            isFetching: isDynamicFieldsFetching,
        },
    ] = useLazyGetAdditionalFormFieldsByMonitorLookupCodeQuery();

    const {id, programArgs: dynamicFields} = fetchedData || {};

    useEffect(() => {
        if (program === OPENTEXT_LOOKUP_CODE) {
            form.validateFields();
        }
    }, [program]);

    const [
        runAdministrationMonitorService,
        {isSuccess: isFormSubmitted},
    ] = useRunAdministrationMonitorServiceMutation();

    useEffect(() => {
        if (isFormSubmitted) {
            handleCancel();
            dispatch(resetLoadedData(entityName, EntityType.TABLE));
        }
    }, [isFormSubmitted]);

    return (
        <ModalOpenerComponent
            isOpen={isModalOpen}
            shouldConfirm={() => form.isFieldsTouched()}
            setIsOpen={setIsModalOpen}
            controlLabels={{
                save: 'Запустить',
            }}
            hideControls={{
                cancel: true,
            }}
            handleCancel={handleCancel}
            handleSave={() => {
                const data = form.getFieldsValue();
                form.validateFields()
                    .then(() => {
                        if (id) runAdministrationMonitorService({data, id});
                    });
            }}
            disabledControls={{
                save: program === OPENTEXT_LOOKUP_CODE
                    ? (isSubmitButtonDisabled || isDynamicFieldsFetching)
                    : isDynamicFieldsFetching,
            }}
            component={(
                <Button
                    icon={<PlusCircleOutlined />}
                    type="primary"
                >
                    Запуск
                </Button>
            )}
            modalProps={{
                title: 'Параметры запуска программы',
                forceRender: true,
                destroyOnClose: false,
                centered: true,
                width: '980px',
            }}
        >
            <Form
                layout="vertical"
                form={form}
                className="form"
                onValuesChange={(_, allValues) => {
                    if (program === OPENTEXT_LOOKUP_CODE) {
                        if ((allValues?.P_DATE_FROM !== undefined
                          || allValues?.P_DATE_TO !== undefined)
                          && !!allValues?.P_DOC_IDS
                        ) {
                            setIsSubmitButtonDisabled(true);
                            form.validateFields();
                        } else {
                            setIsSubmitButtonDisabled(false);
                            form.validateFields();
                        }
                    }
                }}
            >
                <div className={s['administration-service-fields']}>
                    <Form.Item
                        style={{width: 308}}
                        name="nameId"
                        label="Имя программы"
                        required
                    >
                        <CustomSelect
                            handleTriggerEvent={(value, event) => {
                                if (event === TriggerEvent.valueWasSet
                                                || event === TriggerEvent.firstOptionWasSet
                                                || event === TriggerEvent.valueHasBeenChangedManually) {
                                    getAdditionalFormFieldsByMonitorLookupCode({lookupCode: value?.lookupCode});
                                    setProgram(value?.lookupCode);
                                }
                            }}
                            settings={{
                                url: '/lookupValue/EXECUTABLE',
                                placeholder: 'Выберите сервис',
                                labelPath: 'meaning',
                                valuePath: 'id',
                                formFieldKey: 'externalServiceId',
                                formInstance: form,
                                showSearch: true,
                                isDisabled: isDynamicFieldsFetching,
                            }}
                        />
                    </Form.Item>

                    <AdministrationMonitorServiceDynamicFields
                        form={form}
                        fields={dynamicFields}
                        entityName={entityName}
                        programName={program}
                    />
                </div>
            </Form>
        </ModalOpenerComponent>
    );
};
