import bytes from 'bytes';
import React from 'react';

import {Entity, EntityValue} from 'modules/data';
import {
    DocumentTypeResponse,
    DocumentUploadFile,
    EntityFileUploadState,
    FileSizeFormat,
    FileUploadStatus,
    FileUploadType,
} from 'modules/documents/documents-types';
import {FieldMeta} from 'modules/metadata';
import {RegionResponse} from 'modules/regions/regions-types';
import {ReactComponent as DeleteIcon} from 'shared/assets/close.svg';
import {createOptionItemsTransformer} from 'shared/utils';

import {ProgressCell} from '../../editable-table/columns/progress-cell/progress-cell';
import {EditableColumn, FieldStateType} from '../../editable-table/editable-table-types';

import './document.less';

type OptionsType = {
    handleDelete: (tableRow: Entity) => void;
    handleEdit: (tableRow: Entity, args: EditFormArgs) => void;
    fileSizeFormat: string | null;
    progress?: {
        fileUploadSlice: EntityFileUploadState;
        fieldKey: string;
    };
};

const createFieldState = (
    uniqueFieldKey: string = '', activityList?: Record<string, FileUploadType>,
) => (fieldName: string, record: Record<string, any>): FieldStateType => {
    const uniqueFieldValue = record?.[uniqueFieldKey];

    return activityList?.[uniqueFieldValue]?.editFieldsPermission?.[fieldName] || {} as FieldStateType;
};

const renderXmlValidateStatus = (
    uniqueFieldKey: string = '', activityList?: Record<string, FileUploadType>,
) => (_: any, record: Record<string, any>) => {
    const uniqueFieldValue = record?.[uniqueFieldKey];

    return activityList?.[uniqueFieldValue]?.validatedMeaning;
};

const renderFileSize = (size: number, mask: string | null): string => {
    if (mask == null) {
        return String(size);
    }
    let flexMask: string | null = null;
    if (mask.toUpperCase() === FileSizeFormat.FLEX) {
        if (size < 1024) flexMask = 'B';
        if (size >= 1024 && size < 1024 * 1024) flexMask = 'Kb';
        if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) flexMask = 'Mb';
        if (size >= 1024 * 1024 * 1024) flexMask = 'Gb';
    }
    return bytes.format(size, {
        unit: (flexMask || mask) as bytes.Unit,
        unitSeparator: ' ',
    });
};

export interface EditFormArgs {
    typeId?: string;
    branchId?: string;
}

export enum keyCols {
    progress = 'progress',
    parsing = 'parsing',
    title = 'title',
    type = 'type',
    sectionCode = 'sectionCode',
    subsectionCode = 'subsectionCode',
    fileName = 'fileName',
    fileSize = 'fileSize',
    xmlValidateStatusCode = 'xmlValidateStatusCode',
    uploadDate = 'uploadDate',
    correctionNumber = 'correctionNumber',
    regNum = 'regNum',
    actions = 'actions',
    docBranchName = 'docBranchName',
    templateCode = 'templateCode',
}

export const generateFileColumnsByMetaData = (
    cols: FieldMeta,
    regions: RegionResponse[],
    documentTypesOnUpload: DocumentTypeResponse[],
    branchNames: DocumentTypeResponse[],
    {
        handleDelete,
        fileSizeFormat,
        progress,
    }: OptionsType,
): EditableColumn => {
    let columnSpecificProps: Record<string, any> = {};
    // eslint-disable-next-line default-case
    switch (cols.key) {
    case keyCols.progress:
        columnSpecificProps = {
            render: (_: any, record: { [x: string]: any }) => {
                const {fieldKey, fileUploadSlice} = progress || {};
                const uniqueValue = record[fieldKey as string];
                const percent = fileUploadSlice?.activityList?.[uniqueValue]?.uploadPercent ?? 0;
                const status = fileUploadSlice?.activityList?.
                    [uniqueValue]?.uploadStatus as FileUploadStatus | undefined;

                return (
                    <ProgressCell
                        status={status}
                        percent={percent}
                    />
                );
            },
        };
        break;
    case keyCols.parsing:
        columnSpecificProps = {
            render: (_: any, record: Record<string, any>) => {
                const {fieldKey, fileUploadSlice} = progress || {};
                const uniqueValue = record[fieldKey as string];
                const percent = fileUploadSlice?.activityList?.[uniqueValue]?.parsingPercent ?? 0;
                const status = fileUploadSlice?.activityList?.
                    [uniqueValue]?.parsingStatus as any as FileUploadStatus | undefined;

                return (
                    <ProgressCell
                        status={status}
                        percent={percent}
                    />
                );
            },
        };
        break;
    case keyCols.type: {
        columnSpecificProps = {
            generateInputProps: (_: string, record?: Entity) => ({
                defaultValue: record?.typeId
                    ? (documentTypesOnUpload.find(
                        el => el.id === record?.typeId,
                    ))?.id : undefined,
                options: createOptionItemsTransformer('meaning', 'id')(documentTypesOnUpload),
            }),
        };
        break;
    }
    case keyCols.sectionCode:
        columnSpecificProps = {
            render: (text: string) => (regions.find(region => region.regionCode === text)?.regionFullName),
            generateInputProps: () => ({
                options: createOptionItemsTransformer('regionFullName', 'regionCode')(regions),
            }),
        };
        break;
    case keyCols.subsectionCode:
        columnSpecificProps = {
            generateInputProps: (_: string, record?: Entity) => {
                const subregionList = regions
                    .find(({regionCode}: RegionResponse) => regionCode === record?.sectionCode)?.subregionList || [];
                return {
                    options: createOptionItemsTransformer('name', 'code')(subregionList),
                };
            },
        };
        break;
    case keyCols.fileSize:
        columnSpecificProps = {
            render: (size: string | number) => renderFileSize(+size, fileSizeFormat),
        };
        break;
    case keyCols.xmlValidateStatusCode:
        columnSpecificProps = {
            render: renderXmlValidateStatus(progress?.fieldKey, progress?.fileUploadSlice?.activityList),
        };
        break;
    case keyCols.uploadDate:
    case keyCols.correctionNumber:
    case keyCols.regNum:
        columnSpecificProps = {
            getFieldState: createFieldState(progress?.fieldKey, progress?.fileUploadSlice?.activityList),
        };
        break;
    case keyCols.actions:
        columnSpecificProps = {
            render: (_: any, record: Record<string, EntityValue>) => (
                <DeleteIcon
                    style={{cursor: 'pointer', marginTop: 5}}
                    onClick={() => handleDelete(record)}
                />
            ),
        };
        break;
    case keyCols.templateCode:
        columnSpecificProps = {
            generateInputProps: (_: string, record: DocumentUploadFile) => {
                if (record.templatesTypeOptions) {
                    return {
                        entries: createOptionItemsTransformer('meaning', 'lookupCode')(record.templatesTypeOptions),
                        value: record.templatesTypeOptions.length === 1 && record.templatesTypeOptions[0].meaning,
                        required: record.templatesTypeOptions.length > 1,
                        settings: {
                            placeholder: 'Не выбрано',
                            isDisabled: record?.templatesTypeOptions.length <= 0,
                        },
                    };
                }
                return null;
            },
        };
        break;
    case keyCols.docBranchName: {
        columnSpecificProps = {
            generateInputProps: (_: string, record?: Entity) => {
                const docTypeBranchId = documentTypesOnUpload.find(el => el.id === record?.branchId)?.id;
                const defaultBranchId = branchNames.find(branch => branch?.attribute1 === 'MAIN')?.id;
                return {
                    defaultValue: record?.branchId
                        ? docTypeBranchId
                        : defaultBranchId,
                    options: createOptionItemsTransformer('meaning', 'id')(branchNames),
                };
            },
        };
        break;
    }
    }
    return {
        ...cols,
        dataIndex: cols.dataIndex || cols.key,
        getFieldState: undefined,
        inputProps: undefined,
        ...columnSpecificProps,
    };
};

export const hasHeaderOrganization = (
    hasBranchesLinkingFlag?: boolean,
    branchNames?: DocumentTypeResponse[],
) => {
    if (!hasBranchesLinkingFlag) return true; // раздел филиалов не используется, проверка не нужна
    return branchNames?.some(branch => branch?.attribute1 === 'MAIN');
};
