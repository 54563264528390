import axios from 'axios';

import {isProduction} from '../is-production';
import {METADATA_MOCK_RULES} from './metadata-mock-rules';

const extractMetadataInfo = (url: string | undefined) => {
    if (!url) return undefined;

    const metadataRegexp = /^(.*)\/(.*)\/(.*)\/$/;

    const metadataInfo = (() => {
        let info;
        const execResult = metadataRegexp.exec(url);
        if (execResult && (execResult?.length ?? 0) === 4) {
            const l = execResult?.length;
            if (execResult[l - 3] === 'meta') {
                info = {
                    entityType: execResult[l - 1],
                    entityName: execResult[l - 2],
                };
            }
        }
        return info;
    })();

    return metadataInfo;
};

interface ApplyMockRulesArgs {
    entityType: string;
    entityName: string;
    data: any;
}

interface FindRuleInMocksArgs {
    entityType?: string;
    entityName?: string;
}

const findRuleInMocks = ({
    entityName,
    entityType,
}: FindRuleInMocksArgs) => {
    const rule = METADATA_MOCK_RULES.find(r => r.entityName === entityName && r.entityType === entityType);
    return rule;
};

const applyMockRules = ({entityName, entityType, data}: ApplyMockRulesArgs) => {
    const rule = findRuleInMocks({entityName, entityType});

    if (!rule) return data;

    const {action = 'REPLACE'} = rule;

    const metadata: object = (() => {
        const {metadata: meta} = rule;
        let metaObject;
        if (typeof meta === 'function') {
            metaObject = meta(data);
        }
        if (typeof meta === 'string') {
            try {
                metaObject = JSON.parse(meta);
            } catch {
                console.warn(`[MetadataMock] Couldn't parse meta for ${entityName} (${entityType}).`);
            }
        }
        if (typeof meta === 'object') {
            metaObject = meta;
        }
        return metaObject;
    })();

    let metadataToApply = data;

    switch (action) {
    case 'REPLACE':
        metadataToApply = metadata ?? {};
        break;
    case 'INJECT':
        metadataToApply = {
            ...data,
            ...(metadata ?? {}),
        };
        break;
    default:
    }

    console.warn(`[MetadataMock] Applying mock [${action}] for`
        + ` ${entityName} (${entityType})\n\n With data >>\n`, metadataToApply);

    return metadataToApply;
};

export const createMetadataMockInterceptors = () => {
    if (isProduction()) return;

    axios.interceptors.response.use(response => {
        let interceptedResponse = response;

        const metadataInfo = extractMetadataInfo(response.config.url);
        if (metadataInfo) {
            interceptedResponse = {
                ...response,
                data: applyMockRules({
                    ...metadataInfo,
                    data: response.data,
                }),
            };
        }

        return interceptedResponse;
    }, error => {
        const {response} = error;
        const metadataInfo = extractMetadataInfo(response?.config.url);

        const {
            entityName,
            entityType,
        } = metadataInfo ?? {};

        if (metadataInfo && findRuleInMocks({entityName, entityType})) {
            return {
                ...response,
                status: 200,
                statusText: 'OK',
                data: applyMockRules({
                    ...metadataInfo,
                    data: {},
                }),
            };
        }

        return Promise.reject(error);
    });
};
