import {Button, Menu} from 'antd';
import Spin from 'antd/es/spin';
import cn from 'classnames';
import React, {
    useEffect, useMemo, useRef, useState,
} from 'react';

import {ActionBar} from 'components/@common/widgets/action-bar';
import {CustomCard} from 'components/@common/widgets/custom-card';
import {KebabMenu} from 'components/@common/widgets/kebab-menu';
import {ParametersFilter} from 'components/@common/widgets/parameters-filter';
import {ParametersFilterRef} from 'components/@common/widgets/parameters-filter/parameters-filter';
import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {UIBlocker} from 'components/ui-blocker';
import {selectContextRawData} from 'modules/context/context-selector';
import {ReactComponent as ReloadOutlined} from 'shared/assets/reload.svg';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {omitEmptyLines, showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {
    changeStatusAisDocumentsInInterface,
    changeStatusAisDocumentsInInterfaceByFilter,
    loadAisDocumentsInterfaceData,
    loadAisDocumentsInterfaceFields,
} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AIS_INTERFACE_ROLE_SECTION} from './ais-documents-interface-constants';
import {AisDocumentsParametersFilter} from './ais-documents-interface-parameters-filter';
import {AisDocumentsInterfaceTable, AisDocumentsInterfaceTableRef} from './ais-documents-interface-table';
import {
    DocumentsInterfaceParametersFilter,
    DocumentsInterfaceParametersFilterForm,
} from './ais-documents-interface-types';
import {ColumnsSettingModal} from './modals/columns-setting-modal/columns-setting-modal';
import {DeleteConfirmationModal} from './modals/delete-confirmation-modal';
import {PublishConfirmationModal} from './modals/publish-confirmation-modal';

import './ais-documents-interface.less';

interface AisDocumentsRegisterProps {}

export const AisDocumentsInterface: React.FC<AisDocumentsRegisterProps> = () => {
    const dispatch = useAppDispatch();

    const [documentsParametersFilter, setDocumentsParametersFilter] = useState<DocumentsInterfaceParametersFilter>();
    const fields = useAppSelector(aisSelectors.selectAisInterfaceDocumentsFields);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [hiddenColumnsKeys, setHiddenColumnsKeys] = useState<string[]>([]);
    const filteredColumns = useMemo(() => {
        if (!fields?.columns) {
            return [];
        }

        return fields?.columns?.filter(column => !hiddenColumnsKeys.includes(column?.id));
    }, [hiddenColumnsKeys, fields]);

    const isFieldsLoading = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsInterfaceFields.typePrefix,
    ));
    const isLoadingInterfaceTableData = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsInterfaceData.typePrefix,
    ));
    const interfaceTableLoadError = useAppSelector(s => selectThunkError(
        s, loadAisDocumentsInterfaceData.typePrefix,
    ));
    const {organizationId} = useAppSelector(selectContextRawData) ?? {};

    const publishConfirmationModalRef = useRef<ModalOpenerComponentRef>(null);
    const deleteConfirmationModalRef = useRef<ModalOpenerComponentRef>(null);
    const aisDocumentsInterfaceTableRef = useRef<AisDocumentsInterfaceTableRef>(null);
    const parametersFilterRef = useRef<ParametersFilterRef<DocumentsInterfaceParametersFilterForm>>(null);
    const [isNeedFetchTableData, setIsNeedFetchTableData] = useState(false);

    const {hasUserRoleFunction} = useUserRoleFunctions({
        sections: [AIS_INTERFACE_ROLE_SECTION],
    });

    const resetPage = () => {
        if (documentsParametersFilter) setDocumentsParametersFilter(p => ({...p})); // сброс страницы на 1-ю
        aisDocumentsInterfaceTableRef?.current?.setSortData(p => ({...p, noFetch: true})); // Реакт
        // сбатчит изменения стейта и отправит только 1 запрос, в котором сортировки и фильтры таблицы будут сброшены.
    };

    const refetchTableData = () => {
        setIsNeedFetchTableData(true);
    };

    const handleRefresh = () => {
        aisDocumentsInterfaceTableRef?.current?.setSortData(() => ({
            filters: {},
            sort: {},
            noFetch: true,
        }));
        resetPage();
    };

    const handleSelectAll = () => {
        setIsAllSelected(prev => !prev);
    };

    const handleChangeStatus = () => {
        const res = isAllSelected
            ? dispatch(changeStatusAisDocumentsInInterfaceByFilter({...documentsParametersFilter}))
            : dispatch(changeStatusAisDocumentsInInterface({ids: selectedRowKeys} as {ids: number[]}));

        res.unwrap().then(() => {
            showMessage({message: 'Записи успешно обновлены'});
            setSelectedRowKeys([]);
            setIsAllSelected(false);
            refetchTableData();
        }, error => {
            const {data: responseMessage, status} = error?.response;
            const isBadRequest = status === 400;
            const message = isBadRequest && responseMessage
                ? responseMessage : 'Во время изменения статусов произошла ошибка';
            showMessage({message, isError: true});
        });
    };

    useEffect(() => {
        if (!fields) {
            dispatch(loadAisDocumentsInterfaceFields());
        }
    }, []);

    const isLoading = isFieldsLoading;

    return (
        <div className={cn('ais-documents-interface')}>
            <CustomCard
                isLoading={isLoading}
                accordionTransitionType="1"
                title="Открытый интерфейс Реестр документов (АИС Налог-3)"
                accordionContent={(
                    <ParametersFilter<DocumentsInterfaceParametersFilterForm>
                        ref={parametersFilterRef}
                        onChange={values => {
                            setDocumentsParametersFilter(omitEmptyLines(values));
                        }}
                        onClear={() => {
                            dispatch(aisSliceActions.resetInterfaceDocuments());
                            refetchTableData();
                        }}
                        className={cn('ais-documents-interface__filters')}
                    >
                        {AisDocumentsParametersFilter}
                    </ParametersFilter>
                )}
                isAccordionContentVisibleByDefault
            >
                <ActionBar>
                    <ColumnsSettingModal
                        columns={fields?.columns}
                        hideColumns={setHiddenColumnsKeys}
                        hiddenColumnsKeys={hiddenColumnsKeys}
                    />
                    <Button
                        disabled={isLoadingInterfaceTableData}
                        type="default"
                        onClick={refetchTableData}
                        icon={<ReloadOutlined />}
                    >
                        Обновить
                    </Button>
                    <KebabMenu placeholder={[
                        UserRoleFunction.CHANGE_DOCUMENT_PUBLICATION_STATUS,
                    ].every(f => !hasUserRoleFunction(f)) ? <>Нет доступных действий</> : undefined}
                    >
                        <Menu>
                            {hasUserRoleFunction(UserRoleFunction.CHANGE_DOCUMENT_PUBLICATION_STATUS) && (
                                <Menu.Item
                                    disabled={!selectedRowKeys.length}
                                    onClick={handleChangeStatus}
                                >Изменить статус записи
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.CHANGE_DOCUMENT_PUBLICATION_STATUS) && (
                                <Menu.Item
                                    disabled={!selectedRowKeys.length}
                                    onClick={() => {
                                        deleteConfirmationModalRef.current?.showModal();
                                    }}
                                >Удалить
                                </Menu.Item>
                            )}

                            {hasUserRoleFunction(UserRoleFunction.CHANGE_DOCUMENT_PUBLICATION_STATUS) && (
                                <Menu.Item
                                    onClick={() => {
                                        publishConfirmationModalRef.current?.showModal();
                                    }}
                                >Опубликовать
                                </Menu.Item>
                            )}

                            <PublishConfirmationModal
                                ref={publishConfirmationModalRef}
                                refetchTableData={refetchTableData}
                                organizationId={organizationId}
                            />
                            <DeleteConfirmationModal
                                isAllSelected={isAllSelected}
                                ref={deleteConfirmationModalRef}
                                selectedRowKeys={selectedRowKeys}
                                documentsParametersFilter={documentsParametersFilter}
                                refetchTableData={refetchTableData}
                                setSelectedRowKeys={setSelectedRowKeys}
                                setIsAllSelected={setIsAllSelected}
                            />
                        </Menu>
                    </KebabMenu>
                    <Button
                        type="default"
                        onClick={handleSelectAll}
                    >
                        {isAllSelected ? 'Отменить выделение' : 'Выделить все'}
                    </Button>
                </ActionBar>
                <Spin
                    spinning={isLoadingInterfaceTableData}
                    tip="Загрузка данных реестра..."
                >
                    <UIBlocker
                        thunkError={{
                            error: interfaceTableLoadError,
                            verbose: true,
                        }}
                        extra={(
                            <>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        handleRefresh();
                                    }}
                                    icon={<ReloadOutlined />}
                                >
                                    Обновить
                                </Button>
                            </>
                        )}
                    >
                        <AisDocumentsInterfaceTable
                            rawColumns={filteredColumns}
                            documentsParametersFilter={documentsParametersFilter}
                            selectedRowKeys={selectedRowKeys}
                            setSelectedRowKeys={setSelectedRowKeys}
                            ref={aisDocumentsInterfaceTableRef}
                            isAllSelected={isAllSelected}
                            isNeedFetchTableData={isNeedFetchTableData}
                            setIsNeedFetchTableData={setIsNeedFetchTableData}
                            refetchTableData={refetchTableData}
                        />
                    </UIBlocker>
                </Spin>
            </CustomCard>
        </div>
    );
};
