import {Spin} from 'antd';
import cn from 'classnames';
import {union} from 'lodash';
import React, {
    ReactElement, useEffect, useLayoutEffect, useRef, useState,
} from 'react';

import {StateSetter} from 'shared/types/generics';

import './custom-tabs.less';

const BASIC_DELAY = 100 as const;
const MAX_PERFORMANCE = 100 as const;

export interface TabProps {
    tab: CustomTab;
    isSelected: boolean;
    tabInfo: TabInfo;
    setTabInfo: StateSetter<TabInfo>;
    contentSpinner?: {
        isSpinning?: boolean;
        tip?: string;
    };
    noRenderContent?: boolean;
}

export const Tab: React.FC<TabProps> = ({
    tab,
    tabInfo,
    setTabInfo,
    contentSpinner = {},
    noRenderContent,
}: TabProps) => {
    const {isHeavy, showSpinnerAlways} = tab;
    const {isSpinning = false, tip} = contentSpinner;

    const [shouldRender, setShouldRender] = useState(false);
    useLayoutEffect(() => {
        if (!isHeavy) { setShouldRender(true); return; }

        const {skipSpinningKeys} = tabInfo;

        if (!skipSpinningKeys.includes(tab.key) || showSpinnerAlways) {
            const startPerformance = performance.now();
            setTimeout(() => {
                setShouldRender(true);
                const endPerformance = performance.now();
                if (endPerformance - startPerformance < MAX_PERFORMANCE + BASIC_DELAY) {
                    setTabInfo(p => {
                        const newKeys = union(p.skipSpinningKeys, [tab.key]);
                        return {
                            ...p,
                            skipSpinningKeys: newKeys,
                        };
                    });
                }
            }, BASIC_DELAY);
        } else {
            const startPerformance = performance.now();
            setShouldRender(true);
            const endPerformance = performance.now();
            if (startPerformance - endPerformance > MAX_PERFORMANCE) {
                setTabInfo(p => ({
                    ...p,
                    skipSpinningKeys: p.skipSpinningKeys.filter(k => k !== tab.key),
                }));
            }
        }
    }, []);

    const tabContentRef = useRef(null);
    return (
        <>
            <div className={cn('custom-tabs__body__item__title')}>
                {tab.header || tab.title}
            </div>
            <div ref={tabContentRef}>
                <Spin
                    spinning={isSpinning}
                    tip={tip}
                >
                    {(() => {
                        if (!shouldRender) {
                            return (
                                <div
                                    className="d-flex align-items-center justify-content-center"
                                    style={{marginTop: 32}}
                                >
                                    <Spin
                                        tip="Загрузка вкладки..."
                                    />
                                </div>
                            );
                        }
                        if (noRenderContent) return null;
                        return tab.content;
                    })()}
                </Spin>
            </div>
        </>
    );
};

interface CustomTab {
    title?: string;
    key: React.Key;
    header?: string | ReactElement; // наменование вкладки при открытии
    content?: string | ReactElement | ReactElement[];
    isRenderPostponed?: boolean; // отрендерить только после первого перехода на вкладку
    noRenderWhileOnAnotherTab?: boolean; // не рендерить, если открыта другая вкладка
    isHeavy?: boolean; /* флаг для тяжёлых вкладок, которым нужно время на рендеринг */
    showSpinnerAlways?: boolean; /* показывать спиннер загрузки всегда, не учитывая производительность */
    isTabTitleHidden?: boolean;
    noRenderContent?: boolean;
    isDisabled?: boolean;
}

interface CustomTabsProps {
    tabs: CustomTab[];
    selectedTabKey?: CustomTab['key'];
    setSelectedTabKey?: React.Dispatch<React.SetStateAction<React.Key>>;
    contentSpinner?: {
        isSpinning?: boolean;
        tip?: string;
    };
    variant?: 'side-menu' | 'up-menu';
    hideMenu?: boolean;
    defaultRenderBehaviourOnSwitchingTabs?: 'unmount' | 'preserve';
}

interface TabInfo {
    skipSpinningKeys: React.Key[];
}

export const CustomTabs: React.FC<CustomTabsProps> = (
    {
        tabs,
        selectedTabKey,
        setSelectedTabKey,
        contentSpinner,
        variant = 'side-menu',
        defaultRenderBehaviourOnSwitchingTabs,
        hideMenu,
    }: CustomTabsProps,
) => {
    const [tabKeysToRender, setTabKeysToRender] = useState<React.Key[]>(
        tabs.filter(tab => !tab.isRenderPostponed).map(tab => tab.key),
    );

    const [tabsInfo, setTabsInfo] = useState<TabInfo>({
        skipSpinningKeys: [],
    });

    const isWithUpMenu = variant === 'up-menu';

    useEffect(() => {
        if (!selectedTabKey) return;
        if (!tabKeysToRender.includes(selectedTabKey)) {
            setTabKeysToRender(p => ([...p, selectedTabKey]));
        }
    }, [selectedTabKey]);

    return (
        <div className={cn('custom-tabs', {
            'custom-tabs_with-up-menu': isWithUpMenu,
        })}
        >
            {!hideMenu && (
                <div className="custom-tabs__menu">
                    {tabs.map(tab => (
                        tab?.title && (
                            <div
                                onClick={() => {
                                    if (!tab.isDisabled) {
                                        setSelectedTabKey?.(tab.key);
                                    }
                                }}
                                key={tab.key}
                                className={cn('custom-tabs__menu__tab', {
                                    'custom-tabs__menu__tab__selected': selectedTabKey === tab.key,
                                    'custom-tabs__menu__tab__is-hidden': tab.isTabTitleHidden,
                                    disabled: tab.isDisabled,
                                })}
                            >
                                {tab.title}
                            </div>
                        )))}
                </div>
            )}

            <div className="custom-tabs__body">
                {tabs.map(tab => (
                    <div
                        key={tab?.key}
                        className={cn('custom-tabs__body__item', {
                            'custom-tabs__body__item_invisible': selectedTabKey !== tab.key,
                        })}
                    >
                        {(() => {
                            const {
                                noRenderWhileOnAnotherTab = (
                                    defaultRenderBehaviourOnSwitchingTabs === 'unmount'
                                ),
                            } = tab;

                            if (!tabKeysToRender.includes(tab.key)) return null;
                            if (noRenderWhileOnAnotherTab && selectedTabKey !== tab.key) return null;
                            return (
                                <Tab
                                    tabInfo={tabsInfo}
                                    setTabInfo={setTabsInfo}
                                    tab={tab}
                                    isSelected={selectedTabKey !== tab.key}
                                    contentSpinner={contentSpinner}
                                    noRenderContent={tab.noRenderContent}
                                />
                            );
                        })()}
                    </div>
                ))}
            </div>
        </div>
    );
};
