import {message} from 'antd';
import React, {
    useRef, useState,
} from 'react';

import {ConfirmModal} from 'components/confirm-modal/confirm-modal';
import {FormMode} from 'components/form';
import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {
    updateAisDocumentInInterfaceCard,
} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisInterfaceDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {AIS_INTERFACE_ROLE_SECTION} from '../../ais-documents-interface-constants';
import {DocumentCardModalContent, DocumentCardModalContentRef} from './document-card-modal-content';

interface DocumentCardModalProps {
    initialInterfaceDocument?: AisInterfaceDocumentDto;
    initialFormMode?: FormMode;
    isDisabled: boolean;
    refetchTableData: () => void;
}

export const DocumentCardModal = React.forwardRef<
    ModalOpenerComponentRef, DocumentCardModalProps>(({
        initialInterfaceDocument,
        initialFormMode = FormMode.EDIT,
        isDisabled,
        refetchTableData,
    }: DocumentCardModalProps) => {
        const modalOpenerRef = useRef<ModalOpenerComponentRef>(null);
        const documentCardRef = useRef<DocumentCardModalContentRef>(null);

        const [formMode] = useState(initialFormMode);
        const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

        const dispatch = useAppDispatch();

        const isUpdatingDocument = useAppSelector(s => (
            selectIsThunkPending(s, updateAisDocumentInInterfaceCard.typePrefix)
        ));

        const {hasUserRoleFunction} = useUserRoleFunctions({
            sections: [AIS_INTERFACE_ROLE_SECTION],
        });

        const handleFinish = async () => {
            const form = documentCardRef.current?.form;
            const formValues = form?.getFieldsValue();
            const validationResults = await form?.validateFields().catch(r => {
                message.error(
                    'Пожалуйста, проверьте корректность введённых данных"',
                    1,
                );
                return r;
            });

            if (validationResults?.errorFields?.length) {
                return;
            }

            const documentUpdatePromise = dispatch(updateAisDocumentInInterfaceCard({
                id: documentCardRef.current?.interfaceDocument?.id,
                ...formValues,
            })).unwrap();

            Promise.allSettled([
                documentUpdatePromise,
            ]).then(([
                documentUpdateResult,
            ]) => {
                modalOpenerRef.current?.closeModal();
                if (
                    documentUpdateResult?.status === 'fulfilled'
                ) {
                    showMessage({message: 'Карточка документа успешно обновлена'});
                    refetchTableData();
                }
                if (documentUpdateResult?.status === 'rejected') {
                    showMessage({message: 'Во время обновления карточки произошла ошибка', isError: true});
                }
            });
        };

        const isDocumentDisabled = !hasUserRoleFunction(UserRoleFunction.UPDATE);

        return (
            <ModalOpenerComponent
                ref={modalOpenerRef}
                componentWrapperClassNames="d-inline w-100"
                component={initialFormMode === FormMode.EDIT ? (
                    <span>
                        Редактировать
                    </span>
                ) : undefined}
                modalProps={{
                    centered: true,
                    title: 'Редактирование записи',
                    forceRender: false,
                    destroyOnClose: true,
                }}
                loadingControls={{
                    save: isUpdatingDocument,
                }}
                controlLabels={{
                    save: 'Сохранить',
                }}
                handleCancel={setIsModalOpen => {
                    setIsModalOpen(false);
                }}
                hideControls={{
                    save: isDocumentDisabled,
                }}
                handleSave={() => setIsConfirmModalVisible(true)}
                preventComponentClick={() => isDisabled}
            >
                <DocumentCardModalContent
                    formMode={formMode}
                    ref={documentCardRef}
                    initialInterfaceDocument={initialInterfaceDocument}
                    isDisabled={isDocumentDisabled}
                />
                <ConfirmModal
                    title="Подтверждение"
                    visible={isConfirmModalVisible}
                    content="Вы уверены, что хотите внести изменения?"
                    onCancel={() => setIsConfirmModalVisible(false)}
                    cancelText="Нет"
                    approveText="Да"
                    onConfirm={() => {
                        setIsConfirmModalVisible(false);
                        handleFinish();
                    }}
                />
            </ModalOpenerComponent>
        );
    });
