import {RequestType} from 'modules/metadata';
import {RequestTableResponseWithPagination} from 'shared/types/requests';
import {performRequest} from 'shared/utils';

import {AIS3_COMMENTS_REQUEST_API_PREFIX} from './ais-comments-request-constants';
import {AisCommentsRequestResultTableDto, AisInspectorCommentDto} from './ais-comments-request-types';

export const fetchAisCommentsRequestTable = (
    params?: FetchAisCommentsRequestTableParams,
) => performRequest<RequestTableResponseWithPagination<AisCommentsRequestResultTableDto>>({
    url: AIS3_COMMENTS_REQUEST_API_PREFIX,
    method: RequestType.GET,
    params,
});

export const fetchAisCommentsRequestRowsTable = (
    {
        requestId,
    }: FetchAisCommentsRequestRowsTableParams,
) => performRequest<AisInspectorCommentDto>({
    url: `${AIS3_COMMENTS_REQUEST_API_PREFIX}/${requestId}/`,
    method: RequestType.GET,
});

export const postAisCommentsRequest = async (
    data: PostAisCommentsRequestData,
) => performRequest<string>({
    url: `${AIS3_COMMENTS_REQUEST_API_PREFIX}/saveCommentsRequest`,
    method: RequestType.POST,
    data,
});

export interface FetchAisCommentsRequestTableParams {
  length?: number;
  paginationCurrent?: number;
  paginationPageSize?: number;
  search?: string | null;
  sorterField?: string;
  sorterOrder?: 'ascend' | 'descend';
  start?: number;
}

export interface PostAisCommentsRequestData {
  id: number;
  comment?: string;
}

export interface FetchAisCommentsRequestRowsTableParams {
  requestId: number;
}
