import {createAsyncThunk} from '@reduxjs/toolkit';

import {
    DeleteDocumentUploadTemplateArgs,
    deleteDocumentUploadTemplateConfiguration,
    FetchDocumentUploadTemplateArgs,
    fetchDocumentUploadTemplateData,
    fetchSettingTemplateTransferTargetColumns,
    patchDocumentUploadTemplate,
    postDocumentUploadTemplateConfiguration,
} from './document-upload-template-slice-api';
import {documentUploadTemplateSliceName} from './document-upload-template-slice-constants';
import {
    DocumentUploadTemplateConfiguratorConverted,
    DocumentUploadTemplateData, DocumentUploadTemplateTypeLookupData,
} from './document-upload-template-slice-type';

export const loadDocumentUploadTemplateData = createAsyncThunk<
    DocumentUploadTemplateData, FetchDocumentUploadTemplateArgs
>(
    `${documentUploadTemplateSliceName}/loadDocumentUploadTemplateData`,
    async (args, {rejectWithValue}) => {
        try {
            const response = await fetchDocumentUploadTemplateData(args);
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const createDocumentUploadTemplateConfiguration = createAsyncThunk<
    DocumentUploadTemplateConfiguratorConverted, {
    entityName: string;
    data: DocumentUploadTemplateConfiguratorConverted;
}>(
    `${documentUploadTemplateSliceName}/createTableDocumentUploadTemplateConfiguration`,
    async ({data, entityName: url}, {rejectWithValue}) => {
        try {
            await postDocumentUploadTemplateConfiguration({data, url});
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const updateDocumentUploadTemplateConfiguration = createAsyncThunk<
    Partial<DocumentUploadTemplateData>, {
    entityName: string;
    templateCode: string;
    data: Partial<DocumentUploadTemplateData>;
}>(
    `${documentUploadTemplateSliceName}/updateDocumentUploadTemplateConfiguration`,
    async ({templateCode, data, entityName}, {rejectWithValue}) => {
        try {
            await patchDocumentUploadTemplate({
                entityName,
                templateCode,
                data,
            });
            return data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
export const loadSettingTemplateTargetColumns = createAsyncThunk<
    DocumentUploadTemplateTypeLookupData[], {
    sublistCode: string;
    sublistId?: number;
}>(
    `${documentUploadTemplateSliceName}/loadSettingTemplateTargetColumns`,
    async ({sublistCode, sublistId}, {rejectWithValue}) => {
        try {
            const response = await fetchSettingTemplateTransferTargetColumns({
                sublistCode, sublistId,
            });
            return response.data;
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);
export const removeDocumentUploadTemplateConfiguration = createAsyncThunk<
    string, DeleteDocumentUploadTemplateArgs>(
        `${documentUploadTemplateSliceName}/removeDocumentUploadTemplateConfiguration`,
        async (args, {rejectWithValue}) => {
            try {
                const {data} = await deleteDocumentUploadTemplateConfiguration(args);
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
