import {Form, Spin} from 'antd';
import cn from 'classnames';
import moment from 'moment';
import React, {useEffect, useState} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {AisMainPageDashboardProps} from 'pages/ais/ais-main-page/ais-main-page-types';
import {ReactComponent as DocumentWithCalculatorSvg} from 'shared/assets/ais/document-with-calculator.svg';
import {ReactComponent as MonitorWithDocumentSvg} from 'shared/assets/ais/monitor-with-document.svg';
import {DATE_DMY} from 'shared/constants/date-format';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AisDashboardCode} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-constants';
import {selectSummaryTransactionsProtocolDashboardDataTransformed} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-selectors';
import {getAisSummaryTransactionsProtocolDashboardData} from 'store/slices/ais-slice/ais-dashboards/ais-dashboards-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {formatNumberWithSpaces, generateYearSelectionForDashboard} from '../../ais-main-page.utils';
import {AisDashboardCard} from '../@common/ais-dashboard-card';
import {AisSingleValueWidget} from '../@common/ais-single-value-widget';
import {transformDifferenceValue} from './ais-operations-protocol-dashboard-utils';

import './ais-operations-protocol-dashboard.less';

export const AisOperationsProtocolDashboard: React.FunctionComponent<AisMainPageDashboardProps> = ({
    context,
    className,
}: AisMainPageDashboardProps) => {
    const dispatch = useAppDispatch();

    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedPeriod, setSelectedPeriod] = useState<number | undefined>(undefined);
    const {taxPeriodId, organizationId} = context || {};

    useEffect(() => {
        if (selectedPeriod === undefined || (!taxPeriodId && !organizationId)) return;
        dispatch(getAisSummaryTransactionsProtocolDashboardData({
            code: AisDashboardCode.SUMMARY_TRANSACTIONS_PROTOCOL_STATISTIC,
            year: selectedYear,
            periodId: selectedPeriod,
            taxPeriodId,
            organizationId,
        }));
    }, [selectedYear, selectedPeriod, taxPeriodId, organizationId]);

    const dashboardData = useAppSelector(selectSummaryTransactionsProtocolDashboardDataTransformed);

    const {
        allCountOperation,
        differenceCountOperation,
        differencePUDOperation,
        percentagePUDOperation,
    } = dashboardData ?? {};

    const isLoading = useAppSelector(s => selectIsThunkPending(
        s, getAisSummaryTransactionsProtocolDashboardData.typePrefix,
    ));

    return (
        <AisDashboardCard
            title="Сводный протокол операций"
            className={className}
            bodyClassName="ais-operations-protocol-dashboard"
            footer={(
                <div className={cn('ais-operations-protocol-dashboard__footer')}>
                    По состоянию на: <span className={cn('text-gray-600')}>{moment(new Date()).format(DATE_DMY)}</span>
                </div>
            )}
        >
            <Spin
                tip="Загрузка..."
                spinning={isLoading || selectedPeriod === undefined}
            >
                <div>
                    <Form
                        layout="horizontal"
                        colon={false}
                        className="d-flex gap-2"
                    >
                        <Form.Item label="Год">
                            <CustomSelect
                                value={selectedYear}
                                entries={generateYearSelectionForDashboard().map(year => ({
                                    label: `${year}`,
                                    value: year,
                                }))}
                                onChange={year => {
                                    setSelectedYear(year);
                                }}
                                style={{width: 100}}
                                settings={{placeholder: 'Год'}}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Период"
                        >
                            <CustomSelect
                                style={{width: 200}}
                                value={selectedPeriod}
                                settings={{
                                    url: 'ais/dashboard/valueLists/FNS_PERIODS_FOR_AIS_WIDGET',
                                    valuePath: 'id',
                                    labelPath: 'meaning',
                                    placeholder: 'Период',
                                    useFirstOptionAfterReceivedFromFetcher: true,
                                }}
                                handleTriggerEvent={v => {
                                    setSelectedPeriod(v.id);
                                }}

                            />
                        </Form.Item>
                    </Form>
                </div>
                <div className={cn(
                    'ais-operations-protocol-dashboard__widgets',
                )}
                >
                    <AisSingleValueWidget
                        icon={<DocumentWithCalculatorSvg />}
                        title="Общее количество операций"
                        mainValue={`${formatNumberWithSpaces(allCountOperation) ?? '0'}`}
                        supValue={`${transformDifferenceValue(differenceCountOperation)}`}
                    />
                    <AisSingleValueWidget
                        icon={<MonitorWithDocumentSvg />}
                        title="Доля операций с ПУД"
                        mainValue={`${formatNumberWithSpaces(percentagePUDOperation) ?? '0'}%`}
                        supValue={`${transformDifferenceValue(differencePUDOperation)}%`}
                    />
                </div>
            </Spin>
        </AisDashboardCard>
    );
};
