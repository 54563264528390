import {createAsyncThunk} from '@reduxjs/toolkit';

import {ais3SliceName} from 'store/slices/ais-slice/ais-slice-constants';
import {
    fetchOrganizationStructureData,
    saveOrganizationStructureDataRequest,
    SaveOrganizationStructureDataRequestArgs,
} from 'store/slices/ais-slice/organization-structure/organization-structure-api';
import {OrganizationStructureDto} from 'store/slices/ais-slice/organization-structure/organization-structure-types';

export const getOrganizationStructureData = createAsyncThunk<
    OrganizationStructureDto, void>(
        `${ais3SliceName}/getOrganizationStructureData`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await fetchOrganizationStructureData();
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );

export const saveOrganizationStructureData = createAsyncThunk<
    string, SaveOrganizationStructureDataRequestArgs>(
        `${ais3SliceName}/saveOrganizationStructureData`,
        async (args, {rejectWithValue}) => {
            try {
                const response = await saveOrganizationStructureDataRequest(args);
                const {data} = response;
                return data;
            } catch (e) {
                return rejectWithValue(e);
            }
        },
    );
