import {Empty, Form, Input} from 'antd';
import React, {useMemo} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DATE_DMY} from 'shared/constants/date-format';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {useAppSelector} from 'store/config/hooks';
import {aisSelectors} from 'store/slices/ais-slice/ais-slice';
import {AisDocInterfaceFieldTypes} from 'store/slices/ais-slice/ais-slice-types';

interface AisDocumentsParametersFilterProps {
    isFilterApplied?: boolean;
}

export const AisDocumentsParametersFilter = (
    {isFilterApplied}: AisDocumentsParametersFilterProps,
) => {
    const {filters} = useAppSelector(aisSelectors.selectAisInterfaceDocumentsFields) ?? {};

    const {currentState} = useAppHistory();

    const ignoreRequiredByLinkSettings = !!currentState?.[LocationStateKey.LINK_META]?.ignoreRequiredParameters;

    const filtersJSX = useMemo(
        () => filters?.map(f => {
            const lowerCaseLabel = f.label?.toLowerCase();

            const isRequired = !ignoreRequiredByLinkSettings && f.isRequired;

            const inputField = (() => {
                switch (f.type) {
                case AisDocInterfaceFieldTypes.DATE:
                    return (
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE_DMY}
                            disabled={isFilterApplied}
                        />
                    );
                case AisDocInterfaceFieldTypes.REFERENCE:
                    return (
                        <CustomSelect settings={{
                            placeholder: `Выберите ${lowerCaseLabel}`,
                            url: f.referenceUrl,
                            labelPath: 'meaning',
                            valuePath: 'lookupCode',
                            isDisabled: isFilterApplied,
                            isClearable: true,
                        }}
                        />
                    );
                default:
                    return (
                        <Input
                            placeholder={`Введите ${lowerCaseLabel}`}
                            disabled={isFilterApplied}
                        />
                    );
                }
            })();

            return (
                <Form.Item
                    key={f.fieldKey}
                    label={f.label}
                    name={f.fieldKey}
                    rules={[{
                        required: isRequired,
                        message: `Пожалуйста, заполните поле ${lowerCaseLabel}`,
                    }]}
                    required={isRequired}
                    data-type={f.type === AisDocInterfaceFieldTypes.DATE ? 'date' : undefined} // для css
                >
                    {inputField}
                </Form.Item>
            );
        }),
        [filters],
    );

    return filtersJSX?.length ? <>{filtersJSX}</> : (
        <Empty
            style={{width: '100%'}}
            description="Фильтры не найдены"
        />
    );
};
