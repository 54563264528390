import {AxiosResponse} from 'axios';

import {FieldStateType} from 'components/editable-table/editable-table-types';
import {EntityType} from 'shared/constants/entities';
import {CommonMessages} from 'shared/constants/messages';
import {OracleBoolType} from 'shared/types';
import {
    isObject, performRequest, showMessage, showMessageFromResponse,
} from 'shared/utils';
import {createAction} from 'store/config/creators';
import {AppState, Dispatch} from 'store/config/types';
import {selectUserInfo} from 'store/slices/auth-slice';
import {tableReportSliceActions} from 'store/slices/table-report-slice';
import {normalizeBoolValue, normalizeEntityName} from 'utils/common';

import {selectContextRawData} from '../context/context-selector';
import {resetLoadedData} from '../data';
import {resetData} from '../data/data-actions';
import {DefaultTableStateFlat} from '../data/data-types';
import {convertParamsForRequest} from '../data/utils/data.utils';
import {METADATA_MODULE} from '../metadata';
import {
    fetchAcceptableExtensions,
    fetchBranchesLinkingFlag,
    fetchBranchNames,
    fetchDefaultFileInfo,
    fetchDefaultSignInfo,
    fetchDocumentsForSign,
    fetchDocumentsForUploadAttach,
    FetchDocumentsForUploadAttachArgs,
    fetchDocumentsSigningMethod,
    fetchDocumentTypes,
    fetchDocumentTypesOnUpload,
    ParserRequestOptions,
    uploadDocumentFileRequest,
    uploadDocumentSignatureRequest,
    UploadFilesRequestOptions,
    UploadFilesRequestParams,
    UploadSignsRequestOptions,
} from './documents-api';
import {
    BranchNamesActions,
    DocumentsActions,
    DocumentsForSignActions,
    FILE_UNIQUE_FIELD_NAME,
    FileListActions,
    FileUploadActions,
    SIGN_UNIQUE_FIELD_NAME,
    SignatureUploadActions,
    SignListActions,
    SingExtensions,
    TOTAL_ENTITY,
} from './documents-constants';
import {
    selectDocumentFileUploadByEntityName,
    selectDocumentSignatureUploadByEntityName,
    selectDocumentList,
    selectDocumentTypes,
    selectDocumentTypeOnUpload,
    selectDocumentBranchNames,
    selectDocumentExtensions,
    selectDocumentsSigningMethod,
} from './documents-selectors';
import {
    DocumentProperty,
    DocumentsSigningMethod,
    DocumentsUploadMonitoringState,
    DocumentTypeResponse,
    DocumentUploadFile,
    DocumentUploadSignature,
    DocumentUploadSignatureResponse,
    FileActivityResetAllPayload,
    FileActivityResetItemPayload,
    FileActivitySetPayload,
    FileListDtoPayload,
    FileListResetPayload,
    FileListSetPayload,
    FileParsingStatus,
    FileUploadStatus,
    FileUploadType,
    SetDocumentsForUploadAttachPayload,
    SignListResetPayload,
    SignListSetPayload,
    SignUploadType, SingSetExtensionsPayload,
} from './documents-types';
import {
    generateSignatureInfoFromResponse,
    generateUploadFileRequest,
    generateUploadSignRequest,
    getSubsectionFromEntityName,
    makeDefaultFileInfo,
} from './documents-utils';

const setDocumentTypes = createAction<DocumentTypeResponse[]>(DocumentsActions.SET_DOCUMENT_TYPES);
const setDocumentTypesOnUpload = createAction<DocumentTypeResponse[]>(DocumentsActions.SET_DOCUMENT_TYPES_ON_UPLOAD);
const setBranchNames = createAction<DocumentTypeResponse[]>(BranchNamesActions.SET_BRANCH_NAMES);
const setDocumentList = createAction<DocumentTypeResponse[]>(DocumentsForSignActions.SET_DOCUMENT_LIST);
const setExtensions = createAction<SingSetExtensionsPayload>(SingExtensions.SET_SIGN_EXTENSIONS);
const setDocumentProperty = createAction<DocumentProperty>(DocumentsActions.SET_DOCUMENT_PROPERTY);

const setDocumentsUploadMonitoring = createAction<DocumentsUploadMonitoringState>(
    DocumentsActions.SET_DOCUMENTS_UPLOAD_MONITORING,
);
const setDocumentSigningMethod = createAction<DocumentsSigningMethod>(
    DocumentsActions.SET_DOCUMENT_SIGNING_METHOD,
);

const setDocumentsForUploadAttach = createAction<SetDocumentsForUploadAttachPayload>(
    DocumentsActions.SET_DOCUMENTS_FOR_UPLOAD_ATTACH,
);
const {
    setModalReportDownloadDocuments,
} = tableReportSliceActions;

export const setDocumentsPagination = createAction<DefaultTableStateFlat>(DocumentsActions.SET_DOCUMENTS_PAGINATION);
export const resetDocumentList = createAction<FileListResetPayload>(FileListActions.RESET_FILE_LIST);

export const loadDocumentsForUploadAttach = ({
    sublistCode,
}: FetchDocumentsForUploadAttachArgs) => async (dispatch: Dispatch) => {
    const {data: documentsForUpload} = await fetchDocumentsForUploadAttach({sublistCode});

    dispatch(setDocumentsForUploadAttach({
        sublistCode,
        documents: documentsForUpload,
    }));
};

export const loadBranchesLinkingFlag = () => async (dispatch: Dispatch) => {
    const {data: linkingDocumentsToBranches} = await fetchBranchesLinkingFlag();

    dispatch(setDocumentProperty({
        hasBranchesLinkingFlag: normalizeBoolValue(linkingDocumentsToBranches),
    }));
};

export const loadDocumentTypesOnUpload = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const currentDocumentTypesOnUpload = selectDocumentTypeOnUpload(getState());

    if (!currentDocumentTypesOnUpload || !currentDocumentTypesOnUpload.length) {
        try {
            const {data: documentTypesOnUpload} = await fetchDocumentTypesOnUpload();
            dispatch(setDocumentTypesOnUpload(documentTypesOnUpload));
        } catch (e) {
            if (e.response) {
                dispatch(setDocumentTypesOnUpload(e.response.data));
            }
        }
    }
};

export const loadDocumentTypes = () => async (dispatch: Dispatch, getState: () => AppState) => {
    const currentTypes = selectDocumentTypes(getState());

    if (!currentTypes || !currentTypes.length) {
        try {
            const {data: documentTypes} = await fetchDocumentTypes();
            dispatch(setDocumentTypes(documentTypes));
        } catch (e) {
            dispatch(setDocumentTypes([]));
            showMessageFromResponse({response: e.response, isError: true});
        }
    }
};
export const loadAcceptableExtensions = () => async (
    dispatch: Dispatch,
    getState: () => AppState,
) => {
    const extensions = selectDocumentExtensions(getState());

    if (!extensions) {
        try {
            const {data} = await fetchAcceptableExtensions();
            dispatch(setExtensions({accept: data}));
        } catch (e) {
            showMessageFromResponse({response: e.response, isError: true});
        }
    }
};

export const clearDocumentsForSign = () => async (dispatch: Dispatch) => {
    dispatch(setDocumentList([]));
};

export const loadDocumentsForSign = (entityName: string) => async (dispatch: Dispatch, getState: () => AppState) => {
    const documentList = selectDocumentList(getState());
    const contextData = selectContextRawData(getState()) ?? {};
    const contextFilter = convertParamsForRequest(contextData);
    const contextParam = {
        ...contextFilter,
        entityName: getSubsectionFromEntityName(entityName),
    };

    if (!documentList || !documentList.length) {
        try {
            const {data} = await fetchDocumentsForSign(contextParam);
            dispatch(setDocumentList(data));
        } catch (e) {
            showMessageFromResponse({response: e.response, isError: true});
        }
    }
};

export const loadDefaultFileInfo = (
    sectionCode: string,
    subsectionCode: string,
    files: File[],
) => async (): Promise<DocumentUploadFile[]> => {
    const {data} = await fetchDefaultFileInfo(sectionCode, subsectionCode, files);
    const filesInfo: DocumentUploadFile[] = [];
    data.forEach(document => {
        const correspondingFile = files.find(file => file.name === document.fileName);
        if (correspondingFile) {
            filesInfo.push(makeDefaultFileInfo(document, correspondingFile));
        }
    });

    return filesInfo;
};

const setActivityState = createAction<FileActivitySetPayload>(FileUploadActions.SET_FILE_ACTIVITY_STATE);
const resetActivityState = createAction<FileActivityResetAllPayload>(FileUploadActions.RESET_ALL_ACTIVITY_STATE);
const resetItemActivityState = createAction<FileActivityResetItemPayload>(FileUploadActions.RESET_FILE_ACTIVITY_STATE);

export const setFileList = createAction<FileListSetPayload>(FileListActions.SET_FILE_LIST);
export const resetFileList = createAction<FileListResetPayload>(FileListActions.RESET_FILE_LIST);
export const dtoFileList = createAction<FileListDtoPayload>(FileListActions.DTO_FILE_LIST);

export const setSignList = createAction<SignListSetPayload>(SignListActions.SET_SIGN_LIST);
export const resetSignList = createAction<SignListResetPayload>(SignListActions.RESET_SIGN_LIST);

const setSignActivityState = createAction<FileActivitySetPayload>(SignatureUploadActions.SET_SIGN_ACTIVITY_STATE);
const resetSignActivityState = createAction<FileActivityResetAllPayload>(
    SignatureUploadActions.RESET_SIGN_ACTIVITY_STATE,
);

export const resetFilesUploadProgress = (
    entityName: string,
    entityType: EntityType = EntityType.TABLE,
) => (dispatch: Dispatch) => {
    dispatch(resetData({entityType, entityName: normalizeEntityName(entityName)}));
};

const getTotalLengthFrom = (progressEvent: Record<string, any>) => {
    const {total, target: {getResponseHeader}} = progressEvent;

    return total
        || getResponseHeader('content-length')
        || getResponseHeader('x-decompressed-content-length');
};

const getUploadProgressPercent = (progressEvent: Record<string, any>) => {
    const {loaded} = progressEvent;
    const total = getTotalLengthFrom(progressEvent);
    const percent = (loaded / total) * 100;

    return +percent.toFixed(2);
};

const createUploadFilesAction = <T extends DocumentUploadFile | DocumentUploadSignature>(
    uniqueFieldName: string = '',
    convertFile: (files: T[], useUploadDate?: boolean) => Record<string, any>[],
    request:
        (data: Record<string, any>,
            options: UploadFilesRequestOptions,
            params?: UploadFilesRequestParams,
        ) => Promise<any>,
) => (
        files: T[], entityName: string = TOTAL_ENTITY, useUploadDate?: boolean,
    ) => async (dispatch: Dispatch, getState: () => AppState) => {
        if (uniqueFieldName.length === 0) {
            console.error('Не выбрано уникальное поле для файла.');
            return;
        }

        const fileUploadSlice = selectDocumentFileUploadByEntityName(entityName)(getState());
        const title = getState()?.[METADATA_MODULE]?.[entityName]?.[EntityType.TABLE]?.title;
        const contextData = selectContextRawData(getState()) ?? {};
        const userInfo = selectUserInfo(getState());
        const userId = userInfo?.userId;

        const params = (contextData && userInfo && {
            organizationId: contextData.organizationId,
            taxPeriodId: contextData.taxPeriodId,
            userId,
        });

        const filteredFiles = files.filter(file => {
            const activityList = fileUploadSlice?.activityList?.[file[uniqueFieldName] as string];
            const isEmptyActivityList = !activityList;
            const isException = activityList?.uploadStatus === FileUploadStatus.EXCEPTION;
            const isEmptyStatus = activityList?.uploadStatus === undefined;

            return isEmptyActivityList || isException || isEmptyStatus;
        });
        const convertedFiles = convertFile(filteredFiles, useUploadDate);

        const requestResult: Record<string, any> = {};
        const getUniqueValue = (data?: Record<string, any>) => (
            isObject(data) ? (data as Record<string, any>)[uniqueFieldName] : undefined
        );

        const changeActivityState = (
            uniqueValue: string, item: FileUploadType,
        ) => dispatch(setActivityState({entityName, uniqueValue, item}));

        const createParsingHandlers = (uniqueValue: string): ParserRequestOptions => {
            let parsingPercent = 0;
            let intervalId: number | undefined;

            return {
                onParsingStart: () => {
                    intervalId = setInterval(() => {
                        if (parsingPercent === 92) {
                            clearInterval(intervalId);
                        }

                        parsingPercent += 4;

                        changeActivityState(uniqueValue, {
                            parsingPercent,
                            parsingStatus: FileParsingStatus.ACTIVE,
                        });
                    }, 4800) as unknown as number;
                },
                onParsingSuccess: (message?: string) => {
                    changeActivityState(uniqueValue, {
                        parsingPercent: 100,
                        parsingStatus: FileParsingStatus.SUCCESS,
                    });

                    clearInterval(intervalId);

                    requestResult[uniqueValue] = message;
                },
                onParsingException: (message?: string) => {
                    changeActivityState(uniqueValue, {
                        parsingStatus: FileParsingStatus.EXCEPTION,
                    });

                    clearInterval(intervalId);

                    requestResult[uniqueValue] = message;
                },
            };
        };

        const createRequestOptions = (uniqueValue: string): UploadFilesRequestOptions => ({
            onFileUploadProgress: (progressEvent: Record<string, any>) => {
                const percent = getUploadProgressPercent(progressEvent);
                const uploadPercent = percent === 100 ? 99 : percent;
                const uploadStatus = FileUploadStatus.NORMAL;

                changeActivityState(uniqueValue, {uploadPercent, uploadStatus});
            },
            onFileUploadSuccess: () => {
                changeActivityState(uniqueValue, {
                    uploadPercent: 100,
                    uploadStatus: FileUploadStatus.SUCCESS,
                });
            },
            onFileUploadException: (message?: string) => {
                changeActivityState(uniqueValue, {
                    uploadStatus: FileUploadStatus.EXCEPTION,
                });

                requestResult[uniqueValue] = message;
            },
            ...createParsingHandlers(uniqueValue),
        });

        try {
            if (filteredFiles.length) {
                showMessage({
                    message: 'Программа запущена\n',
                    modalData: {
                        text: 'Посмотреть результат\n',
                        onClick: () => dispatch(
                            setModalReportDownloadDocuments({isOpen: true, wereDocumentsRead: true}),
                        ),
                    },
                });
                const savedFilesInfo: { fileName: string; saveResult: any }[] = [];
                await Promise.all(convertedFiles.map(async file => {
                    const fileSaveResult = await request(file, createRequestOptions(getUniqueValue(file)), {...params});
                    if (fileSaveResult?.status === 200) {
                        savedFilesInfo.push({fileName: file.fileName, saveResult: fileSaveResult?.data});
                    } else {
                        changeActivityState(getUniqueValue(file), {
                            uploadStatus: FileUploadStatus.EXCEPTION,
                        });
                    }
                }));

                if (savedFilesInfo.length) {
                    dispatch(setDocumentsUploadMonitoring({headers: title, fileList: savedFilesInfo}));
                }
            } else {
                showMessage({message: CommonMessages.UPLOAD_FILES, isError: true});
            }
        } catch (e) {
            console.error(e);
        }
    };

const createUploadSignsAction = <T extends DocumentUploadFile | DocumentUploadSignature>(
    uniqueFieldName: string = '',
    convertFile: (files: T[]) => Record<string, any>[],
    request: (data: Record<string, any>, options: UploadSignsRequestOptions) => Promise<AxiosResponse | void>,
) => (files: T[], entityName: string = TOTAL_ENTITY) => async (dispatch: Dispatch, getState: () => AppState) => {
        if (uniqueFieldName.length === 0) {
            console.error('Не выбрано уникальное поле для файла.');
            return;
        }
        const signUploadSlice = selectDocumentSignatureUploadByEntityName(entityName)(getState());
        const filteredFiles = files.filter(file => {
            const activityList = signUploadSlice?.activityList?.[file[uniqueFieldName] as string];
            const isEmptyActivityList = !activityList;
            const isException = activityList?.uploadStatus === FileUploadStatus.EXCEPTION;
            const isEmptyStatus = activityList?.uploadStatus === undefined;

            return isEmptyActivityList || isException || isEmptyStatus;
        });
        const convertedFiles = convertFile(filteredFiles);
        const requestResult: Record<string, any> = {};
        const getUniqueValue = (data?: Record<string, any>) => (
            isObject(data) ? (data as Record<string, any>)[uniqueFieldName] : undefined
        );
        const changeActivityState = (
            uniqueValue: string, item: SignUploadType,
        ) => dispatch(setSignActivityState({entityName, uniqueValue, item}));

        const createRequestOptions = (uniqueValue: string): UploadSignsRequestOptions => ({
            onFileUploadProgress: (progressEvent: Record<string, any>) => {
                const percent = getUploadProgressPercent(progressEvent);
                const uploadPercent = percent === 100 ? 99 : percent;
                const uploadStatus = FileUploadStatus.NORMAL;

                changeActivityState(uniqueValue, {uploadPercent, uploadStatus});
            },
            onFileUploadException: (message?: string) => {
                changeActivityState(uniqueValue, {
                    uploadPercent: 100,
                    uploadStatus: FileUploadStatus.EXCEPTION,
                });

                requestResult[uniqueValue] = message;
            },
            onFileUploadSuccess: (message?: string) => {
                changeActivityState(uniqueValue, {
                    uploadPercent: 100,
                    uploadStatus: FileUploadStatus.SUCCESS,
                });

                requestResult[uniqueValue] = message;
            },
        });

        try {
            if (filteredFiles.length) {
                showMessage({
                    message:
                        `Загрузка начата.\n
                        По окончанию загрузки появится уведомление.\n
                        Результат можно будет посмотреть в данном модальном окне.\n
                        Не закрывайте приложение до окончания загрузки,
                        иначе файлы могут не сохраниться.`,
                });

                await Promise.all(convertedFiles.map(data => request(
                    data, createRequestOptions(getUniqueValue(data)),
                )));
                // TODO временное решение
                const requestResultKeys = Object.keys(requestResult);

                showMessage({
                    message: requestResultKeys.reduce(
                        (acc: string, resultKey: string) => (`${acc + resultKey}: ${requestResult[resultKey]}\n`),
                        'Загрузка завершена.\nРезультат загрузки файлов:\n',
                    ),
                });

                files.forEach(fileInfo => {
                    if (fileInfo.subsectionCode) {
                        dispatch(resetLoadedData(fileInfo.subsectionCode));
                    }
                });
            } else {
                showMessage({message: CommonMessages.UPLOAD_FILES, isError: true});
            }
        } catch (e) {
            console.error(e);
        }
    };

export const uploadDocumentFiles = createUploadFilesAction<DocumentUploadFile>(
    FILE_UNIQUE_FIELD_NAME,
    generateUploadFileRequest,
    uploadDocumentFileRequest,
);

export const uploadSignatureFiles = createUploadSignsAction<DocumentUploadSignature>(
    SIGN_UNIQUE_FIELD_NAME,
    generateUploadSignRequest,
    uploadDocumentSignatureRequest,
);

export const clearAllFilesInfo = <T extends DocumentUploadFile | DocumentUploadSignature>(
    files: T[], entityName: string = TOTAL_ENTITY,
) => (dispatch: Dispatch) => {
        dispatch(setFileList({entityName, fileList: [] as DocumentUploadFile[]}));
        dispatch(resetActivityState({entityName}));
    };

export const clearAllSignsInfo = <T extends DocumentUploadFile | DocumentUploadSignature>(
    files: T[], entityName: string = TOTAL_ENTITY,
) => (dispatch: Dispatch) => {
        dispatch(setSignList({entityName, signList: [] as DocumentUploadSignature[]}));
        dispatch(resetSignActivityState({entityName}));
    };

const normalizeFlagForUploadFile = (object: Record<string, OracleBoolType>): FieldStateType => {
    if (isObject(object)) {
        return Object
            .keys(object)
            .reduce((acc: FieldStateType, currentKey: string) => (
                {...acc, [currentKey]: object[currentKey] === 'Y'}
            ), {}) as FieldStateType;
    }

    return {
        activated: object === 'Y',
        required: false,
    };
};

export const loadFileRowStateDataInEditableTable = (
    uniqueFieldName: string = FILE_UNIQUE_FIELD_NAME,
) => (
    tableRow: Record<string, any>,
    entityName: string = TOTAL_ENTITY,
) => async (dispatch: Dispatch, getState: () => AppState): Promise<any> => {
    const uniqueValue = tableRow[uniqueFieldName];
    const fileUploadSlice = selectDocumentFileUploadByEntityName(entityName)(getState());
    const changeActivityState = (
        item: FileUploadType,
    ) => dispatch(setActivityState({entityName, uniqueValue, item}));
    const changeFileListState = (
        fileList: DocumentUploadFile[],
    ) => dispatch(setFileList({entityName, fileList}));
    const editFieldsPermissionSlice = fileUploadSlice
        ?.activityList?.[uniqueValue]
        ?.editFieldsPermission || {};

    const formData = new FormData();

    formData.append('docTypeC', tableRow.typeId);
    formData.append('docFile', tableRow.file, tableRow.file.name);
    // TODO Изменить вызов api
    const [validated, uploadDate, correctionNumber, regNum] = await Promise
        .all([
            performRequest({
                method: 'POST',
                url: 'fileupload/validate-status',
                data: formData,
            }),
            performRequest({
                method: 'GET',
                url: 'directories.type.documents/rpc/get-timestamp',
                params: {docTypeC: tableRow.typeId},
            }),
            performRequest({
                method: 'GET',
                url: 'directories.type.documents/rpc/get-corrnum',
                params: {docTypeC: tableRow.typeId},
            }),
            performRequest({
                method: 'GET',
                url: 'directories.type.documents/rpc/get-regnum',
                params: {docTypeC: tableRow.typeId},
            }),
        ])
        .then(result => result.map(item => item.data));

    // Реализация на основе особенностей api
    const {fileList} = fileUploadSlice;
    const fileIndex = fileList.findIndex(file => file[uniqueFieldName] === uniqueValue);
    fileList[fileIndex] = {
        ...tableRow,
        xmlValidateStatusCode: validated?.lookupCode as string | undefined,
    } as DocumentUploadFile;

    changeFileListState([...fileList]);
    changeActivityState({
        validated: !!validated.meaning,
        validatedMeaning: validated.meaning,
        editFieldsPermission: {
            ...editFieldsPermissionSlice,
            uploadDate: normalizeFlagForUploadFile(uploadDate),
            correctionNumber: normalizeFlagForUploadFile(correctionNumber),
            regNum: normalizeFlagForUploadFile(regNum),
        },
    });
};

export const resetRowStateDataForEditableTable = (
    uniqueFieldName: string = FILE_UNIQUE_FIELD_NAME,
    entityName: string = TOTAL_ENTITY,
) => (
    tableRow: Record<string, any>,
) => async (dispatch: Dispatch): Promise<any> => {
    const uniqueValue = tableRow[uniqueFieldName];

    dispatch(resetItemActivityState({entityName, uniqueValue}));
};

export const loadDefaultSignInfo = (
    entityName: string,
    files: File[],
) => async (dispatch: Dispatch, getState: () => AppState) => {
    const contextData = selectContextRawData(getState());
    const contextFilter = convertParamsForRequest(contextData || {});
    const oldSignList = selectDocumentSignatureUploadByEntityName(entityName)(getState())
        ?.signList || [];

    const requestData = {
        ...contextFilter,
        entityName: getSubsectionFromEntityName(entityName),
        files,
    };
    const {data} = await fetchDefaultSignInfo(requestData);
    const signInfo: DocumentUploadSignature[] = [];
    data.forEach((signFile: DocumentUploadSignatureResponse) => {
        const correspondingFile = files.find(file => file.name === signFile.signatureFileName);
        if (correspondingFile) {
            signInfo.push({
                file: correspondingFile,
                fileName: correspondingFile.name,
                createdAt: new Date(),
                ...generateSignatureInfoFromResponse(signFile),
            });
        }
    });

    dispatch(setSignList({entityName: entityName ?? TOTAL_ENTITY, signList: [...oldSignList, ...signInfo]}));
};

export const loadBranchNames = (resetBranchNames?: boolean) => async (dispatch: Dispatch, getState: () => AppState) => {
    const currentBranchNames = selectDocumentBranchNames(getState());

    if (!currentBranchNames || resetBranchNames) {
        try {
            const {data: branchNames} = await fetchBranchNames();
            dispatch(setBranchNames(branchNames));
        } catch (e) {
            dispatch(setBranchNames([]));
            showMessageFromResponse({response: e.response, isError: true});
        }
    }
};

let isLoadingSigningMethod = false;
export const loadDocumentsSigningMethod = () => async (dispatch: Dispatch, getState: () => AppState) => {
    if (isLoadingSigningMethod) return;

    isLoadingSigningMethod = true;

    const currentDocumentsSigningMethod = selectDocumentsSigningMethod(getState());

    if (!currentDocumentsSigningMethod) {
        try {
            const {data: signingMethod} = await fetchDocumentsSigningMethod();
            dispatch(setDocumentSigningMethod(signingMethod));
        } catch (e) {
            dispatch(setDocumentSigningMethod(undefined));
            showMessageFromResponse({response: e.response, isError: true});
        }
    }

    isLoadingSigningMethod = false;
};
