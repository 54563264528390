import compact from 'lodash/compact';

import {TableAttributesCustomSelectEntry} from 'components/report-configuration/tabs/report-table-settings';
import {ReportConfigurationAttribute} from 'store/slices/report-configuration-slice';
import {
    CustomColumnCase,
    ReportConfigurationAlign,
    ReportConfigurationReportGroupDto,
} from 'store/slices/report-configuration-slice/report-configuration-dto';
import {ReportConfigurationConvertedField} from 'store/slices/report-configuration-slice/report-configuration-types';
import {TableReportExtendedConfiguration} from 'store/slices/table-report-slice/table-report-slice-types';

const replaceSpaces = (str: string, replacer: string) => {
    const replaceLeading = (s: string) => {
        const newString: string[] = [];
        let foundFirstLetterFlag = false;
        s.split('').forEach(char => {
            if (char !== ' ') foundFirstLetterFlag = true;
            if (foundFirstLetterFlag) newString.push(char);
            else newString.push(replacer);
        });
        return newString.join('');
    };
    const reverse = (s: string) => s.split('').reverse().join('');
    const stringWithReplaceSpaces = reverse(replaceLeading(reverse(replaceLeading(str))));
    return stringWithReplaceSpaces;
};

const isValuePositive = (str: string): boolean => {
    if (!Number.isNaN(Number(str)) && !Number.isNaN(parseFloat(str))) {
        return Number(str) === 0;
    }
    return str?.trim() === 'Нет' || str?.trim() === '0,00р';
};

const getFontClassName = (style?: string) => {
    const fontClassName = style?.toLowerCase()
        ?.split('font_')
        ?.join('')
        ?.split('_')
        ?.join('-');
    return fontClassName;
};

const getFormatClassNames = (align: string | undefined, style: string | undefined): string[] => {
    // modify align values like LEFT_TOP/MIDDLE_BOTTOM to kebab case
    const alignClassName = align?.toLowerCase()
        ?.split('_').join('-');

    // modify align values like FONT_BOLD/FONT_CURSIVE_UNDERLINE to kebab case and remove "FONT_" part
    const fontClassName = getFontClassName(style);

    return compact([alignClassName, fontClassName]);
};

const isInteger = (value: string) => /^\d+$/.test(value);

export interface ApplyFormatSettingsToRowArgs {
    rowFormats: TableReportExtendedConfiguration['rowFormats'];
    dataIndex: string;
    record: any;
    reportGroups: TableReportExtendedConfiguration['reportGroups'] | undefined;
}

export interface FormatSettingsApplicationResult {
    color: undefined | string;
    background: undefined | string;
    style: undefined | string;
    align: undefined | ReportConfigurationAlign;
}

interface ApplyConditionArgs {
    record: any;
    columnName: string;
    operator: string;
    value: string;
}

const applyCondition = ({
    record,
    columnName,
    operator,
    value,
}: ApplyConditionArgs) => {
    const recordValue = record[columnName];

    const recordValueAsNumber = Number(recordValue);
    const recordValueAsString = String(recordValue);

    const conditionFunctions = {
        EQUALS: () => recordValue == value, // eslint-disable-line
        RESULTING_STRING: () => recordValue == value, // eslint-disable-line
        NOT_EQUALS: () => recordValue != value, // eslint-disable-line
        GREATER_THAN: () => recordValueAsNumber > Number(value),
        LESS_THAN: () => recordValueAsNumber < Number(value),
        GREATER_OR_EQUALS: () => recordValueAsNumber >= Number(value),
        LESS_OR_EQUALS: () => recordValueAsNumber <= Number(value),
        CONTAINS: () => recordValueAsString?.includes?.(value) ?? false,
        NOT_CONTAINS: () => !recordValueAsString?.includes?.(value),
        STARTS_WITH: () => recordValueAsString?.startsWith?.(value),
        NOT_STARTS_WITH: () => !recordValueAsString?.startsWith?.(value),
        ENDS_WITH: () => recordValueAsString?.endsWith?.(value),
        NOT_ENDS_WITH: () => !recordValueAsString?.endsWith?.(value),
        EMPTY: () => recordValue === null || recordValueAsString === '',
        NOT_EMPTY: () => !(recordValue === null || recordValueAsString === ''),
    } as {[index: string]: () => boolean};

    if (!operator || !conditionFunctions[operator]) return false;
    return conditionFunctions[operator]?.() ?? false;
};

const applyFormatSettingsToRow = ({
    dataIndex,
    record,
    rowFormats,
    reportGroups,
}: ApplyFormatSettingsToRowArgs) => {
    const applicationResult: FormatSettingsApplicationResult = {
        color: undefined,
        background: undefined,
        style: undefined,
        align: undefined,
    };

    if (reportGroups) {
        Object.keys(reportGroups).forEach(groupKey => {
            const group = reportGroups[groupKey];

            if (group?.placeholderColumnName
                && record[group.placeholderColumnName] === group.groupLabel) {
                applicationResult.style = group?.style;
                applicationResult.align = group?.align;
            }
        });
    }

    rowFormats.forEach(rowFormat => {
        const {conditions, columns = []} = rowFormat;

        const setAsApplicationResult = () => {
            applicationResult.color = rowFormat.color;
            applicationResult.background = rowFormat.filling;
            applicationResult.style = rowFormat.style;
        };

        // всегда применяем, если columns пустой
        // иначе применяем, если там есть текущая колонка (dataIndex)
        if (!dataIndex || (columns.length && !columns.includes(dataIndex))) return;

        if (!conditions?.length) {
            setAsApplicationResult(); // если нет условий для строки - всегда применяем
            return;
        }

        let isApplied = true;
        conditions.forEach(condition => {
            if (!applyCondition({record, ...condition})) isApplied = false;
        });
        if (isApplied) setAsApplicationResult(); // если условия есть, применяем если выполняются все
    });

    if (applicationResult.style) applicationResult.style = getFontClassName(applicationResult.style);

    return applicationResult;
};

export const tableReportColumnContentUtils = {
    isInteger,
    replaceSpaces,
    isValuePositive,
    getFontClassName,
    getFormatClassNames,
    applyFormatSettingsToRow,
};

export interface CustomColumnConditionValueArgs {
    cases: CustomColumnCase[];
    record: any;
}

export interface CustomColumnValue {
    color?: string;
    icon?: string;
    value?: string;
}

export const getCustomColumnConditionalValue = ({
    record,
    cases,
}: CustomColumnConditionValueArgs) => {
    const resultValue: CustomColumnValue = {
        color: undefined,
        icon: undefined,
        value: undefined,
    };

    cases.every(c => {
        const {conditions} = c;

        const setAsApplicationResult = () => {
            resultValue.color = c?.color;
            resultValue.icon = c?.icon;
            resultValue.value = c?.value;
        };

        if (!conditions?.length) {
            setAsApplicationResult(); // если нет условий для строки - всегда применяем
            return false; // break
        }

        let wasApplied = false;
        conditions.every(condition => {
            if (condition?.operator && applyCondition({record, ...condition})) {
                setAsApplicationResult();
                wasApplied = true;
                return false; // break
            }
            return true; // continue
        });
        return !wasApplied;
    });

    return resultValue;
};

export enum UnnecessaryValueConditions {
    EMPTY = 'EMPTY',
    NOT_EMPTY = 'NOT_EMPTY',
}

export const getGroupingAttributes = (templateConfig?: TableReportExtendedConfiguration) => {
    let groupData;
    if (templateConfig && templateConfig.tableDatasourceColumns) {
        groupData = templateConfig.tableDatasourceColumns.map((field, index) => {
            const {
                type,
                typeGroup,
                name,
                comment,
            } = field as ReportConfigurationAttribute;
            return {
                label: templateConfig?.reportColumns?.[name]?.reportTitle || name,
                value: templateConfig?.tableDatasourceColumns?.[index]?.name,
                type: type ?? 'Тип не найден',
                name,
                typeGroup,
                comment,
            };
        }) as TableAttributesCustomSelectEntry[];
    }
    return groupData;
};

export const convertGroupingDataMapToArray = (
    reportGroups?: {[reportGroupName: string]: ReportConfigurationReportGroupDto},
) => {
    if (!reportGroups) return [];
    const reportGroupKeyNames = Object.keys(reportGroups || [] as object);
    return reportGroupKeyNames.map(keyName => (
        {
            keyName,
            ...reportGroups[keyName],
        }
    )) as ReportConfigurationConvertedField<ReportConfigurationReportGroupDto>;
};
