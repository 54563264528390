// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._36yeDafaJqz07I6570TFHm {\n  color: #333333;\n  border: none;\n  box-shadow: none;\n}\n._36yeDafaJqz07I6570TFHm:hover {\n  color: #587EAD !important;\n}\n._36yeDafaJqz07I6570TFHm:focus {\n  color: #333333 !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/ais/comments-request-result/modals/ais-comment-request-row-modal-opener/ais-comment-request-row-modal-opener.module.less"],"names":[],"mappings":"AAGE;EACE,cAAA;EACA,YAAA;EACA,gBAAA;AAFJ;AAII;EACE,yBAAA;AAFN;AAKI;EACE,yBAAA;AAHN","sourcesContent":["@import 'src/themes/default/default';\n\n.modal {\n  &__comment-button {\n    color: @base-dark;\n    border: none;\n    box-shadow: none;\n\n    &:hover {\n      color: @complement-light !important;\n    }\n\n    &:focus {\n      color: @base-dark !important;\n    }\n  }\n}\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal__comment-button": "_36yeDafaJqz07I6570TFHm"
};
export default ___CSS_LOADER_EXPORT___;
