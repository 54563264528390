import {Menu, Tooltip} from 'antd';
import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';

import {customTableUtils} from 'components/@common/widgets/custom-table';
import {useCustomTable, UseCustomTableArgs} from 'components/@common/widgets/custom-table/custom-table';
import {ColumnSortData} from 'components/@common/widgets/custom-table/table-column-menu';
import {KebabMenu} from 'components/@common/widgets/kebab-menu';
import {IconsMap} from 'components/dynamic-icon';
import {Spinner} from 'components/spinner';
import {selectContextRawData} from 'modules/context/context-selector';
import {useAfterEffect} from 'shared/hooks/use-after-effect';
import {usePagination} from 'shared/hooks/use-pagination';
import {useUserRoleFunctions} from 'shared/hooks/use-user-role-functions';
import {StateSetter} from 'shared/types/generics';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSelectors, aisSliceActions, selectAisSliceMeta} from 'store/slices/ais-slice/ais-slice';
import {loadAisDocumentsInterfaceData} from 'store/slices/ais-slice/ais-slice-thunks';
import {AisDocInterfaceField, AisDocInterfaceFieldTypes} from 'store/slices/ais-slice/ais-slice-types';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';
import {UserRoleFunction} from 'store/slices/user-slice/user-slice-role-functions';

import {
    AIS_INTERFACE_ROLE_SECTION, DEFAULT_PAGE_SIZE, STATUS_CODES, TRUNCATED_COLUMNS, TRUNCATED_TEXT_LENGTH,
} from './ais-documents-interface-constants';
import {DocumentsInterfaceParametersFilterForm} from './ais-documents-interface-types';
import {DocumentCardModal} from './modals/document-card-modal';

const {
    setHeaderStyles,
} = customTableUtils;

interface AisDocumentsInterfaceTableProps {
    rawColumns: AisDocInterfaceField[] | undefined;
    documentsParametersFilter?: DocumentsInterfaceParametersFilterForm;
    selectedRowKeys: React.Key[];
    setSelectedRowKeys: StateSetter<React.Key[]>;
    isAllSelected?: boolean;
    isNeedFetchTableData?: boolean;
    setIsNeedFetchTableData: Dispatch<SetStateAction<boolean>>;
    refetchTableData: () => void;
}
export interface AisDocumentsInterfaceTableRef {
    setSortData: StateSetter<ColumnSortData>;
}

export const AisDocumentsInterfaceTable = React.forwardRef<
AisDocumentsInterfaceTableRef, AisDocumentsInterfaceTableProps>((
    {
        rawColumns,
        documentsParametersFilter,
        selectedRowKeys,
        setSelectedRowKeys,
        isAllSelected,
        isNeedFetchTableData,
        setIsNeedFetchTableData,
        refetchTableData,
    }: AisDocumentsInterfaceTableProps,
    ref,
) => {
    const dispatch = useAppDispatch();
    const [sortData, setSortData] = useState<ColumnSortData>({
        sort: {},
    });

    const {organizationId} = useAppSelector(selectContextRawData) ?? {};
    const {selectAll: selectAllDocuments} = aisSelectors.documentsInterfaceSelectors;

    const isLoadingInterfaceTableData = useAppSelector(s => selectIsThunkPending(
        s, loadAisDocumentsInterfaceData.typePrefix,
    ));

    const documents = useAppSelector(selectAllDocuments);
    const {documentsTotalCount} = useAppSelector(selectAisSliceMeta);
    const shouldLoadWithoutFilters = true;

    const {hasUserRoleFunction, isLoadingUserFunctions} = useUserRoleFunctions({
        sections: [AIS_INTERFACE_ROLE_SECTION],
    });

    const {
        current, pageSize, handleChange, setCurrentManually,
    } = usePagination({
        pageDefault: 1,
        pageSizeDefault: DEFAULT_PAGE_SIZE,
        onChange(page, size) {
            dispatch(loadAisDocumentsInterfaceData({
                pageRequestDto: {
                    paginationPageSize: size,
                    paginationCurrent: page,
                },
                filter: {...documentsParametersFilter},
            }));
        },
    });

    useImperativeHandle(ref, () => ({
        setSortData,
    }));

    const columns: UseCustomTableArgs['columns'] = [
        {
            title: '',
            render: (value: any) => (
                <div className="d-flex align-items-center gap-1-25">
                    <KebabMenu
                        button={{
                            type: 'primary',
                            size: 'small',
                        }}
                        placeholder={[
                            UserRoleFunction.UPDATE,
                        ].every(f => !hasUserRoleFunction(f)) ? <>Нет доступных действий</> : undefined}
                    >
                        <Menu>
                            {hasUserRoleFunction(UserRoleFunction.UPDATE) && (
                                <Menu.Item disabled={value?.statusCode !== STATUS_CODES.NEW}>
                                    <DocumentCardModal
                                        initialInterfaceDocument={value}
                                        refetchTableData={refetchTableData}
                                        isDisabled={value?.statusCode !== STATUS_CODES.NEW}
                                    />
                                </Menu.Item>
                            )}
                        </Menu>
                    </KebabMenu>
                </div>
            ),
        },
    ];

    rawColumns?.forEach(rc => {
        const render = (() => {
            if (rc.type === AisDocInterfaceFieldTypes.MONETARY) {
                return (sum?: string) => (
                    <div className="text-center">
                        {typeof sum === 'string'
                        && Intl.NumberFormat('ru-RU').format(Number(sum?.toString().replace(',', '.')))}
                    </div>
                );
            }
            if (rc.type === AisDocInterfaceFieldTypes.DATE) {
                return (date: string) => (
                    <div className="text-center">
                        {date}
                    </div>
                );
            }
            if (TRUNCATED_COLUMNS.includes(rc.fieldKey)) {
                return (text: string) => {
                    const isTextExceedsLimit = text?.length > TRUNCATED_TEXT_LENGTH;
                    return isTextExceedsLimit
                        ? <Tooltip title={text}>{`${text?.slice(0, TRUNCATED_TEXT_LENGTH)}...`}</Tooltip>
                        : text;
                };
            }

            return undefined;
        })();

        columns.push({
            title: rc.label,
            dataIndex: rc.fieldKey,
            key: rc.fieldKey,
            render,
        });
    });

    const {
        customTableJSX,
    } = useCustomTable({
        tableColumnSortData: sortData,
        setTableColumnSortData: setSortData,
        pagination: {
            position: ['bottomLeft'],
            showQuickJumper: {goButton: <IconsMap.ArrowRightIcon className="pagination_jumper" />},
            showSizeChanger: true,
            locale: {items_per_page: 'на странице', jump_to: ''},
            current,
            pageSizeOptions: ['5', '10', '20', '50', '100', '500'],
            total: (() => {
                if (!isLoadingUserFunctions && !hasUserRoleFunction(UserRoleFunction.VIEW)) return undefined;
                return documentsTotalCount;
            })(),
            pageSize,
            onChange: handleChange,
        },
        rowKey: 'id',
        rowSelection: {
            selectedRowKeys,
            onChange: selectedTableRowKeys => setSelectedRowKeys(selectedTableRowKeys),
            getCheckboxProps: () => ({
                disabled: isAllSelected,
            }),
        },
        // ---
        className: 'ais-documents-interface-table mt-5',
        locale: {
            emptyText: (
                <div style={{marginTop: 30}}>
                    {(() => {
                        if (!hasUserRoleFunction(UserRoleFunction.VIEW) && isLoadingUserFunctions) {
                            return <div><Spinner tip="Загрузка..." /></div>;
                        }
                        if (!hasUserRoleFunction(UserRoleFunction.VIEW) && !isLoadingUserFunctions) {
                            return 'Недостаточно прав для просмотра данных';
                        }
                        if ((documentsParametersFilter || shouldLoadWithoutFilters) && !isLoadingInterfaceTableData) {
                            return 'Ничего не найдено';
                        }
                        return 'Для формирования данных необходимо задать параметры';
                    })()}
                </div>
            ),
        },
        dataSource: (() => {
            if (isLoadingUserFunctions) return [];
            if (!isLoadingUserFunctions && !hasUserRoleFunction(UserRoleFunction.VIEW)) return [];
            if (!documentsParametersFilter && !shouldLoadWithoutFilters) return [];
            return documents;
        })(),
        onColumn: col => ({
            ...setHeaderStyles({
                minWidth: 'min-content',
                maxWidth: '30vw',
                flexGrow: 1,
                textAlign: 'center',
                fontSize: '12px',
                padding: '16px 2.3em 16px 16px',
                textTransform: 'uppercase',
                fontWeight: 600,
            }),
            ...col,
        }),
        bordered: true,
        columns,
    });

    useEffect(() => {
        const loadThunk = dispatch(loadAisDocumentsInterfaceData({
            filter: {},
            pageRequestDto: {
                paginationPageSize: pageSize,
                paginationCurrent: 1,
            },
        }));

        return () => {
            loadThunk.abort();
        };
    }, [organizationId]);

    useAfterEffect(() => {
        const loadThunk = dispatch(loadAisDocumentsInterfaceData({
            filter: {...documentsParametersFilter},
            pageRequestDto: {
                paginationPageSize: pageSize,
                paginationCurrent: 1,
            },
        }));

        setCurrentManually(1);

        return () => {
            loadThunk.abort();
        };
    }, [documentsParametersFilter, pageSize]);

    useAfterEffect(() => {
        if (!isNeedFetchTableData) {
            return () => {};
        }

        const loadThunk = dispatch(loadAisDocumentsInterfaceData({
            filter: {...documentsParametersFilter},
            pageRequestDto: {
                paginationPageSize: pageSize,
                paginationCurrent: current,
            },
        }));

        loadThunk.finally(() => {
            setIsNeedFetchTableData(false);
        });

        return () => {
            loadThunk.abort();
        };
    }, [documentsParametersFilter, pageSize, current, isNeedFetchTableData]);

    useAfterEffect(() => {
        if (documents.length && isAllSelected) {
            setSelectedRowKeys(documents.map(({id}) => id));
        } else {
            setSelectedRowKeys([]);
        }
    }, [documents, isAllSelected]);

    useEffect(() => () => { dispatch(aisSliceActions.updateAisSliceMeta({documentsTotalCount: undefined})); }, []);

    return <>{customTableJSX}</>;
});
