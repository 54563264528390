import {Card} from 'antd';
import {message} from 'antd/es';
import Form from 'antd/es/form';
import useForm from 'antd/es/form/hooks/useForm';
import cn from 'classnames';
import React, {
    ReactNode, useCallback, useEffect, useState,
} from 'react';

import {CustomTabs} from 'components/custom-tabs';
import {ButtonsBar} from 'pages/ais/organization-structure/buttons-bar';
import {
    CommonInfoFieldNames as CommonFieldNames,
    CompanyGroupFieldNames as CompanyFieldNames,
    OrganizationStructureTabKeys as FormKeys,
    OrganizationStructureTabKeys,
    OrganizationStructureTabTitles,
} from 'pages/ais/organization-structure/organization-structure-page.constants';
import {CompanyInfoPageValidationConfig} from 'pages/ais/organization-structure/organization-structure-page.types';
import {
    getValidationMessages,
    isFormValuesDirty,
} from 'pages/ais/organization-structure/organization-structure-page.utils';
import {CommonInfoTab} from 'pages/ais/organization-structure/tabs/common-info-tab';
import {CompanyGroupTab} from 'pages/ais/organization-structure/tabs/company-group-tab';
import {ForeignParentsTab} from 'pages/ais/organization-structure/tabs/foreign-parents-tab';
import {ParticipantsTab} from 'pages/ais/organization-structure/tabs/participants-tab';
import {AntdErrorFields} from 'shared/types/antd';
import {DeepPartial} from 'shared/types/generics';
import {getInvalidFieldsFromForm} from 'shared/utils/antd';
import {showMessage} from 'shared/utils/notifications';
import {showMessageFromResponse} from 'shared/utils/show-message-from-response';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {aisSliceActions} from 'store/slices/ais-slice/ais-slice';
import {
    selectAisOrganizationStructureData,
    getOrganizationStructureData,
    saveOrganizationStructureData,
    OrganizationStructureDto,
} from 'store/slices/ais-slice/organization-structure';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import './organization-structure-page.less';

export const OrganizationStructurePage = () => {
    const dispatch = useAppDispatch();
    const initialValues = useAppSelector(selectAisOrganizationStructureData);
    const isOrgStructureLoading = useAppSelector(s => selectIsThunkPending(s, getOrganizationStructureData.typePrefix));
    const isOrgStructureSaving = useAppSelector(s => selectIsThunkPending(s, saveOrganizationStructureData.typePrefix));
    const isLoading = isOrgStructureLoading || isOrgStructureSaving;

    const isEntityExists = !!initialValues?.id;

    const [form] = useForm();

    useEffect(() => {
        if (!initialValues) dispatch(getOrganizationStructureData());
        else form.resetFields();
    }, [initialValues]);

    const [selectedTabKey, setSelectedTabKey] = useState<React.Key>(OrganizationStructureTabKeys.commonInfo);

    const [isEditing, setEditing] = useState<boolean>(false);
    const [isDirty, setDirty] = useState<boolean>(false);
    const [validationFieldsConfig, setValidationFieldsConfig] = useState<
        CompanyInfoPageValidationConfig
    >({
        isAffiliationSet: false,
        isForeignParentSet: false,
    });
    const {isAffiliationSet, isForeignParentSet} = validationFieldsConfig;
    const isDisabled = !isEditing;

    const setValidationField = useCallback((field: keyof CompanyInfoPageValidationConfig, value: boolean) => {
        setValidationFieldsConfig(s => ({...s, [field]: value}));
    }, [setValidationFieldsConfig]);

    const handleSubmit = (values: OrganizationStructureDto) => {
        let submitData = {...values} as DeepPartial<OrganizationStructureDto>;

        if (isEntityExists) {
            submitData = {
                ...submitData,
                id: initialValues.id,
                companyGroup: {
                    ...submitData?.companyGroup,
                    id: initialValues?.companyGroup?.id,
                },
            };
        }

        if (!isAffiliationSet) {
            delete submitData[FormKeys.companyGroup];
            delete submitData[FormKeys.participants];
        } else if (!isForeignParentSet) {
            const companyGroup = submitData[FormKeys.companyGroup];
            if (companyGroup && companyGroup[FormKeys.foreignParentsInformation]) {
                delete companyGroup[FormKeys.foreignParentsInformation];
            }
        }

        dispatch(saveOrganizationStructureData({
            data: submitData,
        })).unwrap()
            .then(() => {
                setEditing(false);
                setDirty(false);
                dispatch(aisSliceActions.resetAisOrganizationStructure());
                showMessage({message: `Данные успешно ${isEntityExists ? 'обновлены' : 'сохранены'}`});
            }).catch(e => showMessageFromResponse({response: e, isError: true}));
    };

    const handleValidationFailed = (errorFields: AntdErrorFields) => {
        const invalidFields = getInvalidFieldsFromForm(errorFields) as string[];
        const messages = getValidationMessages({
            invalidFields,
            isForeignParentSet,
            isAffiliationSet,
        });
        messages.forEach(m => message.error(m));
    };

    const handleChange = (_: any, values: Partial<OrganizationStructureDto>) => {
        if (!initialValues) {
            setDirty(true);
            return;
        }

        // в values значения из Form.List приходят не совсем корректно, поэтому приходится
        // вытаскивать их через form.getFieldValue
        const isFormDirty = isFormValuesDirty({
            initialValues,
            currentValues: {
                ...values,
                companyGroup: {
                    ...values?.companyGroup,
                    foreignParentsInformation: (
                        form.getFieldValue([FormKeys.companyGroup, FormKeys.foreignParentsInformation])
                    ),
                },
                participants: form.getFieldValue([FormKeys.participants]),
            },
        });

        setDirty(isFormDirty);
    };

    const handleReset = useCallback(() => {
        form.resetFields();
        // при вызове resetFields с undefined значениями affiliation и hasForeignParent в initialValues
        // триггеры onChange и handleTriggerEvent внутри CustomSelect`ов их полей не вызываются,
        // а потому обновляем значения флагов отдельно
        setValidationField('isAffiliationSet', String(initialValues?.[CommonFieldNames.affiliation]) === '1');
        setValidationField(
            'isForeignParentSet',
            String(initialValues?.[FormKeys.companyGroup]?.[CompanyFieldNames.hasForeignParent]) === '1',
        );
        setDirty(false);
        setEditing(false);
    }, [initialValues]);

    const buttonsBar = useCallback((additional?: ReactNode) => (
        <ButtonsBar
            form={form}
            additional={additional}
            isDirty={isDirty}
            isEditing={isEditing}
            setEditing={setEditing}
            onReset={handleReset}
        />
    ), [form, isEditing, isDirty, handleReset]);

    return (
        <Card
            title="Сведения по структуре организации"
            className={cn('organization-structure', 'organization-structure-card')}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={initialValues}
                onValuesChange={handleChange}
                onFinish={handleSubmit}
                onFinishFailed={({errorFields}) => handleValidationFailed(errorFields)}
            >
                <CustomTabs
                    selectedTabKey={selectedTabKey}
                    contentSpinner={{
                        isSpinning: isLoading,
                        tip: `${isOrgStructureLoading ? 'Загрузка данных организации...' : 'Сохранение данных...'}`,
                    }}
                    setSelectedTabKey={setSelectedTabKey}
                    tabs={[{
                        key: OrganizationStructureTabKeys.commonInfo,
                        title: OrganizationStructureTabTitles.commonInfo,
                        content: (
                            <CommonInfoTab
                                isDisabled={isDisabled}
                                buttonsBar={buttonsBar}
                                isAffiliationSet={isAffiliationSet}
                                setValidationField={setValidationField}
                            />
                        ),
                    }, {
                        key: OrganizationStructureTabKeys.companyGroup,
                        title: OrganizationStructureTabTitles.companyGroup,
                        isDisabled: !isAffiliationSet,
                        content: (
                            <CompanyGroupTab
                                isDisabled={isDisabled}
                                buttonsBar={buttonsBar}
                                setValidationField={setValidationField}
                            />
                        ),
                    }, {
                        key: OrganizationStructureTabKeys.foreignParentsInformation,
                        title: OrganizationStructureTabTitles.foreignParentsInformation,
                        isDisabled: !isForeignParentSet,
                        content: (
                            <ForeignParentsTab
                                isDisabled={isDisabled}
                                buttonsBar={buttonsBar}
                            />
                        ),
                    }, {
                        key: OrganizationStructureTabKeys.participants,
                        title: OrganizationStructureTabTitles.participants,
                        isDisabled: !isAffiliationSet,
                        content: (
                            <ParticipantsTab
                                isDisabled={isDisabled}
                                buttonsBar={buttonsBar}
                            />
                        ),
                    },
                    ]}
                />
            </Form>
        </Card>
    );
};
