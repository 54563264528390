import {OracleBoolType} from 'shared/types';
import {LookupEntry} from 'shared/types/lookups';

import {Entity} from '../data';
import {DefaultTableStateFlat, LookupValue} from '../data/data-types';
import {
    DocumentsUploadMonitoring,
    extensionName,
} from './documents-upload-monitoring-types';

export enum DocumentsSigningMethod {
    'UPLOAD_EDS_FILE' = 'UPLOAD_EDS_FILE',
    'EXTERNAL_SERVICE' = 'EXTERNAL_SERVICE',
    'CRYPTO_PRO_PLUGIN' = 'CRYPTO_PRO_PLUGIN'
}

export interface DocumentsState {
    documentTypes?: DocumentTypeResponse[];
    documentTypesOnUpload?: DocumentTypeResponse[];
    branchNames?: DocumentTypeResponse[];
    documentList?: LookupValue[];
    fileUpload: FileUploadState;
    signatureUpload: SignatureUploadState;
    signatureExtensions?: SignExtensionsState;
    totalStage?: {
        fileList?: DocumentUploadFile[];
    };
    documentsUploadMonitoring?: DocumentsUploadMonitoringState;
    pagination?: DefaultTableStateFlat;
    signingMethod?: DocumentsSigningMethod;
    documentsForUploadAttach?: {[sublistCode: string]: LookupEntry[]};
    documentProperties?: DocumentProperty;
}

export interface DocumentsUploadMonitoringState {
    fileList?: DocumentsMonitoringItem[];
    headers?: string;
}

export interface DocumentProperty {
    [key: string]: boolean;
}

export interface DocumentsMonitoringItem {
    fileName: string;
    saveResult: DocumentsUploadMonitoring;
}

export interface DocumentUploadFile extends Entity {
    title: string;
    type?: string;
    sectionCode?: string;
    subsectionCode?: string;
    typeCode?: string;
    typeId?: string | number;
    sectionId?: string | number;
    subsectionId?: string | number;
    xmlValidateStatusCode?: string;
    fileName: string;
    fileSize: string;
    uploadDate: string | null;
    correctionNumber?: number;
    regNum?: number;
    branchId?: string;
    templateCode?: string;
    file: File;
    templatesTypeOptions?: DocumentTemplatesTypeResponse[];
    [index: symbol]: any;
}

export interface DocumentUploadScan extends DocumentUploadFile {
    documentFileId?: number;
    progress?: {
        percent: number;
        status?: FileUploadStatus;
    };
    isSubmitted?: boolean;
}

export interface DocumentSaveInfoFile {
    attachmentId: number;
    defaultAttachInfo: {
        fileName: string;
        branchName: null;
        branchId: null;
        outFileSize: string;
        docTypeCode: string;
        docTypeMean: string;
        docSectionCode: string;
        docSubsectionCode: string;
        extensions: extensionName;
        docSubsectionList: [
            {
                id: number;
                lookupType: string;
                lookupCode: string;
                meaning: string;
                description: null;
                parentLookupValueId: number;
                hibernateLazyInitializer: {};
            }
        ];
        validStatusCode: null;
        validStatusMean: null;
        useTimestampFlag: string;
        useCorrNumFlag: string;
        documentNamingRule: null;
    };
    stages: Stages[];
    autoStart: boolean;
}

export interface Stages {
    id: number;
    displaySequence: number;
    name: string;
    monitorProgramSetId: number;
    monitorProgramId: number;
    frontBackUrl: string;
}

export interface DocumentUploadSignature extends Entity {
    createdAt: Date;
    fileName: string;
    documentFileId?: string;
    documentName?: string;
    documentTypeName?: string;
    sectionCode?: string;
    subsectionCode?: string;
    file: File;
    attachId?: number;
    docTypeId?: number;
    corrNum?: string;
    branchId?: number;
}

type DocumentType = Omit<LookupEntry, 'id'> & {
    id: string;
}

export interface DocumentTemplatesTypeResponse extends DocumentType {}

export interface DocumentTypeResponse extends DocumentType {
    description: string;
}

export interface SignExtensionsState {
    accept: string;
}

export interface DocumentUploadedSignature extends Entity {
}

export interface DocumentFileUploadForm {
    uploadFiles: DocumentUploadFileRequest[];
}

export interface DocumentUploadFileRequest {
    documentName: string;
    docTypeCode?: string;
    sectionCode?: string | number;
    subSectionCode?: string | number;
    docTypeId?: string | number;
    sectionId?: string | number;
    subSectionId?: string | number;
    xmlValidateStatusCode?: string;
    branchId?: string;
    startDocDate?: string;
    corrNum?: number;
    file: File;

    [key: string]: string | Blob | undefined | number | null;
}

export interface DocumentUploadSignatureRequest {
    attachId?: string;
    file: File;

    [key: string]: string | Blob | undefined | number;
}

export interface DocumentUploadFileResponse {
    fileName: string;
    outFileSize: string;
    docTypeCode: string;
    docTypeMean: string;
    docSectionCode: string;
    docSubsectionCode: string;
    docTypeId?: string;
    sectionId?: string;
    subsectionId?: string;
}

export interface FileAttachInfoResponse {
    filename: string;
    defaultName: string;
    defaultSection: string;
    defaultSubSection: string;
    defaultBranchId: string;
    defaultDocTypeId: number;
}

export interface DocumentUploadSignatureResponse {
    attachmentId: string;
    docFileName: string;
    docId: string;
    docName: string;
    docSection: string;
    docType: string;
    signatureFileContent: string;
    signatureFileName: string;
    status: string;
}

export interface DocumentFileInfo {
    attachmentId: string;
    docFileName: string;
    docId: string;
    docName: string;
    docSection: string;
    docType: string;
    signatureFileContent: string;
    signatureFileName: string;
    status: string;
}

export interface FileUploadState {
    [key: string]: EntityFileUploadState;
}

export interface EntityFileUploadState {
    activityList: Record<string, FileUploadType>;
    fileList: DocumentUploadFile[];
}

export interface FileUploadType {
    uploadStatus?: FileUploadStatus;
    uploadPercent?: number;
    parsingStatus?: FileParsingStatus;
    parsingPercent?: number;
    validated?: boolean;
    validatedMeaning?: string;
    editFieldsPermission?: {
        [key: string]: {
            activated?: boolean;
            required?: boolean;
        };
    };
}

export interface SignUploadType {
    uploadStatus?: FileUploadStatus;
    uploadPercent?: number;
}

export interface SignatureUploadState {
    [key: string]: EntitySignatureUploadState;
}

export interface EntitySignatureUploadState {
    activityList: Record<string, SignatureUploadType>;
    signList: DocumentUploadSignature[];
}

export interface SignatureUploadType {
    uploadStatus?: FileUploadStatus;
    uploadPercent?: number;
    parsingStatus?: FileParsingStatus;
    parsingPercent?: number;
    validated?: boolean;
    validatedMeaning?: string;
    editFieldsPermission?: {
        [key: string]: OracleBoolType;
    };
}

export interface FileActivitySetPayload {
    entityName: string;
    uniqueValue: string | number;
    item: FileUploadType;
}

export interface FileActivityResetItemPayload {
    entityName: string;
    uniqueValue: string | number;
}

export interface FileActivityResetAllPayload {
    entityName: string;
}

export interface FileListSetPayload {
    entityName: string;
    fileList: DocumentUploadFile[];
}

export interface FileListResetPayload {
    entityName: string;
}

export interface FileListDtoPayload {
    entityName: string;
    fileList: DocumentUploadFile[];
}

export interface SignActivitySetPayload {
    entityName: string;
    uniqueValue: string | number;
    item: SignUploadType;
}

export interface SignActivityResetPayload {
    entityName: string;
}

export interface SignListSetPayload {
    entityName: string;
    signList: DocumentUploadSignature[];
}

export interface SignListResetPayload {
    entityName: string;
}

export interface SingSetExtensionsPayload {
    accept?: string;
}

export interface SetDocumentsForUploadAttachPayload {
    documents: LookupEntry[];
    sublistCode: string;
}

export interface SetDocumentScansListPayload {
    scans: DocumentUploadScan[];
    entityName: string;
}

export enum FileUploadStatus {
    NORMAL = 'normal',
    ACTIVE = 'active',
    EXCEPTION = 'exception',
    SUCCESS = 'success',
}

export enum FileParsingStatus {
    NORMAL = 'normal',
    ACTIVE = 'active',
    EXCEPTION = 'exception',
    SUCCESS = 'success',
}

export enum FileValidationStatus {
    VALID = 'valid',
    INVALID = 'invalid',
}

export type FileRowStateDataType = {
    /**
     * timestamp-enabled & corrnum-enabled
     */
    docTypeIdC?: string;
    /**
     * validate-status
     */
    docFile?: Blob;
    docTypeCode?: string;
};

export enum FileSizeFormat {
    B = 'B',
    KB = 'KB',
    MB = 'MB',
    GB = 'GB',
    FLEX = 'FLEX',
}
