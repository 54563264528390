import {RequestType} from 'modules/metadata';
import {
    FetchDataByIdArgsNumberType,
    RequestTableResponseWithPagination,
} from 'shared/types/requests';
import {performRequest, showMessage} from 'shared/utils';
import {
    DescriptiveFlexFieldData,
    DescriptiveFlexfieldSegmentMinimizedEntity,
} from 'store/slices/descriptive-flexfields-slice/descriptive-flexfields-slice-types';

import {
    AisArchiveSystemCode,
    AisDocRegisterRawField,
    AisInterfaceDocumentDto,
    AisRegisterDocumentByTaxDto,
    AisRegisterDocumentDto,
    AisRegisterTax,
    AisRequestDto,
    AisRequestToPublishDto,
    AisRequirementDto,
} from './ais-slice-types';

export type FetchAisDocumentsRegisterParams = {
    organizationId: number;
    dateFrom?: string;
    dateTo?: string;
    paginationCurrent?: number;
};

export type FetchAisDocumentsInterfaceDataParams = {
    filter: {
      counterpartyCountryCode?: string;
      counterpartyForeignRegNumber?: string;
      counterpartyInn?: string;
      counterpartyKpp?: string;
      counterpartyName?: string;
      dateFrom?: string;
      dateTo?: string;
      docJournalId?: number;
      documentBaseCode?: string;
      documentBaseDate?: string;
      documentBaseName?: string;
      documentBaseNumber?: string;
      documentCatCode?: string;
      documentCode?: string;
      documentDate?: string;
      documentName?: string;
      documentNumber?: string;
      documentSumGross?: number;
      documentTaxSum?: number;
      id?: number;
      loadId?: number;
      statusCode?: string;
    };
    pageRequestDto: {
      length?: number;
      paginationCurrent?: number;
      paginationPageSize?: number;
      search?: string;
      sorterField?: string;
      sorterOrder?: string;
      start?: string;
    };
  };

// ===== AIS-3 Requests =====

export type FetchAisRequestsParams = {
    aisDocId?: AisRegisterDocumentDto['aisDocId'];
};

export const fetchAisRequests = (args?: FetchAisRequestsParams) => (
    performRequest<AisRequestDto[]>({
        url: `integration.module/register.documents/requests/${args?.aisDocId}`,
        method: RequestType.GET,
    })
);

// ===== Additional Placement Requests =====

export type FetchAisRequestsToPublishParams = {
    id?: AisRegisterDocumentDto['id'];
};

export const fetchAisRequestsToPublish = (args?: FetchAisRequestsToPublishParams) => (
    performRequest<AisRequestToPublishDto[]>({
        url: `integration.module/register.documents/requestsToPublish/${args?.id}`,
        method: RequestType.GET,
    })
);

// ===== Requirements =====

export type FetchAisRequirementsParams = {
    aisDocId?: AisRegisterDocumentDto['aisDocId'];
};

export const fetchAisRequirements = (args?: FetchAisRequirementsParams) => (
    performRequest<AisRequirementDto[]>({
        url: `integration.module/register.documents/requirement/${args?.aisDocId}`,
        method: RequestType.GET,
    })
);

// ===== Documents =====

export type DeletePUDDocumentsArgs = {ids: string[]}
export const deletePUDDocuments = (args: DeletePUDDocumentsArgs) => performRequest<
string>({
    url: 'integration.module/register.documents/file/del',
    method: RequestType.POST,
    data: args.ids,
});

export interface PostPUDFileToDocumentArgs {
    requestData: {
        aisDocId: string;
        archiveSystem: AisArchiveSystemCode;
        file: File;
    };
    handleProgress?: (event: ProgressEvent) => void;
}

export const postPUDFileToDocument = (
    {
        requestData,
        handleProgress,
    }: PostPUDFileToDocumentArgs,
) => {
    const {
        aisDocId,
        archiveSystem,
        file,
    } = requestData;

    const requestFormData = new FormData();

    if (aisDocId) requestFormData.append('aisDocId', aisDocId);
    if (archiveSystem) requestFormData.append('archiveSystem', archiveSystem);
    if (file) requestFormData.append('file', file);

    return performRequest({
        url: `integration.module/register.documents/file/${aisDocId}`,
        method: 'POST',
        headers: {'Content-Type': 'multipart/form-data'},
        data: requestFormData,
        onUploadProgress: progressEvent => {
            handleProgress?.(progressEvent);
        },
    });
};

export const fetchAisDocumentsRegister = (args?: FetchAisDocumentsRegisterParams) => (
    performRequest<RequestTableResponseWithPagination<AisRegisterDocumentDto>>({
        url: 'integration.module/register.documents/documents',
        method: RequestType.GET,
        params: args,
    })
);

export const transferAisDocumentsRegister = async () => {
    try {
        const response = await performRequest({
            url: 'integration.module/register.documents/transfer',
            method: RequestType.GET,
        });
        if (response.status === 200) {
            showMessage({
                message: 'Документы были успешно перенесены в Реестр документов',
            });
        }
    } catch (e) {
        showMessage({
            message: e.message,
            isError: true,
        });
    }
};

export type PutAisDocumentInRegisterArgs = Partial<AisRegisterDocumentDto> & {
    id: AisRegisterDocumentDto['id'];
}

export const putAisDocumentInRegister = (args: PutAisDocumentInRegisterArgs) => {
    const {id, ...restArgs} = args;

    const requestFormData = new FormData();
    Object.keys(restArgs).forEach(k => {
        const v = (restArgs as {[index: string]: any})[k];
        if (v) requestFormData.set(k, v);
    });

    return performRequest<AisRegisterDocumentDto>({
        url: `integration.module/register.documents/documents/${id}`,
        method: RequestType.PUT,
        headers: {'Content-Type': 'multipart/form-data'},
        data: requestFormData,
    });
};

export type PostAisDocumentInRegisterArgs = Omit<AisRegisterDocumentDto, 'id'> & {}
export const postAisDocumentInRegister = (args: PostAisDocumentInRegisterArgs) => {
    const requestFormData = new FormData();
    Object.keys(args).forEach(k => {
        const v = (args as {[index: string]: any})[k];
        if (v) requestFormData.set(k, v);
    });

    return performRequest<AisRegisterDocumentDto>({
        url: 'integration.module/register.documents/documents/',
        method: RequestType.POST,
        headers: {'Content-Type': 'multipart/form-data'},
        data: requestFormData,
    });
};

export type DeleteAisDocumentsInRegisterArgs = {ids: number[]}
export const deleteAisDocumentsInRegister = (args: DeleteAisDocumentsInRegisterArgs) => performRequest<
string>({
    url: 'integration.module/register.documents/documents/del',
    method: RequestType.POST,
    data: args,
});

export type PostUpdateAisDocumentsActiveFlag = {ids: number[]}
export const postUpdateAisDocumentsActiveFlag = (args: PostUpdateAisDocumentsActiveFlag) => performRequest<
string>({
    url: 'integration.module/register.documents/update-active-flag',
    method: RequestType.POST,
    data: args,
});

type AisDocsSettingsAttributeKey = (
    'use_parameters'
    | 'quantity_lines'
    | 'content_availability_condition'
    | 'ais_doc_id_autogeneration'
);

interface AisDocsSettingAttribute {
    id: string;
    attribute: AisDocsSettingsAttributeKey;
    value: string;
}

export type AisDocumentsRegisterSettings = AisDocsSettingAttribute[];
export const fetchAisDocumentsRegisterSettings = () => (
    performRequest<AisDocumentsRegisterSettings>({
        url: 'integration.module/register.documents/param-settings',
        method: RequestType.GET,
    })
);

export type AisDocRegisterFieldsRaw = AisDocRegisterRawField[];
export const fetchAisDocumentsRegisterFields = () => (
    performRequest<AisDocRegisterFieldsRaw>({
        url: 'integration.module/register.documents/form-settings',
        method: RequestType.GET,
    })
);

// ===== Documents By Tax =====

export type FetchAisDocumentsByTaxRegisterParams = {
    paginationCurrent?: number;
};

export const fetchAisDocumentsByTaxRegister = (args?: FetchAisDocumentsByTaxRegisterParams) => (
    performRequest<RequestTableResponseWithPagination<AisRegisterDocumentByTaxDto>>({
        url: 'integration.module/register.documents/documents-by-tax',
        method: RequestType.GET,
        params: args,
    })
);

// =====  Included Documents By Tax =====

export type FetchAisIncludedDocumentsByTaxArgs = {
    id: number;
}
export const fetchAisIncludedDocumentsByTax = (
    args: FetchAisIncludedDocumentsByTaxArgs,
) => performRequest<AisRegisterDocumentByTaxDto[]>(
    {
        url: `integration.module/register.documents/documents-by-tax/tbd/${args.id}`,
        method: RequestType.GET,
    },
);

export type PostAisDocumentByTaxArgs = Partial<Omit<AisRegisterTax, 'id'>> & {
    id: AisRegisterDocumentDto['id']; // base document id
} & Required<Pick<AisRegisterTax, 'kndCode' | 'reportingYear' | 'taxCode' | 'corrNum' | 'taxPeriod'>>

export const postAisDocumentByTax = (args: PostAisDocumentByTaxArgs) => {
    const requestFormData = new FormData();
    Object.keys(args).forEach(k => {
        const v = (args as {[index: string]: any})[k];
        if (v) requestFormData.set(k, v);
    });

    return performRequest<AisRegisterDocumentByTaxDto>({
        url: 'integration.module/register.documents/documents-by-tax/',
        method: RequestType.POST,
        headers: {'Content-Type': 'multipart/form-data'},
        data: requestFormData,
    });
};

export type PutAisDocumentByTaxArgs = Partial<Omit<AisRegisterTax, 'id' | 'mappingId'>> & {
    id: AisRegisterDocumentDto['id']; // base
} & Required<Pick<AisRegisterTax, 'kndCode' | 'reportingYear' | 'taxCode' | 'corrNum' | 'taxPeriod' | 'mappingId'>>

export const putAisDocumentByTax = (args: PutAisDocumentByTaxArgs) => {
    const {mappingId, ...restArgs} = args;

    const requestFormData = new FormData();
    Object.keys(restArgs).forEach(k => {
        const v = (restArgs as {[index: string]: any})[k];
        if (v) requestFormData.set(k, v);
    });

    return performRequest<AisRegisterDocumentByTaxDto>({
        url: `integration.module/register.documents/documents-by-tax/${mappingId}`,
        method: RequestType.PUT,
        headers: {'Content-Type': 'multipart/form-data'},
        data: requestFormData,
    });
};

export type DeleteAisDocumentsByTaxArgs = {ids: number[]}
export const deleteAisDocumentsByTax = (args: DeleteAisDocumentsInRegisterArgs) => performRequest<
string>({
    url: 'integration.module/register.documents/documents-by-tax/del',
    method: RequestType.POST,
    data: args,
});

// ===== Documents Register AdditionalFields =====

export const fetchAisDocAdditionalFieldsByDocId = (
    args: FetchDataByIdArgsNumberType,
) => performRequest<DescriptiveFlexFieldData>(
    {
        url: `integration.module/register.documents/get-flex-field/${args.id}`,
        method: RequestType.GET,
    },
);

export type PostUpdateAisDocumentsAdditionalFields = {
    id?: number;
    values: DescriptiveFlexfieldSegmentMinimizedEntity;
}
export const postUpdateAisDocumentsAdditionalFields = (args: PostUpdateAisDocumentsAdditionalFields) => performRequest<
string>({
    url: `integration.module/register.documents/update-flex-field/${args.id}`,
    method: RequestType.POST,
    data: args?.values,
});

// =====  Response requirement =====
export type FormTransportContainerProtocolRequestArgs = {
    containerId: number;
    signatoryId: number;
}

export const formTransportContainerProtocolRequest = (
    args: FormTransportContainerProtocolRequestArgs,
) => performRequest<string>(
    {
        url: 'integration.module/response.requirement/generateProtocolErrorMessage',
        method: RequestType.POST,
        data: args,
    },
);

// ===== Documents Interface =====

export type AisDocInterfaceFieldsRaw = AisDocRegisterRawField[];
export const fetchAisDocumentsInterfaceFields = () => (
    performRequest<AisDocInterfaceFieldsRaw>({
        url: 'integration.module/doc-journal-interface/get-form-settings',
        method: RequestType.GET,
    })
);

export const fetchAisDocumentsInterfaceData = (args?: FetchAisDocumentsInterfaceDataParams) => (
    performRequest<RequestTableResponseWithPagination<AisInterfaceDocumentDto>>({
        url: 'integration.module/doc-journal-interface/data',
        method: RequestType.POST,
        data: args,
    })
);

export type DeleteAisDocumentsInInterfaceArgs = {ids: number[]}
export const deleteAisDocumentsInInterface = (args: DeleteAisDocumentsInInterfaceArgs) => performRequest<
string>({
    url: 'integration.module/doc-journal-interface/update-status/delete',
    method: RequestType.POST,
    data: args,
});

export type DeleteAisDocumentsInInterfaceByFilterArgs = Partial<AisInterfaceDocumentDto>
export const deleteAisDocumentsInInterfaceByFilter = (args: DeleteAisDocumentsInInterfaceByFilterArgs) => (
    performRequest<string>({
        url: 'integration.module/doc-journal-interface/update-status/by-filter/delete',
        method: RequestType.POST,
        data: args,
    }));

export type UpdateStatusAisDocumentsInInterfaceArgs = {ids: number[]}
export const updateStatusAisDocumentsInInterface = (args: UpdateStatusAisDocumentsInInterfaceArgs) => (
    performRequest<string>({
        url: 'integration.module/doc-journal-interface/update-status/change',
        method: RequestType.POST,
        data: args,
    }));

export type UpdateStatusAisDocumentsInInterfaceByFilterArgs = Partial<AisInterfaceDocumentDto>
export const updateStatusAisDocumentsInInterfaceByFilter = (args: UpdateStatusAisDocumentsInInterfaceByFilterArgs) => (
    performRequest<string>({
        url: 'integration.module/doc-journal-interface/update-status/by-filter/change',
        method: RequestType.POST,
        data: args,
    }));

export type PublishAisInterfaceDocumentsArgs = {orgId: number}
export const publishAisInterfaceDocuments = ({orgId}: PublishAisInterfaceDocumentsArgs) => performRequest<string>({
    url: `integration.module/doc-journal-interface/publish-all?orgId=${orgId}`,
    method: RequestType.POST,
});

export type UpdateAisDocumentInterfaceCardArgs = Partial<AisInterfaceDocumentDto>;
export const updateAisDocumentInterfaceCard = (args: UpdateAisDocumentInterfaceCardArgs) => performRequest<
    AisInterfaceDocumentDto>({
        url: 'integration.module/doc-journal-interface/edit',
        method: RequestType.POST,
        data: args,
    });
