import {Button} from 'antd';
import cn from 'classnames';
import React from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {ReactComponent as ErrorAlert} from 'shared/assets/error-alert.svg';
import {DEFAULT_THEME} from 'shared/constants/layout';
import {URL_FORBIDDEN, URL_LOGIN} from 'shared/constants/urls';
import {logout} from 'store/slices/auth-slice';

import './forbidden-form.less';

export const ForbiddenForm:React.FC = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isForbiddenPage = history.location.pathname === URL_FORBIDDEN;

    return (
        <div className="forbidden-form">
            {isForbiddenPage && (
                <div className="forbidden-form__header">
                    <img
                        className={cn({
                            'forbidden-form__partner-logo': (
                                process.env.REACT_APP_THEME !== DEFAULT_THEME
                            ),
                        })}
                        src={`${process.env.PUBLIC_URL}/logos/${process.env.REACT_APP_THEME}-light.svg`}
                        alt="Витрина налогового мониторинга."
                    />
                    <h1>
                        Витрина налогового мониторинга
                    </h1>
                </div>
            )}
            <div className="forbidden-form__verbose-error">
                {isForbiddenPage && (
                    <ErrorAlert className="forbidden-form__verbose-error__logo" />
                )}
                <div className="forbidden-form__verbose-error__message">
                    Недостаточно прав для просмотра страницы
                </div>
            </div>
            <div
                className="forbidden-form__button-container"
            >
                {isForbiddenPage ? (
                    <>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => history.go(-1)}
                        >
                            Назад
                        </Button>
                        <Button
                            type="primary"
                            size="large"
                            block
                            onClick={() => {
                                dispatch(logout());
                                history.push(URL_LOGIN);
                            }}
                        >
                            Выйти
                        </Button>
                    </>
                ) : (
                    <>
                        <Button
                            type="primary"
                            onClick={() => history.go()}
                        >
                            Попробовать ещё раз
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => history.go(-1)}
                        >
                            Вернуться назад
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
