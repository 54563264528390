import {LoadingOutlined} from '@ant-design/icons';
import {Button} from 'antd';
import {AxiosError} from 'axios';
import cn from 'classnames';
import {isNil} from 'lodash';
import React, {
    ReactElement, useContext, useRef, useState,
} from 'react';

import {BreadcrumbsContext} from 'components/breadcrumbs';
import {DynamicIcon} from 'components/dynamic-icon';
import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {RequestType} from 'modules/metadata';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {HistoryLocationState} from 'shared/hooks/use-app-history/use-app-history-types';
import {useFileViewer} from 'shared/hooks/use-file-viewer';
import {ExternalLinkProperties, LinkDto, LinkType} from 'shared/types/links';
import {performRequest, showMessageFromResponse} from 'shared/utils';

import {CustomLinkModal} from './custom-link-modal';

import './custom-link.less';

export interface CustomLinkProps {
    link?: LinkDto;
    component?: ReactElement;
    extraStateData?: Partial<HistoryLocationState>;
    externalProperties?: ExternalLinkProperties;
}

interface CustomLinkViewProps {
    properties: LinkDto['linkProperties'];
    isLoading?: boolean;
}

const CustomLinkView = ({
    properties,
    isLoading,
}: CustomLinkViewProps) => {
    const {
        icon,
        title = 'Ссылка',
        type,
    } = properties ?? {};

    const iconToShow = isLoading ? (
        <div className={cn('mr-1')}>
            <LoadingOutlined />
        </div>
    ) : icon && (
        <DynamicIcon
            className={cn({
                'mr-1': type === 'link',
            })}
            type={icon}
        />
    );

    if (type === 'button') {
        return (
            <Button
                disabled={isLoading}
                type="default"
                className={cn('d-flex align-items-center', 'custom-link__button')}
            >
                {iconToShow}
                {title}
            </Button>
        );
    }

    return (
        <div className={cn(
            'd-flex align-items-center',
            type === 'submenu' ? 'custom-link__default' : 'custom-link__link',
            {'custom-link__link_loading': isLoading},
        )}
        >
            {iconToShow}
            {title ?? (!iconToShow && 'Ссылка')}
        </div>
    );
};

export const CustomLink: React.FC<CustomLinkProps> = ({
    link,
    component,
    extraStateData,
    externalProperties,
}: CustomLinkProps) => {
    const [isLoading, setLoading] = useState(false);
    const {pushWithStateUpdate} = useAppHistory();

    const {
        url = '/',
        urlType,
        filterParameters,
        queryParameters,
        bodyParameters,
        ignoreRequiredParameters,
        linkProperties,
        token,
        suppressMessages,
        method,
        bodyType,
    } = link ?? {};

    const queryParamsString = queryParameters ? `?${new URLSearchParams(queryParameters).toString()}` : '';
    const urlWithParams = `${url}${queryParamsString}`;

    const {loadFile, checkIsLoading} = useFileViewer({
        url: isNil(urlWithParams) ? '/' : urlWithParams,
        urlType,
    });

    const {breadcrumbs} = useContext(BreadcrumbsContext);

    const contentModalRef = useRef<ModalOpenerComponentRef>(null);

    const linkMeta = {
        ignoreRequiredParameters,
    };

    const handleLinkClick = () => {
        if (checkIsLoading()) return;

        if (urlType === LinkType.MODAL || urlType === LinkType.MODAL_WITH_TOKEN) {
            contentModalRef.current?.showModal();
            return;
        }

        if (urlType === LinkType.FILE
            || urlType === LinkType.FILE_WITH_TOKEN
            || urlType === LinkType.PDF_WITH_ERROR_FALLBACK
        ) {
            // token приходит сразу готовым, поэтому оба сценария одинаковые.
            const headers = [] as [string, string][];
            if (token) {
                headers.push([
                    'Authorization', token,
                ]);
            }
            loadFile({
                headers,
            });
            return;
        }
        if (urlType === LinkType.INTERNAL) {
            if (url) {
                pushWithStateUpdate(urlWithParams, {
                    ...(filterParameters ? {
                        [LocationStateKey.LINK_FILTER_PARAMETERS]: filterParameters,
                    } : {}),
                    ...(link?.linkProperties?.passBreadcrumbs ? {
                        [LocationStateKey.PASSED_BREADCRUMBS]: breadcrumbs,
                    } : {}),
                    [LocationStateKey.LINK_META]: linkMeta,
                    ...(extraStateData ?? {}),
                });
            }
        }
        if (url && LinkType.DATA_WITH_TOKEN) {
            const promise = performRequest({
                method: method ?? RequestType.GET,
                url: urlWithParams,
                headers: {
                    Authorization: token,
                    'Content-Type': bodyType ?? 'application/json',
                },
                data: bodyParameters,
            });

            setLoading(true);

            if (!suppressMessages) {
                promise.then(res => {
                    showMessageFromResponse({response: res});
                }).catch((err: AxiosError) => {
                    showMessageFromResponse({response: err.response, isError: true});
                });
            }
            promise.finally(() => setLoading(false));
        }
    };

    const properties: LinkDto['linkProperties'] = {
        ...linkProperties,
        title: externalProperties?.title ?? linkProperties?.title,
        icon: externalProperties?.icon ?? linkProperties?.icon,
    };

    if (!link) return null;
    return (
        <>
            <div
                onClick={handleLinkClick}
                className={cn('d-inline-flex', 'align-items-center')}
            >
                <a
                    href={urlWithParams}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => {
                        if (urlType !== LinkType.EXTERNAL) e.preventDefault();
                    }}
                >
                    {component ?? (
                        <CustomLinkView
                            properties={properties}
                            isLoading={checkIsLoading() || isLoading}
                        />
                    )}

                </a>
            </div>
            <CustomLinkModal
                link={link}
                ref={contentModalRef}
            />
        </>
    );
};
