import {Button, Input} from 'antd';
import {Row} from 'antd/es';
import FormItem from 'antd/es/form/FormItem';
import Col from 'antd/es/grid/col';
import React, {useState} from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {CustomSelect, CustomSelectMode} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {
    ParticipantsFieldNames as FieldNames,
} from 'pages/ais/organization-structure/organization-structure-page.constants';
import {OrgStructureTabProps} from 'pages/ais/organization-structure/organization-structure-page.types';
import {AntdFormListFieldProps} from 'shared/types/antd';

import './participants-fields.less';

interface ParticipantsFieldsProps extends Required<AntdFormListFieldProps>, Pick<OrgStructureTabProps, 'isDisabled'> {}

export const ParticipantsFields = ({field, onRemove, isDisabled}: ParticipantsFieldsProps) => {
    const [participantTypeValue, setParticipantTypeValue] = useState<string | null>(null);

    return (
        <div className="participants-fields">
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.subordination]}
                        label="Тип подчиненности участника к организации, представляющей сведения"
                        rules={[{required: true}]}
                    >
                        <CustomSelect
                            settings={{
                                url: 'valueLists/FLEX_VALUE?sublistCode=subordination',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите значение',
                                isClearable: true,
                                showSearch: true,
                                isDisabled,
                            }}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.interdependence]}
                        label="Признак взаимозависимости с организацией, представляющей сведения,
                         в соответствии с п.2 ст.105.1 НК РФ"
                        rules={[{required: true}]}
                    >
                        <CustomSelect
                            settings={{
                                mode: CustomSelectMode.multiple,
                                url: 'valueLists/FLEX_VALUE?sublistCode=interdependence',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите значение',
                                isClearable: true,
                                showSearch: true,
                                isDisabled,
                            }}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.participantType]}
                        label="Признак участника группы"
                        rules={[{required: true}]}
                    >
                        <CustomSelect
                            onChange={setParticipantTypeValue}
                            handleTriggerEvent={(value, event) => {
                                if (event === CustomSelectValueTriggerEvent.receivedDataFromFetcher) {
                                    setParticipantTypeValue(value?.lookupCode);
                                }
                            }}
                            settings={{
                                url: 'valueLists/FLEX_VALUE?sublistCode=participant_type',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите значение',
                                isClearable: true,
                                showSearch: true,
                                isDisabled,
                            }}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.participantName]}
                        label="Наименование организации участника группы компаний: материнской или дочерней (зависимой)
                         организации для организации, представляющей сведения (в русской транскрипции)"
                        rules={[
                            {required: true},
                            {max: 250, message: 'Длина должна быть не более 250 символов'},
                        ]}
                    >
                        <Input
                            placeholder="Введите наименование"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.participantNameLat]}
                        label="Наименование организации участника группы компаний: материнской или дочерней (зависимой)
                         организации для организации, представляющей сведения (в латинской транскрипции)"
                        rules={[
                            {required: participantTypeValue === '2'},
                            {max: 250, message: 'Длина должна быть не более 250 символов'},
                        ]}
                    >
                        <Input
                            placeholder="Введите наименование"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.participantInn]}
                        label="ИНН материнской или дочерней (зависимой) организации"
                        rules={[
                            {required: participantTypeValue === '1'},
                            {max: 10, message: 'Длина должна быть не более 10 символов'},
                        ]}
                    >
                        <Input
                            placeholder="Введите ИНН"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.foreignRegNumber]}
                        label="Регистрационный номер в стране регистрации (инкорпорации)"
                        rules={[
                            {required: participantTypeValue === '2'},
                            {max: 100, message: 'Длина должна быть не более 100 символов'},
                        ]}
                    >
                        <Input
                            placeholder="Введите номер"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.countryCode]}
                        label="Код страны регистрации (инкорпорации)"
                        rules={[
                            {required: participantTypeValue === '2'},
                            {max: 3, message: 'Длина должна быть не более 3 символов'},
                        ]}
                    >
                        <Input
                            placeholder="Введите код"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.shareParent]}
                        label="Доля прямого участия в капитале"
                    >
                        <Input
                            type="number"
                            suffix="%"
                            placeholder="Введите значение (в процентах)"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        name={[field.name, FieldNames.participantGroupCode]}
                        label="Код группы компаний (холдинга), к которой относится участник/дочерняя
                         (зависимая) организация"
                        rules={[
                            {required: true},
                            () => ({
                                validator: (_, value) => {
                                    if (String(value)?.length > 3) {
                                        return Promise.reject(new Error(
                                            'Длина должна быть не более 3 символов',
                                        ));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <Input
                            placeholder="Введите код"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                justify="end"
            >
                <Button
                    className="button-critic"
                    onClick={() => onRemove(field.name)}
                    disabled={isDisabled}
                >
                    <DynamicIcon type="TrashXOutlined" />
                </Button>
            </Row>
        </div>
    );
};
