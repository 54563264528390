import {RequestType} from 'modules/metadata';
import {DeepPartial} from 'shared/types/generics';
import {performRequest} from 'shared/utils';
import {ORG_STRUCTURE_BASE_URL} from 'store/slices/ais-slice/organization-structure/organization-structure-constants';
import {OrganizationStructureDto} from 'store/slices/ais-slice/organization-structure/organization-structure-types';

export const fetchOrganizationStructureData = () => performRequest<
    OrganizationStructureDto>({
        url: [
            ORG_STRUCTURE_BASE_URL,
            'getInfo',
        ].join('/'),
        method: RequestType.GET,
    });

export interface SaveOrganizationStructureDataRequestArgs {
    data: DeepPartial<OrganizationStructureDto>;
}

export const saveOrganizationStructureDataRequest = ({
    data,
}: SaveOrganizationStructureDataRequestArgs) => performRequest<string>({
    url: [
        ORG_STRUCTURE_BASE_URL,
        'updateAll',
    ].join('/'),
    method: RequestType.POST,
    data,
});
