import {Spin} from 'antd';
import cn from 'classnames';
import React from 'react';
import {
    DragDropContext,
    Droppable,
} from 'react-beautiful-dnd';

import {Dictionary} from 'components/personal-account/tabs/dashboards/dashboards-types';
import {selectContextRawData} from 'modules/context/context-selector';
import {useDraggableComponents} from 'shared/hooks/use-draggable-components.hook';
import {DashboardAPI} from 'store/api/settings-dashboards/settings-dashboards.api';
import {useAppSelector} from 'store/config/hooks';

import {
    checkIfAllColumnsHaveOneWidget,
    getNumberOfMaxWidgetsPerColumn,
    getNumberOfWidgetColumns, WIDGET_HALF_PADDING,
    widgetDraggableComponentsInitialState,
    widgetPageComponentMap,
} from './constants/widget-page.constants';
import {EmptyWidgetsGreetings} from './empty-widgets-greetings/empty-widgets-greetings';
import {WidgetDraggableComponent} from './widget-draggable-component/widget-draggable-component';
import './widget-page.less';

export const WIDGET_PAGE_ENTITY_NAME = 'widgets';

const dashboardDictionary: Dictionary<string> = {
    'dashboard.item.request': 'REQUEST_TABLE',
    'dashboard.item.publication.doc': 'DOC_JOURNAL_PIE_CHART',
    'dashboard.item.components.nob': 'NOB_COMPONENTS_BAR_CHART',
    'dashboard.item.news': 'NEWS',
};

export const WidgetPage = () => {
    const contextRawData = useAppSelector(selectContextRawData);
    const {data: dashboards, isLoading: isLoadingDashboards} = DashboardAPI.useFetchAllDashboardsQuery(null);

    const {
        components: widgets,
        handleDragStart,
        handleDragEnd,
    } = useDraggableComponents(widgetDraggableComponentsInitialState, WIDGET_PAGE_ENTITY_NAME);

    const columnCount = getNumberOfWidgetColumns(widgets);
    const maxWidgetsPerColumn = getNumberOfMaxWidgetsPerColumn(widgets);
    const allColumnsHaveOneWidget = checkIfAllColumnsHaveOneWidget(widgets);
    const activeDashboards = dashboards?.map(item => (
        item.isActive ? dashboardDictionary[item.menuItem.itemName]
            : ''));
    const hasActiveDashboards = !activeDashboards
        || activeDashboards?.filter(item => item !== '').length !== 0;
    return (
        <Spin
            spinning={isLoadingDashboards}
            tip="Загрузка..."
        >
            {(() => {
                if (!hasActiveDashboards) {
                    return <EmptyWidgetsGreetings />;
                }
                return (
                    <div className="widget-page">
                        <DragDropContext
                            onDragStart={handleDragStart}
                            onDragEnd={handleDragEnd}
                        >
                            {widgets.map((colWidgets, droppableId) => (
                                <Droppable
                                    droppableId={droppableId.toString()}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={cn('widget-block', snapshot.isDraggingOver && 'dragging')}
                                            style={{
                                                width: `calc(${100 / columnCount}% -
                                    ${columnCount * WIDGET_HALF_PADDING}px)`,
                                            }}
                                        >
                                            {colWidgets.map(({
                                                id,
                                                component,
                                                entityName,
                                            }, idx) => (
                                                activeDashboards?.includes(component) ? (
                                                    <WidgetDraggableComponent
                                                        component={widgetPageComponentMap[component]}
                                                        draggableId={id}
                                                        index={idx}
                                                        componentProps={{contextRawData, entityName}}
                                                        autoAdjustHeight={
                                                            !snapshot.isDraggingOver
                                                            && (colWidgets.length < maxWidgetsPerColumn
                                                                || allColumnsHaveOneWidget)
                                                        }
                                                    />
                                                ) : (<></>)
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            ))}
                        </DragDropContext>
                    </div>
                );
            })()}
        </Spin>
    );
};
