import {Form, Input} from 'antd';
import {FormInstance} from 'antd/lib/form';
import React, {ChangeEvent, useEffect, useState} from 'react';

import {FormMode} from 'components/form';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DATE, DATE_DMY} from 'shared/constants/date-format';
import {AisInterfaceDocumentDto} from 'store/slices/ais-slice/ais-slice-types';
import './main-info.less';

export interface MainInfoProps {
    form: FormInstance;
    interfaceDocument?: AisInterfaceDocumentDto;
    formMode: FormMode;
    isDisabled?: boolean;
}

export const MainInfo: React.FC<MainInfoProps> = React.forwardRef<FormInstance, MainInfoProps>(({
    form,
    interfaceDocument,
    formMode,
    isDisabled,
}: MainInfoProps, ref) => {
    const [initialFormValues, setInitialFormValues] = useState(interfaceDocument);
    const [isINNFilled, setIsINNFilled] = useState(() => (
        Boolean(interfaceDocument?.counterpartyInn)
    ));
    const [isCounterpartyRegNumFilled, setIsCounterpartyRegNumFilled] = useState(() => (
        Boolean(interfaceDocument?.counterpartyForeignRegNumber)
    ));

    useEffect(() => {
        setInitialFormValues(interfaceDocument);
    }, [interfaceDocument]);

    useEffect(() => {
        form.resetFields();
    }, [initialFormValues]);

    const handleINNChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsINNFilled(Boolean(e.target?.value));
        form.setFields(['counterpartyForeignRegNumber', 'counterpartyCountryCode'].map(name => ({name, errors: []})));
    };

    const handleCounterpartyRegNumChange = (e: ChangeEvent<HTMLInputElement>) => {
        setIsCounterpartyRegNumFilled(Boolean(e.target?.value));
        form.setFields(['counterpartyInn', 'counterpartyCountryCode'].map(name => ({name, errors: []})));
    };

    return (
        <Form<AisInterfaceDocumentDto>
            form={form}
            ref={ref}
            initialValues={initialFormValues}
            layout="vertical"
            className="interfaceDocumentMainInfo"
        >
            <div
                className="form-flex scroll"
                style={{
                    maxHeight: `calc(min(${
                        formMode === 'create' ? '600' : '599'
                    }px, 64vh))`,
                    overflow: 'auto',
                    padding: '8px 12px 8px 0',
                }}
            >
                <Form.Item
                    name="id"
                    hidden
                />
                <div className="form-flex-row">
                    <Form.Item
                        label="Код вида документа"
                        name="documentCode"
                        required
                        rules={[{required: true}]}
                    >
                        <CustomSelect
                            settings={{
                                url: '/ais/valueLists/SPV_DOCS',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код вида документа',
                                isDisabled,
                                showSearch: true,
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Код категории документа"
                        name="documentCatCode"
                    >
                        <CustomSelect
                            settings={{
                                url: '/ais/valueLists/SPVDOC_TYPE_CAT_CODES',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код категории документа',
                                isDisabled,
                                showSearch: true,
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Наименование документа"
                        name="documentName"
                        required
                        rules={[{required: true}]}
                    >
                        <Input
                            placeholder="Наименование документа"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Номер документа"
                        name="documentNumber"
                    >
                        <Input
                            placeholder="Номер документа"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        name="documentDate"
                        label="Дата документа"
                        style={{maxWidth: 250}}
                        required
                        rules={[{required: true}]}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Сумма по документу всего, руб"
                        name="documentSumGross"
                        rules={[{
                            pattern: /^(\d+|\d+\.\d+)$/,
                            message: 'Данное поле должно содержать только цифры и одну точку',
                        }]}
                    >
                        <Input
                            placeholder="Укажите сумму по документу всего, руб"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Сумма НДС по документу всего, руб"
                        name="documentTaxSum"
                        rules={[{
                            pattern: /^(\d+|\d+\.\d+)$/,
                            message: 'Данное поле должно содержать только цифры и одну точку',
                        }]}
                    >
                        <Input
                            placeholder="Укажите сумму НДС по документу всего, руб"
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="ИНН контрагента"
                        name="counterpartyInn"
                        rules={[
                            {
                                required: !isCounterpartyRegNumFilled,
                                message: 'Пожалуйста, введите ИНН контрагента',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Введите ИНН контрагента"
                            onChange={handleINNChange}
                        />
                    </Form.Item>
                    <Form.Item
                        label="КПП контрагента"
                        name="counterpartyKpp"
                    >
                        <Input
                            placeholder="Введите КПП контрагента"
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Наименование (ФИО) контрагента"
                        name="counterpartyName"
                    >
                        <Input
                            placeholder="Введите наименование (ФИО) контрагента"
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        name="counterpartyForeignRegNumber"
                        label="Регистрационный номер контрагента в стране регистрации (инкорпорации)"
                        rules={[
                            {
                                required: !isINNFilled,
                                message: 'Пожалуйста, введите регистрационный номер',
                            },
                        ]}
                    >
                        <Input
                            placeholder="Введите регистрационный номер"
                            disabled={isDisabled}
                            onChange={handleCounterpartyRegNumChange}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        name="counterpartyCountryCode"
                        label="Код страны регистрации контрагента-нерезидента"
                        rules={[
                            {
                                required: isCounterpartyRegNumFilled,
                                message: 'Пожалуйста, введите код страны регистрации контрагента-нерезидента',
                            },
                        ]}
                    >
                        <CustomSelect
                            settings={{
                                url: '/ais/valueLists/AIS_COUNTRY_CODES',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код страны',
                                isDisabled,
                                showSearch: true,
                            }}
                        />
                    </Form.Item>
                </div>
                <div className="form-flex-row">
                    <Form.Item
                        label="Дата начала периода, к которому относится документ"
                        name="dateFrom"
                        required
                        rules={[{required: true}]}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Дата окончания периода, к которому относится документ"
                        name="dateTo"
                        required
                        rules={[{required: true}]}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>

                <div className="form-flex-row">
                    <Form.Item
                        label={(
                            <div style={{whiteSpace: 'pre'}}>
                                Код вида документа-основания, указывается договор,{'\n'}
                                на основании которого составлены первичные документы
                            </div>
                        )}
                        name="documentBaseCode"
                    >
                        <CustomSelect
                            settings={{
                                url: '/ais/valueLists/SPV_DOCS',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите код вида документа-основания',
                                isDisabled,
                                showSearch: true,
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Наименование документа-основания"
                        name="documentBaseName"
                    >
                        <Input
                            placeholder="Введите наименование документа-основания"
                        />
                    </Form.Item>
                </div>

                <div className="form-flex-row">
                    <Form.Item
                        label="Номер документа-основания"
                        name="documentBaseNumber"
                    >
                        <Input
                            placeholder="Введите Номер документа-основания"
                        />
                    </Form.Item>
                    <Form.Item
                        name="documentBaseDate"
                        label="Дата документа основания"
                        style={{maxWidth: 250}}
                    >
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isDisabled}
                        />
                    </Form.Item>
                </div>
            </div>
        </Form>
    );
});
