import Empty from 'antd/es/empty';
import Form from 'antd/es/form';
import React, {useImperativeHandle} from 'react';

import {
    AddItemButtonRef,
    OrgStructureTabProps,
} from 'pages/ais/organization-structure/organization-structure-page.types';
import {ParticipantsFields} from 'pages/ais/organization-structure/tabs/participants-tab/participants-fields/participants-fields';
import {AntdFormListProps} from 'shared/types/antd';

interface ParticipantsListProps extends Required<AntdFormListProps>, Pick<OrgStructureTabProps, 'isDisabled'> {}

export const ParticipantsList = React.forwardRef<AddItemButtonRef, ParticipantsListProps>((
    {
        fields,
        operations,
        errors,
        isDisabled,
    }: ParticipantsListProps,
    ref,
) => {
    const {add, remove} = operations;

    useImperativeHandle(ref, () => ({
        add,
    }), [add]);

    return (
        <>
            <Form.ErrorList errors={errors} />
            {fields?.length === 0 ? (
                <Empty description="Список пуст" />
            ) : (
                <div className="os-form-list">
                    {fields?.map(field => (
                        <ParticipantsFields
                            key={field.fieldKey}
                            field={field}
                            onRemove={remove}
                            isDisabled={isDisabled}
                        />
                    ))}
                </div>
            )}
        </>
    );
});
