import moment from 'moment';

import {ContextLookupValue} from 'modules/context/context-types';
import {DATE, DATE_DMY} from 'shared/constants/date-format';

export const extractFilterValuesFromRequestData = (data?: {[index: string]: any}, prefillDates = true) => {
    const {
        document_id: aisDocId,
        cparty_inn: cpartyInn,
        cparty_kpp: cpartyKpp,
        document_code: docCode,
        cparty_country_code: cpartyCountryCode,
        cparty_foreign_reg_number: cpartyForeignRegNum,
        docDate,
        dateFrom,
        dateTo,
        ...rest
    } = data ?? {};

    const dateFromFormatted = dateFrom
        ? moment(dateFrom, DATE_DMY).format(DATE)
        : prefillDates ? moment(new Date()).subtract(3, 'years').format(DATE) : undefined;
    const dateToFormatted = dateTo
        ? moment(dateTo, DATE_DMY).format(DATE)
        : prefillDates ? moment(new Date()).format(DATE) : undefined;
    const docDateFormatted = docDate ? moment(docDate, DATE_DMY).format(DATE) : undefined;

    return {
        dateFrom: dateFromFormatted,
        dateTo: dateToFormatted,
        docDate: docDateFormatted,
        aisDocId,
        cpartyInn,
        cpartyKpp,
        docCode,
        cpartyCountryCode,
        cpartyForeignRegNum,
        ...rest,
    };
};

export const SPVDOCOptionsLabelFormatter = (
    label: string,
    {lookupCode, meaning}: Pick<ContextLookupValue, 'lookupCode' | 'meaning'>,
) => `${meaning} (${lookupCode})`;
