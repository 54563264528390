export const AIS3_ORG_STRUCTURE_ENTITY_NAME = 'organization.structure';

export const OrganizationStructureTabKeys = {
    commonInfo: 'commonInfo',
    companyGroup: 'companyGroup',
    foreignParentsInformation: 'foreignParentsInformation',
    participants: 'participants',
} as const;

export const OrganizationStructureTabTitles = {
    commonInfo: 'Сведения об организации',
    companyGroup: 'Сведения о группе компаний (холдинге), о головной организации группы компаний (холдинге)',
    foreignParentsInformation: 'Сведения об иностранной материнской компании у головной организации группы компаний',
    participants: 'Сведения об участниках и о дочерних (зависимых) обществах',
} as const;

const createValidationMessage = (tab: keyof typeof OrganizationStructureTabKeys) => (
    `Ошибка валидации вкладки «${OrganizationStructureTabTitles[tab]}».
    Проверьте правильность заполнения всех обязательных полей`
);

export const OrganizationStructureTabValidationErrors = {
    commonInfo: createValidationMessage('commonInfo'),
    companyGroup: createValidationMessage('companyGroup'),
    foreignParentsInformation: createValidationMessage('foreignParentsInformation'),
    participants: createValidationMessage('participants'),
} as const;

// записываем названия полей по каждой вкладке для определения невалидных вкладок при ошибках валидации
export const CommonInfoFieldNames = {
    affiliation: 'affiliation',
    statePart: 'statePart',
    position: 'position',
    statePartType: 'statePartType',
    companyGroupInterdependence: 'companyGroupInterdependence',
    statePartShare: 'statePartShare',
} as const;

export const CompanyGroupFieldNames = {
    groupCode: 'groupCode',
    companyGroupName: 'companyGroupName',
    headInn: 'headInn',
    headName: 'headName',
    hasForeignParent: 'hasForeignParent',
} as const;

export const ForeignParentsFieldNames = {
    foreignParentRegNumber: 'foreignParentRegNumber',
    foreignParentCountryCode: 'foreignParentCountryCode',
    foreignParentName: 'foreignParentName',
} as const;

export const ParticipantsFieldNames = {
    subordination: 'subordination',
    interdependence: 'interdependence',
    participantType: 'participantType',
    participantName: 'participantName',
    participantNameLat: 'participantNameLat',
    participantInn: 'participantInn',
    foreignRegNumber: 'foreignRegNumber',
    countryCode: 'countryCode',
    shareParent: 'shareParent',
    participantGroupCode: 'participantGroupCode',
} as const;
