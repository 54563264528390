import {Form} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import './setting-template-transfer-target-filter.less';
import {useAppDispatch} from 'store/config/hooks';
import {
    loadSettingTemplateTargetColumns,
} from 'store/slices/document-upload-template/document-upload-template-slice-thunks';

import {WatcherFieldFlag} from '../../../../../report-configuration/hooks/use-form-fields-manager';
import {SettingTemplateTransferTargetFormFields} from '../../constants/setting-template-transfer-target/setting-template-transfer-target';

interface SettingTemplateTransferTargetProps {
    form: FormInstance;
  checkArrayIndependentWatcherFlag: (
    fieldKey: string,
    flag: WatcherFieldFlag
  ) => boolean;
}
export const SettingTemplateTransferTargetFilter: React.FunctionComponent<SettingTemplateTransferTargetProps> = (
    {checkArrayIndependentWatcherFlag, form}: SettingTemplateTransferTargetProps,
) => {
    const dispatch = useAppDispatch();
    const handleChangeFilter = (sublistAttributeOne: string, sublistAttributeTwo: string) => {
        if (sublistAttributeOne && sublistAttributeTwo) {
            dispatch(loadSettingTemplateTargetColumns({sublistCode: sublistAttributeTwo}));
        }
    };
    return (
        <div className={cn('setting-template-transfer-target-filter')}>
            <Form.Item
                name={SettingTemplateTransferTargetFormFields.SOURCE_TABLE}
                style={{width: 245}}
                label="Исходная таблица"
                rules={[{required: true}]}
            >
                <CustomSelect
                    handleTriggerEvent={value => {
                        handleChangeFilter(
                            value?.meaning,
                            form?.getFieldValue(SettingTemplateTransferTargetFormFields.TARGET_TABLE),
                        );
                    }}
                    settings={{
                        showSearch: true,
                        placeholder: 'Выберите тип поля',
                        url: '/lookupValue/PROGRAM_SETTING_SOURCE_TABLE',
                        isDisabled: checkArrayIndependentWatcherFlag(
                            SettingTemplateTransferTargetFormFields.SOURCE_TABLE,
                            WatcherFieldFlag.isDisabled,
                        ),
                        labelPath: 'meaning',
                        valuePath: 'meaning',
                    }}
                />
            </Form.Item>
            <Form.Item
                name={SettingTemplateTransferTargetFormFields.TARGET_TABLE}
                style={{width: 245}}
                label="Целевая таблица"
                rules={[{required: true}]}
            >
                <CustomSelect
                    handleTriggerEvent={value => {
                        handleChangeFilter(
                            form?.getFieldValue(SettingTemplateTransferTargetFormFields.SOURCE_TABLE),
                            value?.meaning,
                        );
                    }}
                    settings={{
                        showSearch: true,
                        isDisabled: checkArrayIndependentWatcherFlag(
                            SettingTemplateTransferTargetFormFields.SOURCE_TABLE,
                            WatcherFieldFlag.isDisabled,
                        ),
                        placeholder: 'Выберите тип поля',
                        url: '/lookupValue/PROGRAM_SETTING_TARGET_TABLE',
                        labelPath: 'meaning',
                        valuePath: 'description',
                    }}
                />
            </Form.Item>
        </div>
    );
};
