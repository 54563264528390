import {Button} from 'antd';
import {ColumnsType, TablePaginationConfig} from 'antd/es/table';
import moment from 'moment';
import React from 'react';
import {Link} from 'react-router-dom';

import {AIS_ROUTES} from 'components/ais/ais.constants';
import {ReactComponent as ArrowRightOutlined} from 'shared/assets/arrow-right-outlined.svg';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {DATE_DMY} from 'shared/constants/date-format';
import {AisCommentsRequestResultTableDto, AisRequestRowCommentTableDto} from 'store/slices/ais-slice/ais-comments-request';

export const COMMENTS_REQUEST_DEFAULT_PAGE_SIZE = 10;
export const COMMENTS_REQUEST_MONITOR_SERVICE_LOOKUP_CODE = 'responseFactorAnalysisComments-v3-EXTERNAL';

export const TABLE_PAGINATION_CONFIG: TablePaginationConfig = {
    defaultPageSize: COMMENTS_REQUEST_DEFAULT_PAGE_SIZE,
    pageSizeOptions: ['10', '20', '50', '100'],
    position: ['bottomLeft'],
    showSizeChanger: true,
    locale: {items_per_page: 'на странице', jump_to: ''},
    showQuickJumper: {
        goButton: <ArrowRightIcon className="pagination_jumper" />,
    },
};

export enum CommentRequestStatuses {
    NEW = 'Новый',
    COMPLETED = 'Завершен',
}

export const formRowCommentsTableHeader = (
    startYear?: number,
    endYear?: number,
): ColumnsType<AisRequestRowCommentTableDto> => [
    {
        title: 'Наименование показателя',
        dataIndex: 'factorName',
        key: 'factorName',
    },
    {
        title: 'Значение показателя по данным декларации',
        children: [
            {
                title: startYear ?? 'Период не определен',
                dataIndex: 'comparedValue',
                key: 'comparedValue',
                align: 'center',
                render: v => v.toLocaleString('ru-RU'),
            },
            {
                title: endYear ?? 'Период не определен',
                dataIndex: 'currentValue',
                key: 'currentValue',
                align: 'center',
                render: v => v.toLocaleString('ru-RU'),
            },
        ],
    },
    {
        title: 'Изменение в отчетном периоде по сравнению с предыдущим',
        children: [
            {
                title: 'абс.',
                dataIndex: 'absChange',
                key: 'absChange',
                align: 'center',
                render: v => v.toLocaleString('ru-RU'),
            },
            {
                title: 'отн.',
                dataIndex: 'relativeChange',
                key: 'relativeChange',
                align: 'center',
            },
        ],
    },
    {
        title: 'Требуется комментарий',
        dataIndex: 'commentRequired',
        key: 'commentRequired',
        align: 'center',
        render: v => (v ? 'Да' : 'Нет'),
    },
];

export const formCommentsRequestTableHeader = (
    paginationCurrent: number,
    paginationPageSize: number,
): ColumnsType<AisCommentsRequestResultTableDto> => [
    {
        title: '№',
        dataIndex: 'key',
        render: (_, __, index) => (
            <div>
                {((paginationCurrent - 1) * paginationPageSize) + index + 1}
            </div>
        ),
    },
    {
        title: 'Карточка запроса',
        dataIndex: 'ais3AnswerFormationCard',
        key: 'ais3AnswerFormationCard',
        render: (_, row) => (
            <Link
                to={`/${AIS_ROUTES.COMMENTS_REQUEST_RESULT}/comments-request/${row.id}`}
            >
                <Button
                    className="button-redirect__table-action"
                    icon={<ArrowRightOutlined />}

                >
                    Перейти
                </Button>
            </Link>
        ),
    },
    {
        title: 'Номер запроса',
        dataIndex: 'numberRequest',
        key: 'numberRequest',
    },
    {
        title: 'Год',
        dataIndex: 'year',
        key: 'year',
        align: 'center',
    },
    {
        title: 'Период',
        dataIndex: 'period',
        key: 'period',
        width: 200,
        align: 'center',
    },
    {
        title: 'Год для сравнения',
        dataIndex: 'yearToCompare',
        key: 'yearToCompare',
        align: 'center',
    },
    {
        title: 'Дата получения запроса',
        dataIndex: 'receivingDate',
        key: 'receivingDate',
        align: 'center',
        render: (_, row) => (row?.receivingDate && moment(row.receivingDate).format(DATE_DMY)),
    },
    {
        title: 'Дата отправки ответа',
        dataIndex: 'sendDate',
        key: 'sendDate',
        align: 'center',
        render: (_, row) => (row?.sendDate && moment(row.sendDate).format(DATE_DMY)),
    },
];
