export enum UserRoleFunction {
    VIEW = 'VIEW',
    CREATE = 'CREATE',
    CANCEL = 'CANCEL',
    DELETE = 'DELETE',
    AIS_PUBLISH = 'AIS_PUBLISH',
    DOWNLOAD_EXCEL = 'DOWNLOAD_EXCEL',
    XX_EXT_UPLOAD_DOC_JOURNAL = 'XX_EXT_UPLOAD_DOC_JOURNAL',
    DOWNLOAD_TEMPLATE = 'DOWNLOAD_TEMPLATE',
    UPLOAD_EXCEL = 'UPLOAD_EXCEL',
    UPDATE = 'UPDATE',
    GROUPS_SETTING = 'GROUPS_SETTING',
    XX_NALMON_UPLOAD_DOC_JOURNAL = 'XX_NALMON_UPLOAD_DOC_JOURNAL',
    VIEW_PUD = 'VIEW_PUD',
    UPLOAD_PUD = 'UPLOAD_PUD',
    VIEW_REQUESTS = 'VIEW_REQUESTS',
    VIEW_REQUIREMENTS = 'VIEW_REQUIREMENTS',
    DOC_JOURNAL_TAXES = 'DOC_JOURNAL_TAXES',
    VIEW_REQ_4_SCENARIO = 'VIEW_REQ_4_SCENARIO',
    DELETE_PUD = 'DELETE_PUD',
    UPLOAD_PDF = 'UPLOAD_PDF',
    DOWNLOAD_PDF = 'DOWNLOAD_PDF',
    VIEW_CR = 'VIEW_CR',
    ADD_TO_CAMPARISON = 'ADD_TO_CAMPARISON',
    CREATE_ANEX_DOC = 'CREATE_ANEX_DOC',
    SIGN_FILE_DOC = 'SIGN_FILE_DOC', // Подписать файл(ы) (внешний сервис)
    SAVE_FILTERS = 'SAVE_FILTERS',
    CHANGE_DOCUMENT_PUBLICATION_STATUS = 'CHANGE_DOCUMENT_PUBLICATION_STATUS'
}
