import cn from 'classnames';
import React from 'react';

import {Footer} from 'components/footer';
import {ModalRoot} from 'components/modal';
import {ReactComponent as LogoIcon} from 'shared/assets/main-logo.svg';
import {DEFAULT_THEME} from 'shared/constants/layout';
import {JSXContent} from 'shared/types';

import './auth-page.less';

interface AuthPageProps {
    className?: cn.Argument;
    children?: JSXContent;
}

export const AuthPage = ({className, children}: AuthPageProps) => (
    <div className={cn('auth-page', className)}>
        <div className="auth-page-logo">
            <LogoIcon className="auth-page-logo__icon" />
            <div className="auth-page-logo__name">
                Витрина<br />
                налогового<br />
                мониторинга
            </div>
            {process.env.REACT_APP_THEME !== DEFAULT_THEME && (
                <div
                    className="auth-page__partner-logo"
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/logos/${process.env.REACT_APP_THEME}-light.svg`}
                        alt="Логотип портала."
                    />
                </div>
            )}
        </div>
        {children}
        <Footer />
        <ModalRoot />
    </div>
);
