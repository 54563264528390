import moment from 'moment';

import {DATE_FOR_REQUEST} from 'shared/constants/date-format';
import {showMessage} from 'shared/utils';

import {RegionResponse} from '../regions/regions-types';
import {DefaultRegion} from './documents-constants';
import {
    DocumentTemplatesTypeResponse,
    DocumentTypeResponse,
    DocumentUploadFile,
    DocumentUploadFileRequest,
    DocumentUploadFileResponse,
    DocumentUploadSignature,
    DocumentUploadSignatureRequest,
    DocumentUploadSignatureResponse, FileAttachInfoResponse,
} from './documents-types';

export const generateDefaultFileInfo = (
    file: File,
    currentSection: string,
    regions?: RegionResponse[],
    fileAttachInfo?: FileAttachInfoResponse,
    defaultBranchName?: DocumentTypeResponse,
    docType?: DocumentTypeResponse,
    templateTypesOptions?: DocumentTemplatesTypeResponse[],
): DocumentUploadFile => {
    const {
        defaultSubSection, defaultSection, defaultBranchId, defaultName, defaultDocTypeId,
    } = fileAttachInfo || {};
    let section;
    if (regions && Array.isArray(regions)) {
        // используем секцию, которая пришла с бэка если она есть в опциях
        section = regions.find(el => el.regionCode === defaultSection);
        if (!section) {
            // иначе она будет текущей
            section = regions.find(el => el.regionCode === currentSection);
        }
    }

    const subSections = section?.subregionList || [];
    const subSection = subSections.length === 1 ? subSections[0] : subSections.find(
        el => el.code === defaultSubSection,
    );

    return {
        fileName: file.name,
        fileSize: `${file.size}`,
        title: defaultName || DefaultRegion.description,
        typeId: defaultDocTypeId || parseInt(DefaultRegion.id, 10),
        uploadDate: moment().format(DATE_FOR_REQUEST),
        sectionCode: section?.regionCode,
        sectionId: section?.id,
        subsectionCode: subSection?.code,
        subsectionId: subSection?.id,
        file,
        branchId: defaultBranchId || (defaultBranchName ? defaultBranchName.id : undefined),
        typeCode: docType?.lookupCode,
        templatesTypeOptions: templateTypesOptions,
    };
};

export const generateSignatureInfoFromResponse = (
    document: DocumentUploadSignatureResponse,
): Partial<DocumentUploadSignature> => ({
    documentFileId: document.attachmentId,
    documentName: document.docName,
    documentTypeName: document.docType,
    sectionCode: document.docSection,
    status: document.status,
    attachId: +document.attachmentId as number | undefined,
});

export const generateSignatureInfoFromDocumentInfo = (
    document: Record<string, any>,
): Partial<DocumentUploadSignature> => ({
    fileName: document.signatureFileName,
    documentFileName: document?.docFileName,
    documentFileId: document.attachmentId,
    documentName: document.docName,
    documentTypeName: document.docType,
    sectionCode: document.docSection,
    status: document.status,
    attachId: document.attachmentId,
});

export const makeDefaultFileInfo = (
    response: DocumentUploadFileResponse,
    file: File,
): DocumentUploadFile => ({
    fileName: response.fileName,
    fileSize: response.outFileSize,
    title: response.docTypeMean,
    type: response.docTypeCode,
    uploadDate: null,
    sectionCode: response.docSectionCode,
    subsectionCode: response.docSubsectionCode,
    typeId: response.docTypeId,
    sectionId: response.sectionId,
    subsectionId: response.subsectionId,
    file,
});

export const addFieldsInDefaultFileInfo = (
    fileInfo: DocumentUploadFile,
    defaultRegion?: RegionResponse,
    documentTypes?: DocumentTypeResponse[],
): DocumentUploadFile => ({
    ...fileInfo,
    typeId: fileInfo.docTypeId
        ? undefined
        : documentTypes
            ?.find(documentType => documentType.lookupType === fileInfo.type)?.id,
    sectionId: fileInfo.sectionId ?? defaultRegion?.id,
    subsectionId: fileInfo.subsectionId ?? defaultRegion?.subregionList.find(
        subregion => subregion.code === fileInfo.subsectionCode,
    )?.id,
});

export const generateUploadFileRequest = (
    files: DocumentUploadFile[],
    useUploadDate?: boolean,
): DocumentUploadFileRequest[] => files.map(documentFile => ({
    fileName: documentFile.fileName,
    documentName: documentFile.title,
    docTypeId: documentFile.typeId || documentFile.type,
    sectionCode: documentFile.sectionCode,
    subsectionCode: documentFile.subsectionCode,
    xmlValidateStatusCode: documentFile.xmlValidateStatusCode ?? undefined,
    branchId: documentFile.branchId,
    startDocDate: useUploadDate === false ? undefined : documentFile.uploadDate || undefined,
    corrNum: documentFile.correctionNumber ?? undefined,
    regNum: documentFile.regNum ?? undefined,
    file: documentFile.file,
    docUploadTemplateCode: documentFile.templateCode,
}));

export const generateUploadSignRequest = (
    signatureFiles: DocumentUploadSignature[],
): DocumentUploadSignatureRequest[] => signatureFiles.map(signFile => ({
    attachId: signFile.attachId ? `${signFile.attachId}` : undefined,
    file: signFile.file,
    fileName: signFile.fileName,
}));

const urlRegex = /(?<=\/)([a-z0-9]|\.)+/i;

export const getSubsectionFromLocation = (
    {pathname}: any,
) => `app.${urlRegex.exec(pathname)?.[0]}` || '';

export const getEntityNameFromLocation = (
    {pathname}: any,
) => `${urlRegex.exec(pathname)?.[0]}` || '';

/**
 * Метод необходим из за особенностей работы некоторых api c названием сущности
 * @param entityName
 */
export const removeExtraPartFromEntityName = (entityName: string) => (
    entityName.replace(/\.(declaration|registers|descriptions|directory)/i, '')
);

export const getSubsectionFromEntityName = (
    entityName: string = '',
) => (
    /^app\./.test(entityName)
        ? entityName
        : `app.${entityName}`
);

export function createNewFileNameToRepeat(fileList: any[]) {
    return function callback(fileName?: string): string {
        if (!fileName) return '';

        const shouldAddFileNumber = fileList
            .some((fileInfo: DocumentUploadFile) => fileInfo.fileName === fileName);
        let changedFileName = fileName;

        if (shouldAddFileNumber) {
            const fileNumberAndFileExtension = /(\()(\d+)(\))(\.[\w\d]{0,6}$)/;
            const shouldIncreaseFileNumber = fileNumberAndFileExtension.test(fileName);
            if (shouldIncreaseFileNumber) {
                changedFileName = fileName
                    .replace(
                        fileNumberAndFileExtension,
                        (_: string, bracketLeft, num, bracketRight, fileExtension) => {
                            const newNum = +num + 1;

                            return `${bracketLeft}${newNum}${bracketRight}${fileExtension}`;
                        },
                    );

                return callback(changedFileName);
            }

            const fileExtension = /(\.[\w\d]{0,6}$)/;
            changedFileName = changedFileName.replace(
                fileExtension, (_: string, extensionGroup) => `(1)${extensionGroup}`,
            );

            return callback(changedFileName);
        }

        return changedFileName;
    };
}

export const filterFilesByExtensions = (files: File[], unacceptedFileExtensions?: string[]) => {
    const unacceptedExtensions: Set<string> = new Set();
    const filteredFiles = files.filter(
        file => {
            const fileExt = `.${file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1)}`;
            const acceptable = !unacceptedFileExtensions?.some(
                ext => ext === fileExt || (fileExt === '.' && ext === ''),
            );
            if (!acceptable) {
                unacceptedExtensions.add(fileExt);
            }
            return acceptable;
        },
    );
    if (unacceptedExtensions.size) {
        showMessage({
            message: `Файлы с недопустимым расширениями: ${Array.from(unacceptedExtensions).join(', ')}
             были удалены из списка загружаемых файлов.`,
            isError: true,
        });
    }

    return filteredFiles;
};
