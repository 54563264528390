export const AIS_INTERFACE_ROLE_SECTION = 'app.ais3.doc.journal.interface';
export const STATUS_CODES = {
    NEW: 'Новый',
    PUBLISHED: 'Опубликован',
    DELETED: 'Удален',
    READY_TO_PUBLISH: 'Готов к публикации',
};
export const DEFAULT_PAGE_SIZE = 100;
export const TRUNCATED_COLUMNS = ['documentName', 'counterpartyName'];
export const TRUNCATED_TEXT_LENGTH = 30;
