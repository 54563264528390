import Title from 'antd/lib/typography/Title';
import React from 'react';

import {PageTemplateProps} from 'pages';

import {usePDFLinkFallbackPage} from './hooks';
import {PAGE_MODES} from './pdf-link-fallback-page-constants';

export const PDFLinkFallbackPage: React.FunctionComponent<PageTemplateProps> = () => {
    const {
        mode, responseText, redirectTo, token, handleDownload,
    } = usePDFLinkFallbackPage();

    if (!redirectTo || !token) {
        return null;
    }

    return (
        <div>
            {mode === PAGE_MODES.HTTP_ERROR && (
                <Title level={4}>{responseText}</Title>
            )}
            {mode === PAGE_MODES.NOT_PDF && (
                <>
                    <Title level={2}>{responseText}</Title>
                    <div
                        onClick={handleDownload}
                    >
                        <a
                            href={redirectTo}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => {
                                e.preventDefault();
                            }}
                            style={{
                                fontSize: 20,
                                textDecoration: 'underline',
                            }}
                        >
                            Скачать
                        </a>
                    </div>
                </>
            )}

        </div>
    );
};
