import {Input} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import Col from 'antd/es/grid/col';
import Row from 'antd/es/grid/row';
import InputNumber from 'antd/es/input-number';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {CustomSelectValueTriggerEvent} from 'components/form/inputs/custom-select/custom-select';
import {
    OrganizationStructureTabKeys as FormKeys,
    CompanyGroupFieldNames as FieldNames,
} from 'pages/ais/organization-structure/organization-structure-page.constants';
import {OrgStructureTabProps} from 'pages/ais/organization-structure/organization-structure-page.types';

interface CompanyGroupTabProps extends OrgStructureTabProps {}

export const CompanyGroupTab = ({setValidationField, buttonsBar, isDisabled}: CompanyGroupTabProps) => (
    <>
        {buttonsBar?.()}
        <div
            className="tab-bordered"
        >
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[FormKeys.companyGroup, FieldNames.groupCode]}
                        label="Код группы компаний (холдинга)"
                        rules={[{required: true}]}
                    >
                        <InputNumber
                            className="w-100"
                            placeholder="Введите код"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        name={[FormKeys.companyGroup, FieldNames.companyGroupName]}
                        label="Наименование группы компаний (холдинга)"
                        rules={[
                            {required: true},
                            {max: 250, message: 'Длина должна быть не более 250 символов'},
                        ]}
                    >
                        <Input
                            placeholder="Введите наименование"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[FormKeys.companyGroup, FieldNames.headInn]}
                        label="ИНН российской головной организации группы компаний (холдинга)"
                        rules={[
                            {required: true},
                            {max: 10, message: 'Длина должна быть не более 10 символов'},
                        ]}
                    >
                        <Input
                            type="number"
                            placeholder="Введите ИНН"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
                <Col span={12}>
                    <FormItem
                        name={[FormKeys.companyGroup, FieldNames.headName]}
                        label="Наименование российской головной организации группы компаний (холдинга)"
                        rules={[
                            {required: true},
                            {max: 250, message: 'Длина должна быть не более 250 символов'},
                        ]}
                    >
                        <Input
                            placeholder="Введите наименование"
                            disabled={isDisabled}
                        />
                    </FormItem>
                </Col>
            </Row>
            <Row
                align="bottom"
                gutter={16}
            >
                <Col span={12}>
                    <FormItem
                        name={[FormKeys.companyGroup, FieldNames.hasForeignParent]}
                        label="Признак наличия иностранной материнской компании у головной организации
                             группы компаний (холдинга)"
                        rules={[{required: true}]}
                    >
                        <CustomSelect
                            onChange={value => {
                                setValidationField?.('isForeignParentSet', value === '1');
                            }}
                            handleTriggerEvent={(value, event) => {
                                if (event === CustomSelectValueTriggerEvent.receivedDataFromFetcher) {
                                    setValidationField?.('isForeignParentSet', value?.lookupCode === '1');
                                }
                            }}
                            settings={{
                                url: 'valueLists/FLEX_VALUE?sublistCode=has_foreign_parent',
                                valuePath: 'lookupCode',
                                labelPath: 'meaning',
                                placeholder: 'Выберите значение',
                                isClearable: true,
                                showSearch: true,
                                isDisabled,
                            }}
                        />
                    </FormItem>
                </Col>
            </Row>
        </div>
    </>
);
