import {Empty, Form, Input} from 'antd';
import React, {useMemo} from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DATE, DATE_DMY} from 'shared/constants/date-format';
import {LocationStateKey, useAppHistory} from 'shared/hooks/use-app-history';
import {useAppSelector} from 'store/config/hooks';
import {aisSelectors} from 'store/slices/ais-slice/ais-slice';
import {AisDocRegisterFieldTypes} from 'store/slices/ais-slice/ais-slice-types';

import {SPVDOCOptionsLabelFormatter} from './ais-documents-register-utils';

interface AisDocumentsParametersFilterProps {
    isFilterApplied?: boolean;
}

export const AisDocumentsParametersFilter = (
    {isFilterApplied}: AisDocumentsParametersFilterProps,
) => {
    const {filters} = useAppSelector(aisSelectors.selectAisRegisterDocumentsFields) ?? {};

    const {currentState} = useAppHistory();

    const ignoreRequiredByLinkSettings = !!currentState?.[LocationStateKey.LINK_META]?.ignoreRequiredParameters;

    const filtersJSX = useMemo(
        () => filters?.map(f => {
            const lowerCaseLabel = f.label?.toLowerCase();

            const isRequired = !ignoreRequiredByLinkSettings && f.isRequired;
            const isSPVDOC = f.referenceUrl?.includes('SPVDOC_TYPE_CAT_CODES')
                            || f.referenceUrl?.includes('SPVDOC_TYPE_CODES');

            const inputField = (() => {
                switch (f.type) {
                case AisDocRegisterFieldTypes.DATE:
                    return (
                        <DateCell
                            format={DATE_DMY}
                            requestFormat={DATE}
                            disabled={isFilterApplied}
                        />
                    );
                case AisDocRegisterFieldTypes.REFERENCE:
                    return (
                        <CustomSelect
                            optionsLabelFormatter={isSPVDOC ? SPVDOCOptionsLabelFormatter : undefined}
                            settings={{
                                placeholder: `Выберите ${lowerCaseLabel}`,
                                url: f.referenceUrl,
                                labelPath: 'meaning',
                                valuePath: 'lookupCode',
                                isDisabled: isFilterApplied,
                            }}
                        />
                    );
                default:
                    return (
                        <Input
                            placeholder={`Введите ${lowerCaseLabel}`}
                            disabled={isFilterApplied}
                        />
                    );
                }
            })();

            return (
                <Form.Item
                    key={f.fieldKey}
                    label={f.label}
                    name={f.fieldKey}
                    rules={[{
                        required: isRequired,
                        message: `Пожалуйста, заполните поле ${lowerCaseLabel}`,
                    }]}
                    required={isRequired}
                    data-type={f.type === AisDocRegisterFieldTypes.DATE ? 'date' : undefined} // для css
                >
                    {inputField}
                </Form.Item>
            );
        }),
        [filters],
    );

    return filtersJSX?.length ? <>{filtersJSX}</> : (
        <Empty
            style={{width: '100%'}}
            description="Фильтры не найдены"
        />
    );
};
