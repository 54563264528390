import {Skeleton} from 'antd';
import {Tooltip} from 'antd/es';
import MenuItem from 'antd/es/menu/MenuItem';
import cn from 'classnames';
import React, {useEffect} from 'react';

import {CustomLink as InnerCustomLink} from 'components/@common/widgets/custom-link';
import type {CustomLinkProps as InnerCustomLinkProps} from 'components/@common/widgets/custom-link';
import {TableActionProps} from 'components/table/action/table-action-types';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectCustomLinkConfig} from 'store/slices/custom-link-slice/custom-link-slice';
import {loadCustomLinkConfig} from 'store/slices/custom-link-slice/custom-link-slice-thunks';
import {selectIsThunkPending, selectThunkError} from 'store/slices/loading-state-slice';

import './custom-link.less';

interface CustomLinkProps extends TableActionProps {}

interface CustomLinkButtonProps extends InnerCustomLinkProps {
    isLoading: boolean;
    loadingError: string | undefined;
}

const CustomLinkButton = ({
    link,
    externalProperties,
    isLoading,
    loadingError,
}: CustomLinkButtonProps) => (
    <div className={cn('custom-link-button')}>
        {(() => {
            if (loadingError) {
                return (
                    <Tooltip
                        className="custom-link-button__disabled"
                        title={`Не удалось получить конфигурацию ссылки. Подробнее об ошибке: ${loadingError}`}
                        placement="bottom"
                    >
                        {externalProperties?.title ?? 'Ссылка'}
                    </Tooltip>
                );
            }

            if (isLoading) {
                return (
                    <Skeleton.Button
                        active
                    />
                );
            }

            return (
                <InnerCustomLink
                    link={link}
                    externalProperties={externalProperties}
                />
            );
        })()}
    </div>
);

export const CustomLink = ({
    entityName,
    meta,
    wrapper,
    ...props
}: CustomLinkProps) => {
    const dispatch = useAppDispatch();

    const {
        actionTitle,
        actionIcon,
        referenceUrl,
    } = meta;

    const referenceKey = referenceUrl && referenceUrl.split('/').pop();
    const linkKey = `${entityName}/${referenceKey}`;

    const linkConfig = useAppSelector(s => selectCustomLinkConfig(s, {key: linkKey}));
    const isLoading = useAppSelector(s => selectIsThunkPending(s, loadCustomLinkConfig.typePrefix));
    const loadingError = useAppSelector(s => selectThunkError(s, loadCustomLinkConfig.typePrefix));

    useEffect(() => {
        if (!linkConfig && referenceUrl && linkKey) {
            dispatch(loadCustomLinkConfig({key: linkKey, url: referenceUrl}));
        }
    }, []);

    const isSubmenu = !!wrapper;

    const customLinkButton = (
        <CustomLinkButton
            link={linkConfig}
            externalProperties={{
                title: actionTitle,
                icon: actionIcon,
            }}
            isLoading={isLoading}
            loadingError={loadingError?.data}
        />
    );

    return (
        isSubmenu ? (
            <MenuItem
                key={meta.actionTitle}
                className={cn('custom-link-meta', 'submenu-item')}
                {...props}
            >
                {customLinkButton}
            </MenuItem>
        ) : (
            <div>
                {customLinkButton}
            </div>
        )
    );
};
