import cn from 'classnames';
import React from 'react';

import {JSXContent} from 'shared/types';

import s from './action-bar.module.less';

interface ActionBarProps {
    children?: JSXContent | React.ReactNode;
    side?: 'right' | 'left' | 'center' | 'space-between';
    bordered?: boolean;
    transparent?: boolean;
    className?: string;
}

export const ActionBar: React.FC<ActionBarProps> = (
    {
        children,
        side = 'left',
        bordered = true,
        transparent = false,
        className,
    }: ActionBarProps,
) => (
    <div className={cn(
        s['action-bar'],
        s[`action-bar_${side}`],
        {[s['action-bar_bordered']]: bordered},
        {[s['action-bar_transparent']]: transparent},
        className,
    )}
    >
        {children}
    </div>
);
