import {PlusCircleOutlined} from '@ant-design/icons';
import {
    Button, Empty, Form,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import cn from 'classnames';
import React, {
    useContext, useEffect, useState,
} from 'react';

import {DocumentUploadTemplateTabKey} from 'components/document-upload-template/document-upload-template-constants';
import {WatcherFieldFlag} from 'components/report-configuration/hooks/use-form-fields-manager';
import {useFormListFieldsManager} from 'components/report-configuration/hooks/use-form-list-fields-manager';
import {setFieldsValueUntouched, showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {selectDocumentUploadTemplateData} from 'store/slices/document-upload-template/document-upload-template-selectors';
import {
    loadDocumentUploadTemplateData,
    updateDocumentUploadTemplateConfiguration,
} from 'store/slices/document-upload-template/document-upload-template-slice-thunks';
import {DocumentUploadTemplateData} from 'store/slices/document-upload-template/document-upload-template-slice-type';

import {SettingTemplateTargetTableButtons} from '../../components/setting-template-target-table-buttons/setting-template-target-table-buttons';
import {DocumentUploadTemplateContext} from '../../document-upload-template-context';
import {
    createDocumentUploadTemplateWatchers, createDffSegmentValues,
} from '../../document-upload-template-utils';
import {SettingTemplateTargetTableFields} from './components/setting-template-target-table-fields/setting-template-target-table-fields';
import {SettingTemplateTargetTableQuery} from './components/setting-template-target-table-query/setting-template-target-table-query';
import {
    SETTING_TEMPLATE_TARGET_FORM_DATA_KEY, SettingTemplateTargetFormQueryFields, settingTemplateTargetMainFields,
} from './constants/setting-template-target-table-constats';

import './setting-template-target-table.less';

interface SettingTemplateTargetTableProps{
    entityName: string;
}
export const SettingTemplateTargetTable: React.FC<SettingTemplateTargetTableProps> = (
    {entityName} : SettingTemplateTargetTableProps,
) => {
    const [form] = useForm<DocumentUploadTemplateData>();
    const [formState, setFormState] = useState(
        {initialValues: {}, isEditingForm: false, isDirty: false},
    );
    const documentUploadTemplate = useAppSelector(selectDocumentUploadTemplateData);
    const dispatch = useAppDispatch();

    const {
        templateCode,
    } = useContext(DocumentUploadTemplateContext);

    const {
        applyFieldWatchers,
        checkWatcherFlag,
        checkArrayIndependentWatcherFlag,
    } = useFormListFieldsManager({
        formInstance: form,
        watchers: createDocumentUploadTemplateWatchers({
            isEditingForm: formState.isEditingForm,
            mainFields: settingTemplateTargetMainFields,
        }),
        arrayIndependentWatchers: {
            [SettingTemplateTargetFormQueryFields.attribute3]: [
                {
                    condition: () => !formState.isEditingForm,
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
            [SettingTemplateTargetFormQueryFields.attribute4]: [
                {
                    condition: () => !formState.isEditingForm,
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
            removeButton: [
                {
                    isNotInput: true,
                    condition: () => !formState.isEditingForm,
                    flag: WatcherFieldFlag.isDisabled,
                },
            ],
        },
        formArrayKey: SETTING_TEMPLATE_TARGET_FORM_DATA_KEY,
        triggerDeps: [formState.isEditingForm],
        modifiers: {
            trackFormDirtyState: true,
            isDirty: formState.isDirty,
            setIsDirty: isDirty => setFormState(v => ({...v, isDirty})),
        },
    });

    useEffect(() => {
        if (documentUploadTemplate) {
            const {programMappings = []} = documentUploadTemplate ?? {};
            const dataToSetToForm = programMappings;

            form.resetFields();
            setFieldsValueUntouched(form, {
                attribute3: documentUploadTemplate?.attribute3,
                attribute4: documentUploadTemplate?.attribute4,
                [SETTING_TEMPLATE_TARGET_FORM_DATA_KEY]: dataToSetToForm,
            });
            setFormState(v => ({
                ...v,
                initialValues: {
                    attribute3: documentUploadTemplate?.attribute3,
                    attribute4: documentUploadTemplate?.attribute4,
                    [SETTING_TEMPLATE_TARGET_FORM_DATA_KEY]: dataToSetToForm,
                },
            }));
        }
        applyFieldWatchers();
    }, [documentUploadTemplate]);

    const handleFinish = (values: any) => {
        const data: DocumentUploadTemplateData = {
            dffSegmentValues: createDffSegmentValues(Object.entries(values)),
            programMappings: values?.programMappings ?? [],
        } as DocumentUploadTemplateData;

        if (templateCode) {
            dispatch(
                updateDocumentUploadTemplateConfiguration({
                    entityName,
                    templateCode,
                    data,
                }),
            )
                .unwrap()
                .then(() => {
                    dispatch(loadDocumentUploadTemplateData({
                        entityName,
                        templateCode,
                    }));
                })
                .catch(error => {
                    const errorMessage = error?.response?.data;
                    showMessage({
                        message: 'Не удалось сохранить изменения',
                        description: errorMessage ? `Текст ошибки: «${errorMessage}»` : undefined,
                        isError: true,
                    });
                });
        }
    };

    return (
        <div className={cn('setting-template-target-table')} >
            <Form
                className={cn('setting-template-target__form')}
                layout="vertical"
                form={form}
                name={SETTING_TEMPLATE_TARGET_FORM_DATA_KEY}
                onFinish={handleFinish}
            >
                <SettingTemplateTargetTableButtons {...{
                    formState,
                    setFormState,
                    form,
                    tabName: DocumentUploadTemplateTabKey.SETTINGS_TEMPLATE_TARGET,
                }}
                />
                <div className={cn('setting-template-target__section-title')} />
                <SettingTemplateTargetTableQuery
                    checkArrayIndependentWatcherFlag={checkArrayIndependentWatcherFlag}
                />
                <div className={cn('setting-template-target__body')}>
                    <Form.List name={SETTING_TEMPLATE_TARGET_FORM_DATA_KEY}>{(fields, {add, remove}) => (
                        <>
                            <Button
                                type="primary"
                                onClick={() => add()}
                                icon={(<PlusCircleOutlined className="svg-icon-size-16" />)}
                                disabled={!formState.isEditingForm}
                            >Добавить строку
                            </Button>
                            {!fields.length && <Empty />}
                            <SettingTemplateTargetTableFields
                                form={form}
                                fields={fields}
                                remove={remove}
                                checkWatcherFlag={checkWatcherFlag}
                                checkArrayIndependentWatcherFlag={checkArrayIndependentWatcherFlag}
                            />
                        </>
                    )}
                    </Form.List>
                </div>
            </Form>
        </div>
    );
};
