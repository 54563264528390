import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {DynamicInputField} from 'shared/types';
import {axiosBaseQuery} from 'store/api/base-query';

import {ProgramArg, ProgramArgsResponse} from './monitor-services.types';

interface GetMonitorServiceById {
    id?: string | number | null;
}

const MONITOR_SERVICES_URL = 'integration.module/monitor.services';
const MONITOR_PROGRAM_SETTINGS_PREFIX = 'administration.monitor.programs';

export const MonitorServicesApi = createApi({
    reducerPath: 'monitorServicesApi',
    baseQuery: axiosBaseQuery(),
    tagTypes: ['MonitorServicesDynamicFields'],
    endpoints: builder => ({
        getAdditionalFormFieldsByMonitorServiceId: builder.query<DynamicInputField[], GetMonitorServiceById>({
            query: ({id}) => ({
                url: `${MONITOR_SERVICES_URL}/executionFields/${id}`,
            }),
        }),
        getAdditionalFormFieldsByMonitorLookupCode: builder.query<
        {id: number; programArgs:ProgramArg[]},
        {lookupCode: string}
        >({
            query: ({lookupCode}) => ({
                url: `${MONITOR_PROGRAM_SETTINGS_PREFIX}/${lookupCode}`,
            }),
            transformResponse: (response: ProgramArgsResponse) => ({
                id: response.id,
                programArgs: response.programArgs,
            }),

        }),
        runMonitorService: builder.mutation<any, any>({
            query: data => {
                const formData = new FormData();
                Object?.keys(data)?.forEach(k => {
                    const value = data?.[k];
                    if (value !== undefined) {
                        if (Array.isArray(value)) {
                            value.forEach((file: any) => formData.append(k, file));
                        } else {
                            formData.append(k, value);
                        }
                    }
                });

                return {
                    url: `${MONITOR_SERVICES_URL}/execute`,
                    method: 'POST',
                    data: formData,
                };
            },
        }),
        runAdministrationMonitorService: builder.mutation<any, {data: any; id: number}>({
            query: ({data, id}) => {
                const filteredData = Object.fromEntries(
                    Object.entries(data).filter(([, value]) => value !== ''),
                );

                return {
                    url: `monitor/execute/${id}`,
                    method: 'POST',
                    data: {id: -1, ...filteredData},
                };
            },
        }),
        getRequestContentByMonitorServiceId: builder.query<string, GetMonitorServiceById>({
            query: ({id}) => ({
                url: `${MONITOR_SERVICES_URL}/requestContent/${id}`,
            }),
        }),
        getResponseContentByMonitorServiceId: builder.query<string, GetMonitorServiceById>({
            query: ({id}) => ({
                url: `${MONITOR_SERVICES_URL}/responseContent/${id}`,
            }),
        }),
    }),
});

export const {
    useLazyGetAdditionalFormFieldsByMonitorServiceIdQuery,
    useLazyGetAdditionalFormFieldsByMonitorLookupCodeQuery,
    useRunMonitorServiceMutation,
    useRunAdministrationMonitorServiceMutation,
    useGetRequestContentByMonitorServiceIdQuery,
    useGetResponseContentByMonitorServiceIdQuery,
} = MonitorServicesApi;
