import {Input} from 'antd';
import {FormInstance} from 'antd/es/form';
import React from 'react';

import {
    FileUploader,
} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/components/file-uploader/file-uploader';
import {
    FILE_UPLOAD_EXTENSIONS,
    SIGN_UPLOAD_EXTENSIONS,
} from 'components/ais/monitor-services-card/monitor-service-dynamic-fields/monitor-service-dynamic-fields.constants';
import {CustomSelect} from 'components/form/inputs/custom-select';
import {InputNumber} from 'components/form/inputs/input-number';
import {DateCell} from 'components/table/columns/date-cell/date-cell';
import {DocumentsSigningMethod} from 'modules/documents/documents-types';
import {FieldMeta, FieldType} from 'modules/metadata';
import {DynamicInputField} from 'shared/types';

import {CryptoProSigningInput} from '../../../@common/inputs/crypto-pro-signing-input';

interface DynamicFieldResolverArgs extends Pick<FieldMeta, 'requestDateFormat' | 'dateFormat'> {
    form: FormInstance;
    key: string | number;
    documentsSigningMethod?: DocumentsSigningMethod;
    entityName: string;
    type?: FieldType;
    isDisabled?: boolean;
    defaultValue?: string;
    referenceUrl?: string;
    placeholder?: string;
    useFirstOptionAsDefaultValue?: boolean;
}

interface GetFileFieldPropsArgs {
    accept: string;
    disabled: boolean;
    multiple?: boolean;
}

const getFileFieldProps = (
    type: FieldType,
    isDisabled?: boolean,
): GetFileFieldPropsArgs => {
    const uploaderProps: GetFileFieldPropsArgs = {
        disabled: isDisabled || false,
        accept: FILE_UPLOAD_EXTENSIONS,
    };
    if (type === FieldType.FILE_LIST_WITH_SIGNS) {
        uploaderProps.accept = SIGN_UPLOAD_EXTENSIONS;
    }
    uploaderProps.multiple = type === FieldType.FILE_LIST || type === FieldType.FILE_LIST_WITH_SIGNS;
    return uploaderProps;
};

export const dynamicFieldResolver = ({
    documentsSigningMethod,
    entityName,
    form,
    type,
    isDisabled,
    defaultValue,
    placeholder,
    referenceUrl,
    dateFormat,
    requestDateFormat,
    key,
    useFirstOptionAsDefaultValue,
}: DynamicFieldResolverArgs) => {
    switch (type) {
    case FieldType.TEXT:
    case FieldType.STRING: {
        return (
            <Input
                defaultValue={defaultValue}
                disabled={isDisabled}
                placeholder={placeholder}
            />
        );
    }
    case FieldType.NUMBER: {
        return (
            <InputNumber
                disabled={isDisabled}
                placeholder={placeholder}
            />
        );
    }
    case FieldType.VALUESET:
    case FieldType.REFERENCE: {
        return (
            <CustomSelect
                settings={{
                    url: referenceUrl,
                    labelPath: 'meaning',
                    valuePath: entityName === 'administration.monitor.programs' ? 'id' : 'lookupCode',
                    isClearable: true,
                    showSearch: true,
                    placeholder,
                    isDisabled,
                    useFirstOptionAfterReceivedFromFetcher: useFirstOptionAsDefaultValue,
                }}
            />
        );
    }
    case FieldType.FILE:
    case FieldType.FILE_LIST:
    case FieldType.MULTIPART_FILE:
    case FieldType.LIST_MULTIPART_FILE:
    case FieldType.FILE_LIST_WITH_SIGNS: {
        const fileFieldProps = getFileFieldProps(
            type,
            isDisabled,
        );
        if (documentsSigningMethod === DocumentsSigningMethod.CRYPTO_PRO_PLUGIN && key === 'signs') {
            return (
                <CryptoProSigningInput
                    form={form}
                    fileFieldNames={['files', 'powerOfAttorney', 'mainDocument']}
                    entityName={entityName}
                />
            );
        }
        return <FileUploader {...fileFieldProps} />;
    }
    case FieldType.DATE: {
        return (
            <DateCell
                requestFormat={requestDateFormat ?? 'DD.MM.YYYY'}
                format={dateFormat ?? 'DD.MM.YYYY'}
            />
        );
    }
    default: {
        return null;
    }
    }
};

export const dynamicFieldsSorter = (a?: DynamicInputField, b?: DynamicInputField) => (
    a?.displayOrder && b?.displayOrder ? a.displayOrder - b.displayOrder : 0
);
