import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';

import {ais3SliceName} from '../ais-slice-constants';

const selectAisSliceState = (state: AppState) => state[ais3SliceName];

export const selectCommentsRequestTableData = createSelector(
    selectAisSliceState,
    ({aisCommentsRequest}) => aisCommentsRequest.aisCommentsRequestTable,
);

export const selectCommentsRequestRowsTableData = createSelector(
    selectAisSliceState,
    ({aisCommentsRequest}) => aisCommentsRequest.aisInspectorCommentInfo,
);
