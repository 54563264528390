import {createSelector} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';
import {ais3SliceName} from 'store/slices/ais-slice/ais-slice-constants';

const selectAisSliceState = (state: AppState) => state[ais3SliceName];

export const selectAisOrganizationStructureData = createSelector(
    selectAisSliceState,
    ({organizationStructure}) => organizationStructure,
);
