import {RequestType} from 'modules/metadata';
import {LinkDto} from 'shared/types/links';
import {performRequest} from 'shared/utils';

export const getCustomLinkConfig = (url: string) => (
    performRequest<LinkDto>({
        url,
        method: RequestType.GET,
    })
);
