import {ActionReducerMapBuilder} from '@reduxjs/toolkit';

import {AisSliceState} from 'store/slices/ais-slice/ais-slice-types';
import {getOrganizationStructureData} from 'store/slices/ais-slice/organization-structure/organization-structure-thunks';

export const createAisOrganizationStructureReducer = <S extends AisSliceState>() => ({
    resetAisOrganizationStructure(state: S) {
        state.organizationStructure = undefined;
    },
});

export const createAisOrganizationStructureExtraReducer = (builder: ActionReducerMapBuilder<AisSliceState>) => {
    builder.addCase(getOrganizationStructureData.fulfilled, (state, {payload}) => {
        state.organizationStructure = payload;
    });
};
