import {
    InfoCircleOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import cn from 'classnames';
import React from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector} from 'store/config/hooks';
import {selectAuthToken} from 'store/slices/auth-slice';
import {openModal} from 'store/slices/modals-slice';

import './footer.less';

export const Footer: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isAuthorized = !!useAppSelector(selectAuthToken);

    const handleOnClick = () => dispatch(
        openModal({modalName: 'listGridModal', entityName: 'administration.settings.helpdesk'}),
    );
    const handleOnClickAppInfo = () => dispatch(openModal({modalName: 'listModal', entityName: 'app.version'}));

    return (
        <footer className={cn('footer', {dark: isAuthorized, fixed: !isAuthorized})}>
            {!isAuthorized && (
                <div className="footer__block">
                    <div onClick={handleOnClick}>
                        <InfoCircleOutlined
                            className="footer__button"
                        />
                        <span className="footer__button-text">Отдел поддержки пользователей</span>
                    </div>
                    <div className="footer__delimiter">|</div>
                    <div onClick={handleOnClickAppInfo}>
                        <QuestionCircleOutlined
                            className="footer__button"
                        />
                        <span className="footer__button-text">Информация о приложении</span>
                    </div>
                </div>
            )}
            <span><b>Внимание!</b> Информация в системе является Коммерческой тайной</span>
        </footer>
    );
};
