import {Button, Input} from 'antd';
import {Row} from 'antd/es';
import FormItem from 'antd/es/form/FormItem';
import React from 'react';

import {DynamicIcon} from 'components/dynamic-icon';
import {ForeignParentsFieldNames as FieldNames} from 'pages/ais/organization-structure/organization-structure-page.constants';
import {OrgStructureTabProps} from 'pages/ais/organization-structure/organization-structure-page.types';
import {AntdFormListFieldProps} from 'shared/types/antd';

import './foreign-parents-fields.less';

interface ForeignParentsFieldsProps extends AntdFormListFieldProps, Pick<OrgStructureTabProps, 'isDisabled'> {}

export const ForeignParentsFields = ({field, onRemove, isDisabled}: ForeignParentsFieldsProps) => (
    <div className="foreign-parents-fields">
        <FormItem
            name={[field.name, FieldNames.foreignParentRegNumber]}
            label="Регистрационный номер иностранной материнской компании в стране регистрации (инкорпорации)"
            rules={[
                {required: true},
                {max: 100, message: 'Длина должна быть не более 100 символов'},
            ]}
        >
            <Input
                placeholder="Введите номер"
                disabled={isDisabled}
            />
        </FormItem>
        <FormItem
            name={[field.name, FieldNames.foreignParentCountryCode]}
            label="Код страны регистрации (инкорпорации) иностранной материнской компании"
            rules={[
                {required: true},
                {max: 3, message: 'Длина должна быть не более 3 символов'},
            ]}
        >
            <Input
                placeholder="Введите код"
                disabled={isDisabled}
            />
        </FormItem>
        <FormItem
            name={[field.name, FieldNames.foreignParentName]}
            label="Наименование иностранной материнской компании головной организации группы
                     компаний (холдинга) в латинской транскрипции"
            rules={[
                {required: true},
                {max: 250, message: 'Длина должна быть не более 250 символов'},
            ]}
        >
            <Input
                placeholder="Введите наименование"
                disabled={isDisabled}
            />
        </FormItem>
        <Row justify="end">
            <Button
                className="button-critic"
                onClick={() => onRemove?.(field.name)}
                disabled={isDisabled}
            >
                <DynamicIcon type="TrashXOutlined" />
            </Button>
        </Row>
    </div>
);
