// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1uYX1YTXUKtBB3LE-4y0Q- {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 16px;\n}\n._1uYX1YTXUKtBB3LE-4y0Q- > div:not(:last-child) {\n  margin-bottom: 16px !important;\n}\n", "",{"version":3,"sources":["webpack://src/components/table/action/open-launch-program-modal/open-launch-program-modal.module.less"],"names":[],"mappings":"AAAA;EAKE,WAAA;EACA,aAAA;EACA,eAAA;EACA,gBAAA;AAHF;AAJE;EACE,8BAAA;AAMJ","sourcesContent":[".administration-service-fields {\n  & > div:not(:last-child) {\n    margin-bottom: 16px !important;\n  }\n\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  column-gap: 16px;\n}\n\n@hack: true; @import \"/home/gitlab-runner/builds/SbDYz-i62/0/tmon/nalmon-src-front-react/src/themes/default/default\";"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"administration-service-fields": "_1uYX1YTXUKtBB3LE-4y0Q-"
};
export default ___CSS_LOADER_EXPORT___;
