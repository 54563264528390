import {
    createSelector,
    createSlice,
} from '@reduxjs/toolkit';

import {AppState} from 'store/config/types';
import {customLinkSliceName} from 'store/slices/custom-link-slice/custom-link-slice-constants';
import {loadCustomLinkConfig} from 'store/slices/custom-link-slice/custom-link-slice-thunks';
import {CustomLinkSliceState} from 'store/slices/custom-link-slice/custom-link-slice-types';

export const customLinkSlice = createSlice({
    initialState: {
        customLinks: {},
    } as CustomLinkSliceState,
    name: customLinkSliceName,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadCustomLinkConfig.fulfilled, (state, action) => {
            const {
                payload,
                meta,
            } = action;

            if (payload?.status === 200 && payload?.data) {
                const {key} = meta.arg;
                state.customLinks[key] = payload?.data;
            }
        });
    },
});

const selectCustomLinkSliceState = (state: AppState) => state[customLinkSliceName];

export const selectCustomLinkConfig = createSelector([
    selectCustomLinkSliceState,
    (_, data: {key: string}) => data], (
    state, {key},
) => state.customLinks[key]);

export const customLinkSelectors = {
    selectCustomLinkSliceState,
    selectCustomLinkConfig,
};

export const {
    reducer: customLinkSliceReducer,
    actions: customLinkSliceActions,
} = customLinkSlice;
