import React from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {removeAisDocumentsInInterface, removeAisDocumentsInInterfaceByFilter} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

import {DocumentsInterfaceParametersFilterForm} from '../../ais-documents-interface-types';

interface DeleteConfirmationModalProps {
    isAllSelected: boolean;
    setIsAllSelected: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRowKeys: React.Key[];
    documentsParametersFilter?: DocumentsInterfaceParametersFilterForm;
    setSelectedRowKeys: StateSetter<React.Key[]>;
    refetchTableData: () => void;
}

export const DeleteConfirmationModal = React.forwardRef<ModalOpenerComponentRef, DeleteConfirmationModalProps>((
    {
        isAllSelected,
        selectedRowKeys,
        documentsParametersFilter,
        refetchTableData,
        setSelectedRowKeys,
        setIsAllSelected,
    }: DeleteConfirmationModalProps,
    ref,
) => {
    const dispatch = useAppDispatch();

    const isDeleting = useAppSelector(s => selectIsThunkPending(s, removeAisDocumentsInInterface.typePrefix));

    const handleDelete = (setIsModalOpen: StateSetter<boolean>) => {
        const res = isAllSelected
            ? dispatch(removeAisDocumentsInInterfaceByFilter({...documentsParametersFilter}))
            : dispatch(removeAisDocumentsInInterface({ids: selectedRowKeys} as {ids: number[]}));

        res.unwrap().then(() => {
            showMessage({message: 'Записи успешно удалены'});
            setSelectedRowKeys([]);
            setIsModalOpen(false);
            setIsAllSelected(false);
            refetchTableData();
        }, error => {
            const {data: responseMessage, status} = error?.response;
            const isBadRequest = status === 400;
            const message = isBadRequest && responseMessage
                ? responseMessage : 'Во время удаления записей произошла ошибка';
            showMessage({message, isError: true});
        });
    };

    return (
        <ModalOpenerComponent
            ref={ref}
            modalProps={{
                forceRender: false,
                destroyOnClose: true,
                title: 'Подтверждение',
                centered: true,
            }}
            controlLabels={{
                save: 'Удалить',
            }}
            loadingControls={{
                save: isDeleting,
            }}
            disabledControls={{
                save: isDeleting,
            }}
            handleSave={setIsModalOpen => {
                handleDelete(setIsModalOpen);
            }}
        >
            Вы действительно хотите удалить выбранные записи?
        </ModalOpenerComponent>
    );
});
