import {Form, Input} from 'antd';
import {FormInstance} from 'antd/lib/form/Form';
import React from 'react';

import {CustomSelect} from 'components/form/inputs/custom-select';
import {SubregionResponse} from 'modules/regions/regions-types';

import {INPUT_UNSET_VALUE} from './synthesis-document-modal.constants';
import {SynthesisForm} from './synthesis-document-modal.types';

interface SynthesisDocumentModalFormProps {
    form: FormInstance<any>;
    handleFinish: () => void;
    handleRegionChange: (value: number) => void;
    subregionList: SubregionResponse[] | [];
}

export const SynthesisDocumentModalForm: React.FunctionComponent<SynthesisDocumentModalFormProps> = ({
    form,
    handleFinish,
    handleRegionChange,
    subregionList = [],
}: SynthesisDocumentModalFormProps) => (
    <Form<SynthesisForm>
        form={form}
        layout="vertical"
        onFinish={handleFinish}
    >
        <div
            className="form-flex scroll"
            style={{
                maxHeight: 'calc(min(600px), 64vh))',
                overflow: 'auto',
                padding: '8px 12px 8px 0',
            }}
        >
            <Form.Item
                name="documentTypeId"
                label="Тип документа"
                rules={[{required: true}]}
            >
                <CustomSelect
                    settings={{
                        url: '/valueLists/DOCUMENT_TYPE_ON_UPLOAD',
                        valuePath: 'id',
                        labelPath: 'meaning',
                    }}
                />
            </Form.Item>
            <Form.Item
                label="Раздел"
                name="documentSectionId"
                rules={[{required: true}]}
            >
                <CustomSelect
                    onChange={handleRegionChange}
                    settings={{
                        url: '/fileupload/regionList',
                        valuePath: 'id',
                        labelPath: 'regionFullName',
                    }}
                />
            </Form.Item>
            <Form.Item
                label="Подраздел"
                name="documentSubsectionId"
                rules={[{required: true}]}
            >
                <CustomSelect
                    entries={subregionList.map(subRegion => ({value: subRegion?.id, label: subRegion?.name}))}
                />
            </Form.Item>
            <Form.Item
                label="Налоговый период"
                name="documentPeriodId"
                rules={[{required: true}]}
            >
                <CustomSelect
                    settings={{
                        url: '/valueLists/TAX_PERIOD',
                        valuePath: 'id',
                        labelPath: 'lookupCode',
                        useFirstOptionAfterReceivedFromFetcher: true,
                        formInstance: form,
                    }}
                />
            </Form.Item>
            <Form.Item
                label="Номер корректировки"
                name="documentCorrNum"
            >
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
                label="ИФНС"
                name="documentIfns"
            >
                <Input autoComplete="off" />
            </Form.Item>
            <Form.Item
                label="Филиал"
                name="documentBranchId"
            >
                <CustomSelect
                    additionalEntries={[{
                        label: 'Не выбрано',
                        value: {id: INPUT_UNSET_VALUE, lookupCode: INPUT_UNSET_VALUE, meaning: 'Не выбрано'},
                    }]}
                    settings={{
                        url: '/valueLists/BRANCHES_COMPANIES',
                        valuePath: 'id',
                        labelPath: 'meaning',
                    }}
                />
            </Form.Item>
        </div>
    </Form>

);
