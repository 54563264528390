import React from 'react';

import {ModalOpenerComponent, ModalOpenerComponentRef} from 'components/modal-opener-component';
import {StateSetter} from 'shared/types/generics';
import {showMessage} from 'shared/utils';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {publishAisDocumentsInInterface} from 'store/slices/ais-slice/ais-slice-thunks';
import {selectIsThunkPending} from 'store/slices/loading-state-slice';

interface PublishConfirmationModalProps {
    refetchTableData: () => void;
    organizationId: number | undefined;
}

export const PublishConfirmationModal = React.forwardRef<ModalOpenerComponentRef, PublishConfirmationModalProps>((
    {refetchTableData, organizationId}: PublishConfirmationModalProps,
    ref,
) => {
    const dispatch = useAppDispatch();

    const isDeleting = useAppSelector(s => selectIsThunkPending(s, publishAisDocumentsInInterface.typePrefix));

    const handleDelete = (setIsModalOpen: StateSetter<boolean>) => {
        dispatch(publishAisDocumentsInInterface({orgId: organizationId!})).unwrap().then(() => {
            showMessage({message: 'Записи успешно опубликованы'});
            setIsModalOpen(false);
            refetchTableData();
        }, error => {
            const {data: responseMessage, status} = error?.response;
            const isBadRequest = status === 400;
            const message = isBadRequest && responseMessage
                ? responseMessage : 'Во время публикации записей произошла ошибка';
            showMessage({message, isError: true});
            setIsModalOpen(false);
        });
    };

    return (
        <ModalOpenerComponent
            ref={ref}
            modalProps={{
                forceRender: false,
                destroyOnClose: true,
                title: 'Предупреждение',
                centered: true,
            }}
            controlLabels={{
                save: 'Да',
                cancel: 'Нет',
            }}
            loadingControls={{
                save: isDeleting,
            }}
            disabledControls={{
                save: isDeleting,
            }}
            handleSave={setIsModalOpen => {
                handleDelete(setIsModalOpen);
            }}
        >
            Вы уверены, что хотите опубликовать документы?
        </ModalOpenerComponent>
    );
});
