import {MessageFilled, MessageOutlined} from '@ant-design/icons';
import {
    Button, Form,
} from 'antd';
import {useForm} from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import cn from 'classnames';
import React, {useState} from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {useAppDispatch} from 'store/config/hooks';
import {AisRequestRowCommentTableDto, createAisCommentsRequest} from 'store/slices/ais-slice/ais-comments-request';

import {CommentRequestStatuses} from '../../constants/ais-comments-request-result.constants';
import s from './ais-comment-request-row-modal-opener.module.less';

interface AisCommentRequestRowModalOpenerProps {
  row: AisRequestRowCommentTableDto;
  fetchTableData: () => void;
  status: CommentRequestStatuses;
}

export const AisCommentRequestRowModalOpener: React.FC<AisCommentRequestRowModalOpenerProps> = ({
    row,
    fetchTableData,
    status,
}: AisCommentRequestRowModalOpenerProps) => {
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = useForm<Partial<AisRequestRowCommentTableDto>>();

    const handleCancel = () => {
        setIsModalOpen(prev => !prev);
        form.resetFields();
    };

    const handleSave = async () => {
        const values = form.getFieldsValue();

        form.validateFields().then(async () => {
            if (form.isFieldsTouched()) {
                await dispatch(createAisCommentsRequest({
                    id: row.id,
                    comment: values.comment === '' ? undefined : values.comment,
                }));
                await fetchTableData();
            }

            handleCancel();
        });
    };

    return (
        <ModalOpenerComponent
            isOpen={isModalOpen}
            shouldConfirm={() => form.isFieldsTouched()}
            setIsOpen={setIsModalOpen}
            controlLabels={{
                save: 'Сохранить',
            }}
            hideControls={{
                save: status === CommentRequestStatuses.COMPLETED,
            }}
            disabledControls={{
                save: status === CommentRequestStatuses.COMPLETED,
            }}
            handleCancel={handleCancel}
            handleSave={handleSave}
            tooltip={{title: 'Комментарий'}}
            component={(
                <>
                    <Button
                        className={cn(s['modal__comment-button'])}
                        icon={!row?.comment ? <MessageOutlined /> : <MessageFilled />}
                        type="link"
                    />
                    <span>{row.itemNumber ?? ''}</span>
                </>
            )}
            modalProps={{
                title: 'Комментарий к строке',
                forceRender: true,
                destroyOnClose: false,
                centered: true,
                width: '980px',
            }}
        >
            <Form
                layout="vertical"
                form={form}
                initialValues={row}
            >
                <Form.Item
                    name="comment"
                >
                    <TextArea
                        autoSize={{minRows: 5}}
                        placeholder="Введите комментарий"
                        disabled={status === CommentRequestStatuses.COMPLETED}
                    />
                </Form.Item>
            </Form>
        </ModalOpenerComponent>
    );
};
