import React from 'react';

import {ModalOpenerComponent} from 'components/modal-opener-component';
import {ActionButton} from 'components/table/action/action-button';
import {TableActionProps} from 'components/table/action/table-action-types';

import {useSynthesisDocumentModal} from './hooks';
import {SynthesisDocumentModalForm} from './synthesis-document-modal-form';

interface SynthesisDocumentModalOpenerProps extends TableActionProps {
}

export const SynthesisDocumentModalOpener: React.FunctionComponent<SynthesisDocumentModalOpenerProps> = ({
    meta,
    wrapper,
    entityName,
    ...props
}: SynthesisDocumentModalOpenerProps) => {
    const {
        modalRef,
        isOpen,
        form,
        selectedEntity,
        subregionList,
        setIsOpen,
        handleCloseModal,
        handleFinish,
        handleRegionChange,
    } = useSynthesisDocumentModal({entityName, wrapper, meta});

    const ActionButtonComponent = wrapper || ActionButton;
    const isDisabled = !selectedEntity?.id;

    return (
        <ModalOpenerComponent
            ref={modalRef}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            handleSave={() => {
                form.submit();
            }}
            handleCancel={setIsModalOpen => {
                handleCloseModal(setIsModalOpen);
            }}
            preventComponentClick={() => isDisabled}
            controlLabels={{save: 'Создать'}}
            component={(
                <>
                    <ActionButtonComponent
                        {...props}
                        actionIcon={meta.actionIcon}
                        disabled={isDisabled}
                    >
                        {meta.actionTitle}
                    </ActionButtonComponent>
                </>
            )}
            modalProps={{
                title: 'Создание документа',
                forceRender: true,
                centered: true,
            }}
        >
            <SynthesisDocumentModalForm
                form={form}
                handleFinish={handleFinish}
                handleRegionChange={handleRegionChange}
                subregionList={subregionList}
            />
        </ModalOpenerComponent>
    );
};
