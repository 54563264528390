import React, {
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react';
import {Link} from 'react-router-dom';

import {selectFormEntityData} from 'modules/data/data-selectors';
import {ReactComponent as ExitIcon} from 'shared/assets/header/exit.svg';
import {ReactComponent as NotificationActive} from 'shared/assets/header/notification-active.svg';
import {ReactComponent as Notification} from 'shared/assets/header/notification.svg';
import {ReactComponent as Requests} from 'shared/assets/header/requests-icon.svg';
import {useAppDispatch, useAppSelector} from 'store/config/hooks';
import {AppState} from 'store/config/types';
import {
    logout,
    selectUserId,
    selectUserInfo,
} from 'store/slices/auth-slice';
import {selectMenuStructure} from 'store/slices/menu-slice';
import {useWebsocketSubscriber} from 'websockets';

import {DynamicIcon, TIconType} from '../dynamic-icon';
import {MenuNavLink} from '../layout/layout.utils';
import {NetDirectoryFileUploaderModal} from '../net-directory-file-uploader-modal/net-directory-file-uploader-modal';
import {
    checkReadNews, findNews,
} from './header-utils';
import {SECTION_CODE_HEADERS} from './header.constants';

import './header.less';

interface HeaderProps {
    children?: ReactNode;
    menuContent?: ReactNode;
    menuNavLinks?: MenuNavLink[];
}

const HeaderComponent = ({children, menuContent, menuNavLinks = []}: HeaderProps) => {
    const dispatch = useAppDispatch();
    const ref = useRef({needRead: false, prevState: false, firstRender: true});
    const [needRead, setNeedRead] = useState(false);
    const menuStructure = useAppSelector(selectMenuStructure);
    const onLogout = () => dispatch(logout());
    const {userId, userName} = useAppSelector((state: AppState) => ({
        userId: selectUserId(state),
        userName: selectUserInfo(state)?.fullName,
    }));
    const newsData = useAppSelector(selectFormEntityData('news'));

    useEffect(() => {
        if (userId) {
            checkReadNews(userId, ref.current, setNeedRead);
        }
    }, [newsData]);

    useWebsocketSubscriber({
        newsAdded: msg => {
            if (msg.data?.newsAdded) {
                setNeedRead(true);
            }
        },
    }, {
        fallbacks: [{
            execute: () => { if (userId) findNews(userId, ref.current, setNeedRead); },
            interval: 30000,
        }],
    });
    const hasNewsSection = menuStructure?.some(
        item => item.parameters && item.parameters.sectionCode === SECTION_CODE_HEADERS.news,
    );

    return (
        <header className="header">
            {children}
            <div className="header__menu">
                <div className="header__nav__links">
                    {
                        menuNavLinks?.map(linkItem => (
                            <a
                                href={linkItem?.url}
                                className="header__nav__link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <DynamicIcon
                                    type={linkItem?.icon as TIconType}
                                    className="header__nav__link"
                                />
                            </a>
                        ))
                    }
                    {menuContent}
                    <NetDirectoryFileUploaderModal />
                    {
                        hasNewsSection
                        && (
                            <Link
                                to="/news/"
                                className="header__menu__link"
                            >
                                {needRead ? (
                                    <NotificationActive className="header__menu__icon" />
                                ) : <Notification className="header__menu__icon" />}
                            </Link>
                        )
                    }
                </div>
                <Link
                    to="/requests/"
                    className="header__menu__request"
                >
                    <Requests className="header__menu__request__icon" />
                    <div>Запросы</div>
                </Link>
                <Link
                    // должно быть имя сущности первого дочернего элемента user-settings
                    to="/settings.user.settings/settings.user.settings.general"
                    className="header__menu__profile"
                >
                    <div className="header__menu__profile__avatar">
                        {userName?.split(' ')?.map(symbol => symbol[0]).join('')?.toUpperCase()}
                    </div>
                </Link>
                <ExitIcon
                    onClick={onLogout}
                    className="header__menu__exit-icon"
                />
            </div>
        </header>
    );
};

export const Header = React.memo(HeaderComponent);
