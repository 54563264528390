export {
    DynamicIcon,
    IconsMap,
    IconAsComponentMap,
} from './dynamic-icon';

export type {
    IconWithProps,
    TIconType, // todo: переименовать
} from './dynamic-icon';
