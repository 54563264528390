import {useForm} from 'antd/lib/form/Form';
import {isNull} from 'lodash';
import React, {
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react';

import {ModalOpenerComponentRef} from 'components/modal-opener-component';
import {TableActionProps} from 'components/table/action';
import {selectContextRawData} from 'modules/context/context-selector';
import {EntityValue, selectEntityData} from 'modules/data';
import {actionRequestData} from 'modules/data/data-actions';
import {TableEntityData} from 'modules/data/data-types';
import {RequestType} from 'modules/metadata';
import {selectRegions} from 'modules/regions';
import {RegionResponse, SubregionResponse} from 'modules/regions/regions-types';
import {URL_SYNTHESIS_DOCUMENT} from 'pages/synthesis-document/synthesis-document.constants';
import {EntityType} from 'shared/constants/entities';
import {useAppSelector} from 'store/config/hooks';

import {INPUT_UNSET_VALUE} from '../synthesis-document-modal.constants';
import {DocumentDetails, SynthesisForm} from '../synthesis-document-modal.types';
import {useCurrentLocationRegionData} from './use-current-location-region-data';

interface useSynthesisDocumentModalArgs extends TableActionProps {
}

export const useSynthesisDocumentModal = ({entityName}: useSynthesisDocumentModalArgs) => {
    const [form] = useForm<SynthesisForm>();
    const modalRef = useRef<ModalOpenerComponentRef>(null);
    const regions = useAppSelector(selectRegions);
    const context: EntityValue = useAppSelector(selectContextRawData);
    const tableEntityData = useAppSelector(
        selectEntityData(entityName, EntityType.TABLE),
    ) as TableEntityData & {selectedRows: DocumentDetails[]};
    const selectedRows = useMemo(() => (
        tableEntityData && tableEntityData?.selectedRows ? tableEntityData?.selectedRows : []
    ),
    [tableEntityData]);
    const [isOpen, setIsOpen] = useState(false);

    const {
        currentLocationRegion,
        currentLocationSubregion,
    } = useCurrentLocationRegionData();
    const [subregionList, setSubregionList] = useState(currentLocationRegion?.subregionList ?? []);

    const firstSelectedEntity = useMemo(() => selectedRows[0], [selectedRows]);

    const getDefaultSubsectionId = (region: RegionResponse | undefined, subregion: SubregionResponse | undefined) => {
        const currentSubregionId = subregion?.id;
        const firstSubregionInList = region?.subregionList[0]?.id;
        return subregion ? currentSubregionId : firstSubregionInList;
    };

    useEffect(() => {
        if (!isOpen || !firstSelectedEntity) {
            return;
        }

        const branchId = firstSelectedEntity.branchesCompany?.id;
        const hasBranchId = !(isNull(branchId) || typeof branchId === 'undefined');
        form.setFieldsValue({
            documentTypeId: firstSelectedEntity?.docTypeId,
            documentPeriodId: context?.taxPeriodId,
            documentCorrNum: firstSelectedEntity?.corrNum,
            documentIfns: firstSelectedEntity?.ifns,
            documentBranchId: hasBranchId ? branchId : INPUT_UNSET_VALUE,
            documentSectionId: currentLocationRegion?.id,
            documentSubsectionId: getDefaultSubsectionId(currentLocationRegion, currentLocationSubregion),
        });
    }, [isOpen, firstSelectedEntity, form, context, currentLocationRegion, currentLocationSubregion]);

    const handleFinish = useCallback(() => {
        const branchIdValue = form.getFieldValue('documentBranchId');
        actionRequestData({
            requestUrl: `${URL_SYNTHESIS_DOCUMENT}/create/synthesis-document`,
            requestType: RequestType.POST,
            successMessage: 'Данные сохранены',
            options: {isJsonRequest: true},
            data: {
                documentTypeId: form.getFieldValue('documentTypeId'),
                documentPeriodId: form.getFieldValue('documentPeriodId'),
                documentCorrNum: form.getFieldValue('documentCorrNum'),
                documentIfns: form.getFieldValue('documentIfns'),
                documentBranchId: branchIdValue === INPUT_UNSET_VALUE ? null : branchIdValue,
                documentSectionId: form.getFieldValue('documentSectionId'),
                documentSubsectionId: form.getFieldValue('documentSubsectionId'),
                ids: selectedRows.map(row => (row?.id)),
            },
        }).then((response:any) => {
            if (response?.status === 200) {
                modalRef.current?.setIsModalOpen(false);
                form.resetFields();
            }
        });
    }, [form, selectedRows, modalRef]);

    const handleRegionChange = (value: number) => {
        const selectedRegion = regions?.find(region => region?.id === value);
        const selectedRegionSubregionList = selectedRegion?.subregionList ?? [];

        setSubregionList(selectedRegionSubregionList);
        form.setFieldsValue({
            documentSubsectionId: selectedRegionSubregionList?.length ? selectedRegionSubregionList[0]?.id : null,
        });
    };

    const handleCloseModal = useCallback((setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => {
        form.resetFields();
        setIsModalOpen(false);
    }, [form]);

    return {
        modalRef,
        isOpen,
        form,
        selectedEntity: firstSelectedEntity,
        subregionList,
        setIsOpen,
        handleCloseModal,
        handleFinish,
        handleRegionChange,
    };
};
