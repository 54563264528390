import {
    Button, Card, List, Modal, Space, Typography,
} from 'antd';
import cn from 'classnames';
import React from 'react';

import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';

import './confirm-modal.less';

export interface ConfirmModalProps {
    visible: boolean;
    onConfirm: () => void;
    onCancel: () => void;
    content: React.ReactNode | string[];
    title: string;
    cancelText?: string;
    approveText?: string;
    onCancelConfirm?: () => void;
    useWrapperDivElementAsContainer?: boolean;
    modalClassName?: cn.Argument;
}

export const ConfirmModal = ({
    visible,
    onConfirm,
    onCancel,
    onCancelConfirm,
    content,
    cancelText,
    approveText,
    title,
    useWrapperDivElementAsContainer,
    modalClassName,
}: ConfirmModalProps) => {
    const eleRef = React.useRef<HTMLDivElement>(null);
    const modalElement = (
        <Modal
            width={680}
            centered
            footer={null}
            visible={visible}
            onCancel={onCancel}
            maskClosable={false}
            getContainer={useWrapperDivElementAsContainer ? eleRef?.current || false : undefined}
            className={cn('confirm-modal', 'form-modal', modalClassName)}
            closeIcon={<CloseIcon className="modal-close-icon" />}
        >
            <Card title={title}>
                <Space
                    size={16}
                    direction="vertical"
                >
                    {Array.isArray(content)
                        ? (
                            <div className="error-list">
                                <List
                                    dataSource={content}
                                    renderItem={item => <List.Item>{item}</List.Item>}
                                />
                            </div>

                        )
                        : <Typography.Text>{content}</Typography.Text>}

                    <div className="confirm-modal__buttons">
                        {approveText && (
                            <Button
                                onClick={onConfirm}
                                type="primary"
                            >{approveText}
                            </Button>
                        )}
                        {cancelText && (
                            <Button
                                onClick={onCancelConfirm || onCancel}
                                type="default"
                            >{cancelText}
                            </Button>
                        )}
                    </div>
                </Space>
            </Card>
        </Modal>
    );

    return (
        useWrapperDivElementAsContainer
            ? ( // чтобы не создавались пустые <div></div>
                <div ref={eleRef}>
                    {modalElement}
                </div>
            ) : modalElement
    );
};
