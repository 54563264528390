/* eslint-disable max-len */
import {
    Modal,
    Table,
    Badge,
    Tooltip,
} from 'antd';
import cn from 'classnames';
import React, {useImperativeHandle, useState} from 'react';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import {
    DocumentsUploadStatusIcon,
} from 'components/documents/file-modal/upload-stage-modal/documents-upload-stage/documents-upload-status-icon/documents-upload-status-icon';
import {SimpleActionButton} from 'components/form/components';
import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {
    fetchRequestListInfo,
    markDownLoadedAll,
    markDownLoadedList,
} from 'modules/data/api/documents-upload-monitoring-api';
import {createActionGetFile} from 'modules/data/data-actions';
import {
    monitoringStatusCode,
} from 'modules/documents/documents-upload-monitoring-types';
import {RequestType} from 'modules/metadata';
import {ReactComponent as ArrowRightIcon} from 'shared/assets/arrow-right.svg';
import {ReactComponent as CloseIcon} from 'shared/assets/close.svg';
import {ReactComponent as AppstoreBlueIcon} from 'shared/assets/header/appstore-blue.svg';
import {ReactComponent as AppstoreWhiteIcon} from 'shared/assets/header/appstore-white.svg';
import {useInterval} from 'shared/hooks/use-interval.hook';
import {usePagination} from 'shared/hooks/use-pagination';
import {showMessageFromResponse} from 'shared/utils';
import {useAppSelector} from 'store/config/hooks';
import {DownloadFilesType, InnerRequestsType, PaginationType} from 'store/slices/table-report-slice/table-report-download-documents';
import {
    selectDownloadDocumentsReports, selectModalReportDownloadDocument,
} from 'store/slices/table-report-slice/table-report-download-documents/table-report-download-documents-selectors';
import {tableReportSliceActions} from 'store/slices/table-report-slice/table-report-slice';

import {
    DownloadDocumentsColumns,
    DownloadReportDocumentsFields,
    DownloadReportDocumentsFieldsWidth,
    DownloadReportStatuses,
    MODAL_UPDATE_DELAY,
    UPLOAD_DOCUMENT_URL,
    UPLOAD_LOG_URL,
} from './download-report-documents-modal.constants';

import './download-report-documents-modal.less';

const {Column} = Table;

// backend falls with numberPage 0
// responseRequest.number !== paginationCurrent, they wants paginationCurrent === 1 to give back 0
const getPageNumber = (pageNumber: number | undefined) => (pageNumber === 0 ? 1 : pageNumber);

interface DownloadReportDocumentsModalProps {
    isOpen?: boolean;
    setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
    handleCancel?: (setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>) => void;
}

export interface DownloadReportDocumentsModalRef {
    closeModal: (shouldConfirm?: boolean) => void;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DownloadReportDocumentsModal = React.forwardRef<
    DownloadReportDocumentsModalRef,
    DownloadReportDocumentsModalProps
>(({
    isOpen,
    setIsOpen,
    handleCancel,
}: DownloadReportDocumentsModalProps, ref) => {
    const dispatch = useDispatch();
    const {
        wereDocumentsRead,
        isAnimationActive,
        isOpen: isOpenDownloadReportDocumentsModal,
    } = useAppSelector(selectModalReportDownloadDocument) ?? {};
    const reportDownloadDocuments = useAppSelector(selectDownloadDocumentsReports);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isModalOpenState, setIsModalOpenState] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([]);
    const [isModalOpen, setIsModalOpen] = [isOpen ?? isModalOpenState, setIsOpen ?? setIsModalOpenState];

    const documentApiActions = bindActionCreators({
        createActionGetFile,
    }, dispatch);

    const {
        setReportDownloadsData,
        removeReportDownloads,
        resetReportDownloadsData,
        updateReportDownloadsPagination,
        setModalReportDownloadDocuments,
        setReadReportDownloadDocuments,
        setAnimationReportDownloadDocuments,
    } = tableReportSliceActions;
    const showModal = () => {
        setIsModalOpen(true);
        dispatch(setReadReportDownloadDocuments({wereDocumentsRead: true}));
    };

    const handleClose = () => {
        dispatch(setModalReportDownloadDocuments({isOpen: false, wereDocumentsRead: true}));
        if (handleCancel) {
            handleCancel(setIsModalOpen);
        } else {
            setIsModalOpen(false);
        }
    };

    useImperativeHandle(ref, () => ({
        closeModal: handleClose,
        setIsModalOpen,
    }));

    const handleUploadLog = async (monitorProgramRequest: number | null) => {
        if (!monitorProgramRequest) return;
        documentApiActions.createActionGetFile({
            isMessage: true,
            requestType: RequestType.GET,
            referenceUrl: `${UPLOAD_LOG_URL}${monitorProgramRequest}`,
        });
    };

    const handleUpdate = (params?: PaginationType) => {
        setIsLoading(true);
        fetchRequestListInfo({
            paginationCurrent: getPageNumber(params?.number || reportDownloadDocuments?.number),
            paginationPageSize: params?.size || reportDownloadDocuments?.size,
        })
            .then(result => {
                setIsModalOpen(true);
                dispatch(setReportDownloadsData(result.data));
            })
            .catch(er => showMessageFromResponse({response: er?.response, isError: true}))
            .finally(() => setIsLoading(false));
    };

    const handleRemoveAll = async () => {
        setIsLoading(true);
        await markDownLoadedAll({})
            .then(() => {
                dispatch(resetReportDownloadsData());
                handleUpdate();
            })
            .catch(er => showMessageFromResponse({response: er?.response, isError: true}))
            .finally(() => setIsLoading(false));
    };

    const handleRemoveSelected = async () => {
        await markDownLoadedList(selectedRowKeys)
            .then(() => {
                dispatch(removeReportDownloads(selectedRowKeys));
                setSelectedRowKeys([]);
                handleUpdate();
            })
            .catch(er => showMessageFromResponse({response: er?.response, isError: true}));
    };

    const handleUploadDocument = async (file: DownloadFilesType) => {
        if (!file?.attachId || !file?.requestId || !file?.filename) return;
        // todo: этот эндпоинт будет заменен,
        // т.к. нужен будет апдейт после скачивания, запись должна скрываться
        await documentApiActions.createActionGetFile({
            referenceUrl: `${UPLOAD_DOCUMENT_URL}?attachId=${file.attachId}&requestId=${file.requestId}&filename=${file.filename}`,
            requestType: RequestType.GET,
        });
        handleUpdate();
    };
    const handleAnimationEnd = () => {
        dispatch(setAnimationReportDownloadDocuments({isAnimationActive: false}));
    };

    useInterval(() => {
        handleUpdate();
    }, isModalOpenState ? MODAL_UPDATE_DELAY : null);

    const {
        current,
        pageSize,
        handleChange,
    } = usePagination({
        pageSizeDefault: reportDownloadDocuments?.size,
        pageDefault: getPageNumber(reportDownloadDocuments?.number),
        onChange: (paginationCurrent, paginationPageSize) => {
            dispatch(updateReportDownloadsPagination({
                number: paginationCurrent ?? 0,
                size: paginationPageSize ?? 20,
            }));
            handleUpdate({
                number: paginationCurrent ?? 0,
                size: paginationPageSize ?? 20,
            });
        },
    });

    const columnRenderer = (field: any, value: any, record: any) => {
        switch (field.key) {
        case DownloadReportDocumentsFields.InnerRequests:
            return record?.innerRequests?.map((innerRequest: InnerRequestsType) => {
                const TooltipText = `${innerRequest?.status?.meaning || ''}\n
                ${innerRequest?.monitorProgramLog?.hasFileContent
        ? 'Для скачивания лога нажмите на иконку с отображением статуса'
        : ''}`;
                return (
                    <Tooltip title={TooltipText}>
                        <span
                            className={cn('status', DownloadReportStatuses[innerRequest?.status?.lookupCode as string])}
                            onClick={() => {
                                if (!innerRequest?.monitorProgramLog?.hasFileContent) return;
                                handleUploadLog(innerRequest?.monitorProgramLog?.monitorProgramRequest);
                            }}
                        >
                            <DocumentsUploadStatusIcon
                                status={innerRequest?.status?.lookupCode as monitoringStatusCode}
                            />
                        </span>
                    </Tooltip>
                );
            });

        case DownloadReportDocumentsFields.DownloadFiles:
            return record?.downloadFiles?.length
                ? record?.downloadFiles?.map((file: DownloadFilesType) => (
                    <div
                        className={cn(file ? 'link' : 'inactive')}
                        onClick={() => handleUploadDocument(file)}
                    >
                        {file?.filename}
                    </div>
                ))
                : <span>-</span>;

        default:
            return <span>{value ? value?.toString() : '-'}</span>;
        }
    };

    return (
        <div className="download-report-modal">
            <div
                className="alert"
                onClick={showModal}
            >
                <div
                    className={cn(
                        'badge',
                        {
                            'badge-animate': isAnimationActive,
                        },
                    )}
                    onAnimationEnd={handleAnimationEnd}
                >
                    <Badge>
                        {wereDocumentsRead ? (
                            <AppstoreWhiteIcon />
                        ) : (
                            <AppstoreBlueIcon />
                        )}
                    </Badge>
                </div>
            </div>
            <Modal
                className="modal"
                width="1650px"
                title="Информация о запущенных программах"
                closeIcon={<CloseIcon />}
                visible={isModalOpen || isOpenDownloadReportDocumentsModal}
                onCancel={handleClose}
                footer={null}
            >
                <div className="action-buttons">
                    <SimpleActionButton
                        type="primary"
                        onClick={() => handleUpdate()}
                        title="Обновить список программ"
                        icon="ReloadOutlined"
                        disabled={isLoading}
                    />

                    <SimpleActionButton
                        type="primary"
                        onClick={handleRemoveSelected}
                        title="Убрать из списка выбранное"
                        className="button-critic"
                        icon="TrashXOutlined"
                        disabled={!selectedRowKeys.length || isLoading}
                    />

                    <ActionConfirmModal
                        cancelText="Отменить"
                        approveText="Очистить"
                        confirmModalContent="Вы уверены, что хотите очистить весь список? "
                        modalTitle="Предупреждение"
                        confirmAction={handleRemoveAll}
                    >
                        {handleClick => (
                            <SimpleActionButton
                                type="primary"
                                onClick={handleClick}
                                title="Очистить весь список"
                                className="button-critic"
                                icon="TrashXOutlined"
                                disabled={isLoading}
                            />
                        )}
                    </ActionConfirmModal>
                </div>
                <Table
                    rowKey="requestId"
                    className="table"
                    dataSource={reportDownloadDocuments?.content}
                    pagination={{
                        position: ['bottomLeft'],
                        total: reportDownloadDocuments?.totalElements,
                        current,
                        pageSize,
                        onChange: handleChange,
                        locale: {items_per_page: 'на странице', jump_to: ''},
                        showQuickJumper: {goButton: <ArrowRightIcon className="pagination_jumper" />},
                        showSizeChanger: false,
                    }}
                    scroll={{
                        x: 'max-content',
                    }}
                    rowSelection={{
                        selectedRowKeys,
                        onChange: (newSelectedRowKeys: React.Key[]) => {
                            setSelectedRowKeys(newSelectedRowKeys as number[]);
                        },
                    }}
                    loading={isLoading}
                >
                    {DownloadDocumentsColumns.map(field => (
                        <Column
                            title={field.title}
                            key={field.key}
                            render={(value, record) => columnRenderer(field, value, record)}
                            dataIndex={field.key}
                            width={DownloadReportDocumentsFieldsWidth[field.key as DownloadReportDocumentsFields]}
                        />
                    ))}
                </Table>
            </Modal>
        </div>
    );
});
