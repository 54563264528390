import {SaveOutlined, UndoOutlined} from '@ant-design/icons';
import {Button, message} from 'antd';
import {FormInstance} from 'antd/es/form';
import cn from 'classnames';
import React, {useContext} from 'react';

import {ActionConfirmModal} from 'components/table/action/action-confirm-modal/action-confirm-modal';
import {ReactComponent as ArrowBackOutlined} from 'shared/assets/arrow-back-outlined.svg';
import {ReactComponent as CloseXOutlined} from 'shared/assets/close-x-outlined.svg';
import {ReactComponent as EditWithLine} from 'shared/assets/edit-with-line.svg';
import {setFieldsValueUntouched} from 'shared/utils';

import {WatcherFieldFlag} from '../../../report-configuration/hooks/use-form-fields-manager';
import {ReportPropertiesFormState} from '../../../report-configuration/tabs/report-properties-settings';
import {
    documentUploadTemplateFormTitles,
    DocumentUploadTemplateMessages,
    DocumentUploadTemplateTabKey,
} from '../../document-upload-template-constants';
import {DocumentUploadTemplateContext} from '../../document-upload-template-context';

export interface SettingTemplateTargetTableButtonsProps {
    form: FormInstance;
    formState: ReportPropertiesFormState;
    setFormState: React.Dispatch<React.SetStateAction<ReportPropertiesFormState>>;
    tabName: DocumentUploadTemplateTabKey;
    checkArrayIndependentWatcherFlag?: (key: string, flag: WatcherFieldFlag) => boolean;
    isClearButtonDisabled?: boolean;
}

export const SettingTemplateTargetTableButtons = ({
    form,
    formState,
    setFormState,
    isClearButtonDisabled,
    tabName,
}: SettingTemplateTargetTableButtonsProps) => {
    const {setSelectedTabKey} = useContext(DocumentUploadTemplateContext);
    return (
        <div className={cn('setting-template-target-table__buttons-bar')}>
            <Button
                disabled={!formState?.isEditingForm}
                type="primary"
                htmlType="submit"
                onClick={() => {
                    form.validateFields()
                        .then(() => {
                            form.submit();
                            setFormState(v => ({...v, isEditingForm: false, isDirty: false}));
                        })
                        .catch(() => message.error(`${DocumentUploadTemplateMessages.ERROR_VALIDATE_FIELD_TEXT}
                        «${documentUploadTemplateFormTitles[tabName]}»`));
                }}
                icon={<SaveOutlined />}
            >Сохранить
            </Button>
            {
                !formState.isEditingForm && (
                    <Button
                        type="primary"
                        icon={<EditWithLine />}
                        onClick={() => {
                            setFormState(v => ({...v, isEditingForm: !formState.isEditingForm}));
                        }}
                    >Редактировать
                    </Button>
                )
            }
            {
                formState.isEditingForm
                && (
                    <Button
                        icon={<UndoOutlined />}
                        type="default"
                        onClick={() => {
                            setFieldsValueUntouched(form, formState.initialValues);
                            setFormState(v => ({...v, isEditingForm: false}));
                        }}
                    >Отменить
                    </Button>
                )
            }
            <ActionConfirmModal
                cancelText="Отменить"
                approveText="Очистить"
                modalTitle="Предупреждение"
                confirmModalContent={DocumentUploadTemplateMessages.CLEAR_FORM_FIELDS_CONFIRM_TEXT}
                confirmAction={() => {
                    form.resetFields();
                    setFormState(v => ({...v, isDirty: true}));
                }}
                isNotInContainer
            >
                {
                    handleClick => (
                        <Button
                            type="default"
                            onClick={handleClick}
                            disabled={isClearButtonDisabled || !formState.isEditingForm}
                            icon={<CloseXOutlined />}
                        >
                            Очистить форму
                        </Button>
                    )
                }
            </ActionConfirmModal>
            <ActionConfirmModal
                cancelText="Вернуться к редактированию"
                approveText="Закрыть форму"
                modalTitle="Предупреждение"
                confirmModalContent={DocumentUploadTemplateMessages.CLOSE_FORM_CONFIRM_TEXT}
                confirmAction={() => setSelectedTabKey?.(DocumentUploadTemplateTabKey.CONFIGURATOR)}
                skipToConfirmCallback={(() => !formState.isEditingForm)}
                isNotInContainer
            >
                {
                    handleClick => (
                        <Button
                            type="default"
                            icon={<ArrowBackOutlined />}
                            onClick={handleClick}
                        >
                            Вернуться на главную вкладку
                        </Button>
                    )
                }
            </ActionConfirmModal>
        </div>
    );
};
