export {
    modalsSelectors,
    modalsSliceActions,

    selectModalData,

    closeModal,
    openModal,
} from './modals-slice';

export type {
    ModalComponentInterface,
} from './modals-slice-types';
