import {FormInstance} from 'antd/es/form';
import {useForm} from 'antd/lib/form/Form';
import React, {useImperativeHandle, useState} from 'react';

import {StateSetter} from 'shared/types/generics';
import {AisInterfaceDocumentDto} from 'store/slices/ais-slice/ais-slice-types';

import {FormMode} from '../../../../../components/form';
import {MainInfo} from './main-info';

interface DocumentCardModalContentProps {
    initialInterfaceDocument?: AisInterfaceDocumentDto;
    formMode: FormMode;
    isDisabled?: boolean;
}

export interface DocumentCardModalContentRef {
    form: FormInstance;
    interfaceDocument: AisInterfaceDocumentDto | undefined;
    setInterfaceDocument: StateSetter<AisInterfaceDocumentDto | undefined>;
}

export const DocumentCardModalContent = React.forwardRef<
    DocumentCardModalContentRef,
    DocumentCardModalContentProps>((props: DocumentCardModalContentProps, ref) => {
        const {
            initialInterfaceDocument,
            formMode,
            isDisabled,
        } = props;

        const [form] = useForm();
        const [interfaceDocument, setInterfaceDocument] = useState<AisInterfaceDocumentDto | undefined>(
            initialInterfaceDocument,
        );

        useImperativeHandle(ref, () => ({
            form,
            interfaceDocument,
            setInterfaceDocument,
        }));

        return (
            <MainInfo
                interfaceDocument={interfaceDocument}
                form={form}
                formMode={formMode}
                isDisabled={isDisabled}
            />
        );
    });
